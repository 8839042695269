<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large container profile-page">
      <user-header
        v-if="selectedUserValue !== ''"
        :userDataForHeader="userData"
        :selectedUserData="selectedUserDataForHeader"
        @weekSelectedChanged="weekSelectedChanged"
        @calendarDateChanged="calendarDateChanged"
        @mondaySunDatesChanged="mondaySunDatesChanged"
        :coachesUsersData="coachesUsersData"
        @selectedUseChanged="selectedUseChanged"
      />
      <div v-else>
        <h1>
          <span>Overview</span>
        </h1>
      </div>

      <b-button
        class="main-button create-academy-report-button"
        v-if="userData.userType === 'Admin' && selectedUserValue !== ''"
        @click="openCreateAcademyReportModal()"
        size="lg"
        variant="outline-success"
        ><b-icon icon="file-text" aria-hidden="true"></b-icon> Create academy
        report</b-button
      >

      <template>
        <template v-if="selectedUserValue !== ''">
          <ul class="tabs headding-space">
            <li
              class="tab-item"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
              <a
                :class="
                  selectedTab === 'daily-planner-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'daily-planner-tab')"
                href="javascript:;"
                >Daily planner</a
              >
            </li>
            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'performace-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'performace-tab')"
                href="javascript:;"
                >Performace</a
              >
            </li>
            <li
              class="tab-item"
            >
              <a
                :class="
                  selectedTab === 'review-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'review-tab')"
                href="javascript:;"
                >Performance Reviews</a
              >
            </li>
            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'contact-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'contact-tab')"
                href="javascript:;"
                >Contact information</a
              >
            </li>
            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'wellness-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'wellness-tab')"
                href="javascript:;"
                >Wellness information</a
              >
            </li>
            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'injury-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'injury-tab')"
                href="javascript:;"
                >Injury information</a
              >
            </li>

            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'learning-plan-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'learning-plan-tab')"
                href="javascript:;"
                >Individual learning plans (ILPs)</a
              >
            </li>

            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'payments-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'payments-tab')"
                href="javascript:;"
                >Payments / subscriptions</a
              >
            </li>

            <!--<li
              class="tab-item"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
              <a
                :class="
                  selectedTab === 'parents-meeting-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'parents-meeting-tab')"
                href="javascript:;"
                >Parents Evenings</a
              >
            </li>-->

            <li
              class="tab-item"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
              <a
                :class="
                  selectedTab === 'coach-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'coach-tab')"
                href="javascript:;"
                >Playing history</a
              >
            </li>
            <!--<li
              class="tab-item"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
              <a
                :class="
                  selectedTab === 'code-of-conduct-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'code-of-conduct-tab')"
                href="javascript:;"
                >Code of Conduct</a
              >
            </li>-->
            <!--<li
              class="tab-item"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
              <a
                :class="
                  selectedTab === 'charter-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'charter-tab')"
                href="javascript:;"
                >Charter</a
              >
            </li>-->
            <!--<li
              class="tab-item"
              v-if="userData.userType === 'Admin' || userData.id === '964'"
            >
              <a
                :class="
                  selectedTab === 'game-prep-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'game-prep-tab')"
                href="javascript:;"
                >Game preparation</a
              >
            </li>-->
          </ul>
          <div class="tabs-wrapper">
            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'daily-planner-tab'"
            >
              <div class="daily-planner-wrapper">
                <DailyPlanner
                  :selectedTeamId="selectedTeamId"
                  :selectedUserData="selectedUserData"
                />
              </div>
            </div>
            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'performace-tab'"
            >
              <div class="games-info two-col-flex">
                <div class="games-stats">
                  <div class="button-wrapper-top">
                    <b-button
                      class="main-button"
                      @click="openGamePerformanceModal('performanceModal')"
                      size="lg"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Submit game performance report"
                      ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                    </b-button>

                    <b-button
                      size="lg"
                      class="main-button"
                      variant="outline-success"
                      v-b-tooltip.hover
                      @click="
                        download_csv(
                          allplayerPerformanceReportData,
                          'player-Performance-Reviews'
                        )
                      "
                      title="Export player performance data to csv"
                      ><b-icon icon="download" aria-hidden="true"></b-icon
                    ></b-button>

                   <!-- <b-button
                      size="lg"
                      class="main-button"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Import player performance data"
                      ><b-icon icon="upload" aria-hidden="true"></b-icon
                    ></b-button>-->

                    <b-button
                      size="lg"
                      class="main-button"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Email player performance data"
                      ><b-icon icon="envelope" aria-hidden="true"></b-icon
                    ></b-button>
                  </div>

                  <div class="stats-wrapper">
                    <div class="game-stats">
                      <h3 class="no-margin">Your game stats for this season</h3>
                      <p
                        class="small-date-player-coach days-red-warning"
                        v-if="gamePerformanceData.created_date === undefined"
                      >
                        <b-icon
                          icon="exclamation-circle"
                          aria-hidden="true"
                        ></b-icon>
                        You have not submitted a game report. Please submit one
                        now.
                      </p>
                      <p
                        v-else
                        class="small-date-player-coach"
                        :class="`days-${checkDateTimeFromNow(
                          gamePerformanceData.created_date
                        )}`"
                      >
                        You last submitted a game report on
                        {{ formatDate(gamePerformanceData.created_date) }}
                      </p>
                      <ul class="key-value-list">
                        <li>
                          <span>Appearances:</span
                          >{{ gamePerformanceData.gamesPlayed }}
                        </li>
                        <li v-if="checkUserPosition() !== 'Goalkeeper'">
                          <span>Goals:</span>
                          <template v-if="gamePerformanceData.goals !== null">{{
                            gamePerformanceData.goals
                          }}</template>
                          <template v-else>0</template>
                        </li>
                        <li v-else>
                          <span>Clean sheets:</span>
                          <template
                            v-if="gamePerformanceData.clean_sheet !== null"
                            >{{ gamePerformanceData.clean_sheet }}</template
                          >
                          <template v-else>0</template>
                        </li>
                        <li>
                          <span>Assists:</span>
                          {{ gamePerformanceData.assists }}
                        </li>
                        <li
                          v-if="gamePerformanceData.player_self_rating !== null"
                        >
                          <span>Player performace rating:</span>
                          {{ gamePerformanceData.player_self_rating }}
                        </li>
                        <li v-if="gamePerformanceData.timeOnPitch !== null">
                          <span>Game time:</span>
                          {{ gamePerformanceData.timeOnPitch }} mins
                        </li>
                        <li>
                          <span>Yellow cards:</span>
                          {{ gamePerformanceData.yellowCardTotal }}
                        </li>
                        <li>
                          <span>Red cards:</span>
                          {{ gamePerformanceData.redCardTotal }}
                        </li>
                      </ul>
                    </div>

                    <div class="playing-positions">
                      <h3>Playing positions</h3>
                      <div class="pitch-wrapper">
                        <img
                          class="goalkeeper small-shirt"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />

                        <img
                          class="small-shirt right-full-back"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt center-back-left"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt center-back-right"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt left-full-back"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />

                        <img
                          class="small-shirt left-midfielder"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt defensive-midfielder"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt attacking-midfielder"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt right-midfielder"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />

                        <img
                          class="small-shirt striker-left"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />
                        <img
                          class="small-shirt striker-right"
                          src="../img/player-shirt-gray.svg"
                          alt="player-shirt"
                        />

                        <div
                          class="position-played-wrapper"
                          v-for="(
                            position, index
                          ) in gamePerformanceData.postion_played"
                          :key="index"
                        >
                          <img
                            v-if="position === 'Goalkeeper'"
                            class="goalkeeper small-shirt"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />

                          <img
                            v-if="position === 'Right Full-back (Wingback)'"
                            class="small-shirt right-full-back"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Center-back left'"
                            class="small-shirt center-back-left"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Center-back right'"
                            class="small-shirt center-back-right"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Left Full-back (Wingback)'"
                            class="small-shirt left-full-back"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />

                          <img
                            v-if="position === 'Left Midfielder (Winger)'"
                            class="small-shirt left-midfielder"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Defensive Midfielder'"
                            class="small-shirt defensive-midfielder"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Attacking Midfielder'"
                            class="small-shirt attacking-midfielder"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Right Midfielder (Winger)'"
                            class="small-shirt right-midfielder"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />

                          <img
                            v-if="position === 'Center Forward (Striker) left'"
                            class="small-shirt striker-left"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                          <img
                            v-if="position === 'Center Forward (Striker) right'"
                            class="small-shirt striker-right"
                            src="../img/player-shirt-blue.svg"
                            alt="player-shirt"
                          />
                        </div>

                        <img
                          src="../img/pitch.svg"
                          alt="pitch"
                          class="pitch-svg"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="all-training-reports">
                    <a
                      href="javascript:;"
                      @click="openAllGamegReportsModal('allGameReportsModal')"
                      ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                      View all this season Game stats</a
                    >

                    <div class="buttons-row">
                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        @click="
                          download_csv(
                            seasonsgamePerformanceReportsForUser,
                            'player-season-Game-stats-Reports'
                          )
                        "
                        title="Export player season Game stats to csv"
                        ><b-icon icon="download" aria-hidden="true"></b-icon
                      ></b-button>

                      <!--<b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Import player season Game stats"
                        ><b-icon icon="upload" aria-hidden="true"></b-icon
                      ></b-button>-->

                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Email player season Game stats"
                        ><b-icon icon="envelope" aria-hidden="true"></b-icon
                      ></b-button>
                    </div>
                  </div>

                  <div class="stats-wrapper">
                    <div
                      v-if="gamePerformanceData.gameId !== null"
                      class="session-enjoyment-wrapper"
                    >
                      <h3>Last game assesment</h3>
                      <ul class="key-value-list">
                        <li>
                          <img class="small-image" :src="getPlayerImage()" />
                          <div class="wrapper-score-ass">
                            <span>Players score:</span
                            >{{ gamePerformanceData.player_self_rating }}
                            <span
                              v-if="
                                gamePerformanceData.created_date !== undefined
                              "
                              class="small-date-player-coach full-width-error"
                              :class="`days-${checkDateTimeFromNow(
                                gamePerformanceData.created_date
                              )}`"
                            >
                              Updated on
                              {{
                                formatDate(gamePerformanceData.created_date)
                              }}</span
                            >
                          </div>
                        </li>
                        <li>
                          <img
                            class="small-image"
                            :src="selectedCoachImageURL"
                          />

                          <div class="wrapper-score-ass">
                            <span>Coaches score:</span
                            >{{ coachGamePerformanceData.player_rating }}
                            <span
                              v-if="
                                coachGamePerformanceData.created_date !==
                                undefined
                              "
                              class="small-date-player-coach full-width-error"
                              :class="`days-${checkDateTimeFromNow(
                                coachGamePerformanceData.created_date
                              )}`"
                            >
                              Updated on
                              {{
                                formatDate(
                                  coachGamePerformanceData.created_date
                                )
                              }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="coach-comments-wrapper">
                      <h3>Coach comments</h3>
                      <p class="coach-comments">
                        {{ coachGamePerformanceData.game_comments }}
                      </p>

                      <b-button
                        v-if="
                          userData.userType === 'Admin' ||
                          userData.userType === 'Coach'
                        "
                        @click="
                          openGamePerformanceModal('coachGamePerformanceModal')
                        "
                        class="main-button"
                        size="lg"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Rate player"
                        ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                        Rate player</b-button
                      >
                    </div>
                  </div>
                </div>

                <div class="training-stats">
                  <div class="game-stats">
                    <div class="stats-wrapper">
                      <div class="training-scores">
                        <div class="button-wrapper-top">
                          <b-button
                            class="main-button"
                            @click="openTrainingPerformanceModal()"
                            size="lg"
                            variant="outline-success"
                            v-b-tooltip.hover
                            title="Submit training report"
                            ><b-icon
                              icon="file-text"
                              aria-hidden="true"
                            ></b-icon>
                          </b-button>

                          <b-button
                            size="lg"
                            class="main-button"
                            variant="outline-success"
                            v-b-tooltip.hover
                            @click="
                              download_csv(
                                seasonsTraningPerformanceReportsForUser,
                                'player-training-data'
                              )
                            "
                            title="Export player training data to csv"
                            ><b-icon icon="download" aria-hidden="true"></b-icon
                          ></b-button>

                          <!--<b-button
                            size="lg"
                            class="main-button"
                            variant="outline-success"
                            v-b-tooltip.hover
                            title="Import player training data"
                            ><b-icon icon="upload" aria-hidden="true"></b-icon
                          ></b-button>-->

                          <b-button
                            size="lg"
                            class="main-button"
                            variant="outline-success"
                            v-b-tooltip.hover
                            title="Email player training data"
                            ><b-icon icon="envelope" aria-hidden="true"></b-icon
                          ></b-button>
                        </div>
                        <h3 class="no-margin">
                          Your training stats for this season
                        </h3>
                        <p
                          class="small-date-player-coach days-red-warning"
                          v-if="
                            trainingPerformanceData.created_date === undefined
                          "
                        >
                          <b-icon
                            icon="exclamation-circle"
                            aria-hidden="true"
                          ></b-icon>
                          You have not submitted a training report. Please
                          submit one now.
                        </p>
                        <p
                          v-else
                          class="small-date-player-coach"
                          :class="`days-${checkDateTimeFromNow(
                            trainingPerformanceData.created_date
                          )}`"
                        >
                          You last submitted a training report on
                          {{ formatDate(playerMoodData.created_date) }}
                        </p>

                        <ul
                          v-if="trainingPerformanceData.traningAttended > 0"
                          class="key-value-list"
                        >
                          <li>
                            <span>Sessions attended:</span
                            >{{ trainingPerformanceData.traningAttended }}
                          </li>
                          <li>
                            <span>Training hours this season:</span
                            >{{ trainingPerformanceData.time }} mins
                          </li>
                          <li>
                            <span>Last Training session rating:</span
                            >{{ trainingPerformanceData.enjoyment }}
                          </li>

                          <span
                            v-if="
                              gamePerformanceData.created_date !== undefined
                            "
                            class="small-date-player-coach full-width-error"
                            :class="`days-${checkDateTimeFromNow(
                              gamePerformanceData.created_date
                            )}`"
                          >
                            Updated on
                            {{
                              formatDate(gamePerformanceData.created_date)
                            }}</span
                          >
                        </ul>
                        <p v-else>
                          You have not attened any training sessions this
                          seasion. You must attened at least one training
                          session and submit a traiing repoort to view training
                          statistics.
                        </p>
                      </div>

                      <div class="rpe-scale-wrapper">
                        <h3>Last session effort score</h3>
                        <div
                          v-if="trainingPerformanceData.effort == '10'"
                          class="rpe-scale-result ten"
                        >
                          <span class="big-number">10</span>
                          <h3>MAX EFFORT ACTIVITY</h3>
                          <p>
                            Feels almost impossable to keep going. Completey out
                            of breath unable to talk.
                          </p>
                        </div>

                        <div
                          v-if="trainingPerformanceData.effort == '9'"
                          class="rpe-scale-result nine"
                        >
                          <span class="big-number">9</span>
                          <h3>VERY HARD ACTIVITY</h3>
                          <p>
                            Very difficult to maintain exercise intensity. Can
                            barely breathe and speak a few words.
                          </p>
                        </div>

                        <div
                          v-if="trainingPerformanceData.effort == '78'"
                          class="rpe-scale-result eight"
                        >
                          <span class="big-number">7-8</span>
                          <h3>VIGOROUS ACTIVITY</h3>
                          <p>
                            Borderline uncomfortable. short of breath, can't
                            speak a sentence.
                          </p>
                        </div>

                        <div
                          v-if="trainingPerformanceData.effort == '46'"
                          class="rpe-scale-result four-six"
                        >
                          <span class="big-number">4-6</span>
                          <h3>MODERATE ACTIVITY</h3>
                          <p>
                            Breathing heavily, can hold a short conversation.
                            Still somewhat comfortable, but still feeling hard.
                          </p>
                        </div>

                        <div
                          v-if="trainingPerformanceData.effort == '23'"
                          class="rpe-scale-result two-three"
                        >
                          <span class="big-number">2-3</span>
                          <h3>LIGHT ACTIVITY</h3>
                          <p>
                            Feels like you can maintain for hours. Easy to
                            breath and have a conversation.
                          </p>
                        </div>

                        <div
                          v-if="trainingPerformanceData.effort == '1'"
                          class="rpe-scale-result one"
                        >
                          <span class="big-number">1</span>
                          <h3>VERY LIGHT ACTIVITY</h3>
                          <p>Light moving, very easy to do.</p>
                        </div>

                        <div
                          v-if="trainingPerformanceData.effort === undefined"
                        >
                          <div class="empty-state">
                            <img src="../img/emptyStateIllustration.svg" />
                            <p>No session effort rating data</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="all-training-reports">
                      <a
                        href="javascript:;"
                        @click="
                          openAllTrainingReportsModal('allTrainingReportsModal')
                        "
                        ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                        View all this season training stats</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'contact-tab'"
            >
              <div class="games-info">
                <div class="personal-info">
                  <div class="item">
                    <span class="label">Name:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.name }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.name
                    }}</span>
                  </div>
                  <div class="item">
                    <span class="label">Email:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.email }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.email
                    }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Dob:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.dob }}</span
                    >
                    <span v-else class="value">{{ selectedUserData.dob }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Position:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.position }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.position
                    }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Phone:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.phone }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.phone
                    }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Emergancy contact one:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.emergency_contact_one }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.emergency_contact_one
                    }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Emergancy contact two:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.emergency_contact_two }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.emergency_contact_two
                    }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Address:</span>
                    <span
                      v-if="
                        userData.userType !== 'Admin' &&
                        userData.userType !== 'Coach'
                      "
                      class="value"
                      >{{ userData.address }}</span
                    >
                    <span v-else class="value">{{
                      selectedUserData.address
                    }}</span>
                  </div>

                  <b-button
                    @click="editPlayerProfileInSettings()"
                    class="main-button"
                    size="lg"
                    variant="outline-warning"
                    >Edit profile</b-button
                  >
                </div>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'wellness-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    class="main-button"
                    @click="showModal('viewDailyWellnessReport')"
                    size="lg"
                    v-b-tooltip.hover
                    title="Submit daily mood
                    report"
                    variant="outline-success"
                    ><b-icon icon="emoji-smile"></b-icon
                  ></b-button>

                  <b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType === 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    @click="
                      download_csv(
                        thisSeasionsPlayerMoodData,
                        'player-Mood-Reports'
                      )
                    "
                    title="Export player wellness information to csv"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <!--<b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import player wellness information"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email player wellness information"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>

                  <p v-if="playerMoodData.date !== undefined">
                    Your last submitted a mood report on
                    {{ formatDate(playerMoodData.date) }}
                  </p>
                  <p v-else class="days-red-warning">
                    <b-icon
                      icon="exclamation-circle"
                      aria-hidden="true"
                    ></b-icon>
                    Please submit your first mood report
                  </p>
                </div>

                <div class="player-performance-review-info">
                  <div class="mood-data">
                    <h3>Mood and wellness score</h3>

                    <div
                      v-if="Object.keys(playerMoodData).length !== 0"
                      class=""
                    >
                      <div>
                      
                            <div class="wellness-mood-summary">
                              <h3>{{ formatDateForHuman(playerMoodData.date) }}</h3>
                              <div class="wellness-row">
                                <h3>Sleep</h3>
                                <span v-if="playerMoodData.sleep == '7'"
                                  >Amazing <img src="../img/amazing.svg" alt=""
                                /></span>
                                <span
                                  v-if="playerMoodData.sleep > '4' && playerMoodData.sleep < '7'"
                                  >Good <img src="../img/good.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.sleep == '4'"
                                  >Ok <img src="../img/ok.svg" alt=""
                                /></span>
                                <span
                                  v-if="playerMoodData.sleep >= '2' && playerMoodData.sleep < '4'"
                                  >Not so good
                                  <img src="../img/notSoGood.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.sleep < '2'"
                                  >Bad <img src="../img/bad.svg" alt=""
                                /></span>
                              </div>

                              <div class="wellness-row">
                                <h3>Happyness</h3>
                                <span v-if="playerMoodData.happyness == '7'"
                                  >Amazing <img src="../img/amazing.svg" alt=""
                                /></span>
                                <span
                                  v-if="
                                    playerMoodData.happyness > '4' && playerMoodData.happyness < '7'
                                  "
                                  >Good <img src="../img/good.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.happyness == '4'"
                                  >Ok <img src="../img/ok.svg" alt=""
                                /></span>
                                <span
                                  v-if="
                                    playerMoodData.happyness >= '2' &&
                                    playerMoodData.happyness < '4'
                                  "
                                  >Not so good
                                  <img src="../img/notSoGood.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.happyness < '2'"
                                  >Bad <img src="../img/bad.svg" alt=""
                                /></span>
                              </div>

                              <div class="wellness-row">
                                <h3>Muscle soreness</h3>
                                <span v-if="playerMoodData.muscle_soreness == '7'"
                                  >None <img src="../img/amazing.svg" alt=""
                                /></span>
                                <span
                                  v-if="
                                    playerMoodData.muscle_soreness > '4' &&
                                    playerMoodData.muscle_soreness < '7'
                                  "
                                  >not much <img src="../img/good.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.muscle_soreness == '4'"
                                  >Ok <img src="../img/ok.svg" alt=""
                                /></span>
                                <span
                                  v-if="
                                    playerMoodData.muscle_soreness >= '2' &&
                                    playerMoodData.muscle_soreness < '4'
                                  "
                                  >Sore <img src="../img/notSoGood.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.muscle_soreness < '2'"
                                  >Very sore <img src="../img/bad.svg" alt=""
                                /></span>
                              </div>

                              <div class="wellness-row">
                                <h3>Fatigue</h3>
                                <span v-if="playerMoodData.fatigue == '7'"
                                  >Ready to go
                                  <img src="../img/amazing.svg" alt=""
                                /></span>
                                <span
                                  v-if="
                                    playerMoodData.fatigue > '4' && playerMoodData.fatigue < '7'
                                  "
                                  >Slugish <img src="../img/good.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.fatigue == '4'"
                                  >Bit tired<img src="../img/ok.svg" alt=""
                                /></span>
                                <span
                                  v-if="
                                    playerMoodData.fatigue >= '2' && playerMoodData.fatigue < '4'
                                  "
                                  >Very tired
                                  <img src="../img/notSoGood.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.fatigue < '2'"
                                  >Exhausted <img src="../img/bad.svg" alt=""
                                /></span>
                              </div>

                              <div class="wellness-row">
                                <h3>Stress</h3>
                                <span v-if="playerMoodData.stress == '7'"
                                  >None <img src="../img/amazing.svg" alt=""
                                /></span>
                                <span
                                  v-if="playerMoodData.stress > '4' && playerMoodData.stress < '7'"
                                  >Not sure <img src="../img/good.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.stress == '4'"
                                  >Ok <img src="../img/ok.svg" alt=""
                                /></span>
                                <span
                                  v-if="playerMoodData.stress >= '2' && playerMoodData.stress < '4'"
                                  >Bit stressed
                                  <img src="../img/notSoGood.svg" alt=""
                                /></span>
                                <span v-if="playerMoodData.stress < '2'"
                                  >Really stressed
                                  <img src="../img/bad.svg" alt=""
                                /></span>
                              </div>
                            </div>
                          
                      
                      </div>

                      <div class="mood-chart">
                        <Bar-Chart :chartData="playerMoodBarChartDataSet" />
                      </div>
                    </div>
                  </div>

                  <div
                    class="charts">
                    <h3>Height</h3>
                    <p>{{ selectedUserData.height }} kg</p>

                    <b-button
                      class="main-button"
                      @click="showModal('addHeight')"
                      size="lg"
                      variant="outline-success"
                      ><b-icon icon="person-lines-fill"></b-icon> Record
                      height</b-button
                    >
                  </div>

                  <div
                    class="charts">
                    <h3>Weight</h3>
                    <p>{{ selectedUserData.weight }} cm</p>

                    <b-button
                      class="main-button"
                      @click="showModal('addWeight')"
                      size="lg"
                      variant="outline-success"
                      ><b-icon icon="person-check-fill"></b-icon> Record
                      Weight</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'injury-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    class="main-button"
                    @click="openPlayerInjuryModal('playerInjuryModal')"
                    size="lg"
                    v-b-tooltip.hover
                    title="Submit player injury report"
                    variant="outline-success"
                    ><b-icon icon="file-text" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    @click="
                      download_csv(
                        allPlayerInjuryReports,
                        'player-Injury-Reports'
                      )
                    "
                    title="Export player injuries to csv"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <!--<b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import player injuries"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <b-button
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType !== 'Coach' ||
                      userData.id === '964'
                    "
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email player injuries"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>
                <h3>Player injuries this season</h3>

                <div v-if="playerInjuryData.length > 0" class="games-info">
                  <div class="player-performance-review-info">
                    <table class="w-a-style injury-table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Injury</th>
                          <th>More detail</th>
                          <th>Return</th>
                          <th>Status</th>
                          <th>Contact</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="injury in playerInjuryData"
                          :key="injury.id"
                          :class="
                            injury.injury_status === 'Fully recoverd'
                              ? 'full-recoverd'
                              : ''
                          "
                        >
                          <td>{{ formatDateForHuman(injury.created_date) }}</td>
                          <td>{{ injury.injury_type }}</td>
                          <td>{{ injury.more_detail }}</td>
                          <td>{{ injury.return_time }}</td>
                          <td class="injury-status">
                            {{ injury.injury_status }}
                          </td>
                          <td>{{ injury.contact }}</td>
                          <td>
                            <div class="player-game-responce">
                              <b-button
                                @click="
                                  editInjuryModal(
                                    'editPlayerInjuryModal',
                                    injury
                                  )
                                "
                                variant="outline-warning"
                                ><b-icon
                                  icon="pencil"
                                  aria-hidden="true"
                                ></b-icon>
                                Edit</b-button
                              >
                              <b-button
                                @click="deletePlayerInjuryReport(injury.id)"
                                variant="outline-danger"
                                ><b-icon
                                  icon="trash"
                                  aria-hidden="true"
                                ></b-icon>
                                Delete</b-button
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="player-injury-body-map">
                      <div v-for="injury in playerInjuryData" :key="injury.id">
                        <div
                          v-if="injury.injury_type === 'Left arm injury'"
                          v-b-tooltip.hover="'Left arm injury'"
                          class="injury-spot Left-arm-injury"
                        >
                          <span></span>
                        </div>

                        <div
                          v-if="injury.injury_type === 'Right arm injury'"
                          v-b-tooltip.hover="'Right arm injury'"
                          class="injury-spot Right-arm-injury"
                        >
                          <span></span>
                        </div>

                        <div
                          v-if="injury.injury_type === 'Left calf injury'"
                          v-b-tooltip.hover="'Left calf injury'"
                          class="injury-spot Left-calf-injury"
                        >
                          <span></span>
                        </div>

                        <div
                          v-if="injury.injury_type === 'Right calf injury'"
                          v-b-tooltip.hover="'Right calf injury'"
                          class="injury-spot Right-calf-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left ankle injury'"
                          v-b-tooltip.hover="'Left ankle injury'"
                          class="injury-spot Left-ankle-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right ankle injury'"
                          v-b-tooltip.hover="'Right ankle injury'"
                          class="injury-spot Right-ankle-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left Knee injury'"
                          v-b-tooltip.hover="'Left Knee injury'"
                          class="injury-spot Left-Knee-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right Knee injury'"
                          v-b-tooltip.hover="'Right Knee injury'"
                          class="injury-spot Right-Knee-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left Quad injury'"
                          v-b-tooltip.hover="'Left Quad injury'"
                          class="injury-spot Left-Quad-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right Quad injury'"
                          v-b-tooltip.hover="'Right Quad injury'"
                          class="injury-spot Right-Quad-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left hamstring injury'"
                          v-b-tooltip.hover="'Left hamstring injury'"
                          class="injury-spot Left-hamstring-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right hamstring injury'"
                          v-b-tooltip.hover="'Right hamstring injury'"
                          class="injury-spot Right-hamstring-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left groin injury'"
                          v-b-tooltip.hover="'Left groin injury'"
                          class="injury-spot Left-groin-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right groin injury'"
                          v-b-tooltip.hover="'Right groin injury'"
                          class="injury-spot Right-groin-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left Hip injury'"
                          v-b-tooltip.hover="'Left Hip injury'"
                          class="injury-spot Left-Hip-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right Hip injury'"
                          v-b-tooltip.hover="'Right Hip injury'"
                          class="injury-spot Right-Hip-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right Shoulder injury'"
                          v-b-tooltip.hover="'Right Shoulder injury'"
                          class="injury-spot Right-Shoulder-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left Shoulder injury'"
                          v-b-tooltip.hover="'Left Shoulder injury'"
                          class="injury-spot Left-Shoulder-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="
                            injury.injury_type === 'Right Wrist and hand injury'
                          "
                          v-b-tooltip.hover="'Right Wrist and hand injury'"
                          class="injury-spot Right-Wrist-and-hand-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="
                            injury.injury_type === 'Left Wrist and hand injury'
                          "
                          v-b-tooltip.hover="'Left Wrist and hand injury'"
                          class="injury-spot Left-Wrist-and-hand-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Head injury'"
                          v-b-tooltip.hover="'Head injury'"
                          class="injury-spot Head-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Left foot injury'"
                          v-b-tooltip.hover="'Left foot injury'"
                          class="injury-spot Left-foot-injury"
                        >
                          <span></span>
                        </div>
                        <div
                          v-if="injury.injury_type === 'Right foot injury'"
                          v-b-tooltip.hover="'Right foot injury'"
                          class="injury-spot Right-foot-injury"
                        >
                          <span></span>
                        </div>
                      </div>
                      <img src="../img/body-sil.svg" />
                    </div>

                    <div class="seasion-injury-summary">
                      <h3>Injury history</h3>

                      <ul class="injury-list">
                        <li>
                          <p>
                            Head / Heck injury
                            <span>{{
                              playerInjuryHistoryAmounts.headNeckInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Shoulder injury
                            <span>{{
                              playerInjuryHistoryAmounts.shoulderInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Arm injury
                            <span>{{
                              playerInjuryHistoryAmounts.armInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Wrist and hand injury
                            <span>{{
                              playerInjuryHistoryAmounts.wristAndHandInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Hip injury
                            <span>{{
                              playerInjuryHistoryAmounts.hipInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Groin injury
                            <span>{{
                              playerInjuryHistoryAmounts.groinInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Quad injury
                            <span>{{
                              playerInjuryHistoryAmounts.quadInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Hamstring injury
                            <span>{{
                              playerInjuryHistoryAmounts.hamstringInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Knee injury
                            <span>{{
                              playerInjuryHistoryAmounts.kneeInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Calf injury
                            <span>{{
                              playerInjuryHistoryAmounts.calfInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Ankle injury
                            <span>{{
                              playerInjuryHistoryAmounts.ankleInjury
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            foot injury
                            <span>{{
                              playerInjuryHistoryAmounts.footInjury
                            }}</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-else class="empty-state">
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No player injury data</p>
                </div>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'review-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    class="main-button"
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType === 'Coach'
                    "
                    @click="
                      openPlayerPerformanceModal('playerPerformanceReportModal')
                    "
                    size="lg"
                    variant="outline-success"
                    ><b-icon icon="file-text" aria-hidden="true"></b-icon>
                    Performance Review</b-button
                  >

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    @click="
                      download_csv(
                        allplayerPerformanceReportData,
                        'player-Performance-Reviews'
                      )
                    "
                    title="Export player performance reviews to csv"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <!--<b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import player performance reviews"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>-->

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email player performance reviews"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>

                <div class="player-performance-review-info">
                  <table
                    v-if="allplayerPerformanceReportData.length > 0"
                    class="w-a-style"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Document</th>
                        <th>Score</th>
                        <th>Coach</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="review in allplayerPerformanceReportData"
                        :key="review.id"
                      >
                        <td>{{ formatDateForHuman(review.review_date) }}</td>
                        <td>
                          <a
                            @click="openPlayerPerformanceReview(review.id)"
                            href="javascript:;"
                            ><b-icon icon="file-text"></b-icon> Performance
                            report details</a
                          >
                        </td>
                        <td class="td-no-padding">
                          <CircularPercentage
                            :review="review"
                            :allPlayerReviews="null"
                            circularType="single"
                          />
                        </td>
                        <td>kyle wilkie</td>
                      </tr>
                    </tbody>
                  </table>

                  <div v-else class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>No player Performance Review data.</p>
                  </div>

                  <div
                    v-if="allplayerPerformanceReportData.length > 0"
                    class="review-average-score"
                  >
                    <CircularPercentage
                      :review="null"
                      :allPlayerReviews="allplayerPerformanceReportData"
                      circularType="player-summary-stats"
                    />
                  </div>

                  <div
                    v-if="allplayerPerformanceReportData.length > 0"
                    class="physical-performance"
                  >
                    <h3>Physical Performance</h3>
                    <p class="small-date-player-coach">
                      Last reviewed
                      {{
                        formatDateForHuman(playerPhysicalPerformanceData.date)
                      }}
                    </p>
                    <ul class="key-value-list">
                      <li>
                        <span>10m SPRINT:</span>
                        <span class="time-value"
                          >{{
                            playerPhysicalPerformanceData.tenM_sprint_time
                          }}s</span
                        >
                        <img
                          v-if="
                            playerPhysicalPerformanceData.tenM_sprint_time_icon ===
                            'better'
                          "
                          class="chev-up"
                          src="../img/chev-green-up.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.tenM_sprint_time_icon ===
                            'worse'
                          "
                          class="chev-up"
                          src="../img/chev-red-down.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.tenM_sprint_time_icon ===
                            'same'
                          "
                          class="chev-up"
                          src="../img/line.svg"
                        />
                      </li>
                      <li>
                        <span>40m SPRINT:</span>
                        <span class="time-value"
                          >{{
                            playerPhysicalPerformanceData.fortyM_sprint_time
                          }}s</span
                        >
                        <img
                          v-if="
                            playerPhysicalPerformanceData.fortyM_sprint_time_icon ===
                            'better'
                          "
                          class="chev-up"
                          src="../img/chev-green-up.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.fortyM_sprint_time_icon ===
                            'worse'
                          "
                          class="chev-up"
                          src="../img/chev-red-down.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.fortyM_sprint_time_icon ===
                            'same'
                          "
                          class="chev-up"
                          src="../img/line.svg"
                        />
                      </li>
                      <li>
                        <span>Countermovement jump:</span>
                        <span class="time-value"
                          >{{
                            playerPhysicalPerformanceData.cuntermovement_jump
                          }}
                          cm</span
                        >
                        <img
                          v-if="
                            playerPhysicalPerformanceData.cuntermovement_jump_icon ===
                            'better'
                          "
                          class="chev-up"
                          src="../img/chev-green-up.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.cuntermovement_jump_icon ===
                            'worse'
                          "
                          class="chev-up"
                          src="../img/chev-red-down.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.cuntermovement_jump_icon ===
                            'same'
                          "
                          class="chev-up"
                          src="../img/line.svg"
                        />
                      </li>
                      <li>
                        <span>Reactive strength index:</span>
                        <span class="time-value">{{
                          playerPhysicalPerformanceData.reactive_strength_index
                        }}</span>
                        <img
                          v-if="
                            playerPhysicalPerformanceData.reactive_strength_index_icon ===
                            'better'
                          "
                          class="chev-up"
                          src="../img/chev-green-up.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.reactive_strength_index_icon ===
                            'worse'
                          "
                          class="chev-up"
                          src="../img/chev-red-down.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.reactive_strength_index_icon ===
                            'same'
                          "
                          class="chev-up"
                          src="../img/line.svg"
                        />
                      </li>
                      <li>
                        <span>30-15 Intermittent Fitness Test:</span>
                        <span class="time-value">{{
                          playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test
                        }}</span>
                        <img
                          v-if="
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test_icon ===
                            'better'
                          "
                          class="chev-up"
                          src="../img/chev-green-up.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test_icon ===
                            'worse'
                          "
                          class="chev-up"
                          src="../img/chev-red-down.svg"
                        />
                        <img
                          v-if="
                            playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test_icon ===
                            'same'
                          "
                          class="chev-up"
                          src="../img/line.svg"
                        />
                      </li>
                    </ul>

                    <div class="buttons-row">
                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Add Physical Performance data"
                        @click="addNewPhysicalPerformanceTimeModal()"
                        ><b-icon icon="watch" aria-hidden="true"></b-icon>
                      </b-button>

                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="View Physical Performance history"
                        @click="openPhysicalPerformanceHistoryModal()"
                        ><b-icon icon="eye" aria-hidden="true"></b-icon
                      ></b-button>

                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        @click="
                          download_csv(
                            allPlayerPhysicalPerformanceData,
                            'player-Physical-Performance-data'
                          )
                        "
                        title="Export Physical Performance to csv"
                        ><b-icon icon="download" aria-hidden="true"></b-icon
                      ></b-button>

                      <!--<b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Import Physical Performance reviews"
                        ><b-icon icon="upload" aria-hidden="true"></b-icon
                      ></b-button>-->

                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Email Physical Performance reviews"
                        ><b-icon icon="envelope" aria-hidden="true"></b-icon
                      ></b-button>
                    </div>
                  </div>

                  <div class="performance-targets">
                    <h3>Current Performance targets</h3>

                    <div v-if="playerPerformanceReviewTargetsData.length > 0">
                      <div
                        class="target-info"
                        v-for="performanceTarget in getPlayersCurrentPerformanceTargets()"
                        :key="performanceTarget.id"
                      >
                        <h4><img src="./img/target.svg" alt="" /> Target</h4>
                        <span
                          v-if="performanceTarget.complete === 0"
                          class="small-date-player-coach"
                          >Not complete
                        </span>
                        <span v-else class="small-date-player-coach completed"
                          >Complete
                        </span>

                        <div class="target-content">
                          <div v-html="performanceTarget.target"></div>

                          <b-button
                            size="lg"
                            class="main-button"
                            variant="outline-warning"
                            v-b-tooltip.hover
                            title="Edit"
                            @click="
                              performanceTargetsModal(
                                performanceTarget,
                                'performanceTargetsModal'
                              )
                            "
                            ><b-icon icon="pencil" aria-hidden="true"></b-icon
                          ></b-button>
                        </div>
                      </div>
                    </div>

                    <div class="buttons-row">
                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="View Performance targets history"
                        @click="performanceTargetsHistoryModal()"
                        ><b-icon icon="eye" aria-hidden="true"></b-icon
                      ></b-button>

                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Add new Performance target"
                        @click="
                          performanceTargetsModal(
                            {},
                            'newPerformanceTargetsModal'
                          )
                        "
                        ><b-icon icon="plus" aria-hidden="true"></b-icon
                      ></b-button>

                      <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Email Performance targets"
                        ><b-icon icon="envelope" aria-hidden="true"></b-icon
                      ></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'learning-plan-tab'"
            >
              <div class="games-info">
                <TrainingPlansLinks  :selectedUserData="selectedUserData"/>
              </div>
            </div>

            <div
              class="payments-tab tab-content"
              v-if="selectedTab === 'payments-tab'"
            >
              <h3>Manage your subscription and billing details</h3>

              <div class="games-info">
                <div v-if="playersPayment.length > 0">
                  <p>
                    <a
                      href="https://billing.stripe.com/p/login/eVa8z1cuPfZ53pS5kk"
                      >Subscription and billing details</a
                    >
                    You can use the portal to manage your subscription and
                    billing details.
                  </p>

                  <vue-good-table
                    style-class="vgt-table bordered"
                    :columns="columns"
                    :rows="playersPayment"
                    :search-options="{
                      enabled: true,
                      placeholder: 'Search payments',
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'date'">
                        <span>{{
                          formatDates(props.formattedRow[props.column.field])
                        }}</span>
                      </span>
                      <span v-else-if="props.column.field == 'total_price'">
                        {{ pounds(props.formattedRow[props.column.field]) }}
                      </span>
                      <span v-else-if="props.column.field == 'payment_state'">
                        <span :class="props.formattedRow[props.column.field]">{{
                          props.formattedRow[props.column.field]
                        }}</span>
                      </span>
                      <span v-else>
                        <span>{{
                          props.formattedRow[props.column.field]
                        }}</span>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
                <div v-else>
                  <p>
                    We currently do not have any payment information on record.
                    To proceed, kindly subscribe to a payment plan through the
                    <router-link :to="{ name: 'payments' }"
                      >Payments and Subscriptions module</router-link
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'parents-meeting-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Export Parents evenings to csv"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import Parents evenings document"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email Parents evenings data"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>
                <p>Parents evenings cover:</p>

                <ul>
                  <li>The club objectives for the forthcoming season</li>
                  <li>Club fees and registration processes</li>
                  <li>Club diary of events for the year</li>
                  <li>Team management and support</li>
                  <li>Club communication process</li>
                  <li>To recruit additional volunteers</li>
                  <li>Club welfare policy</li>
                  <li>Club Codes of Conduct and</li>
                  <li>disciplinary policy</li>
                </ul>

                <table class="w-a-style">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Discussion points and actions</th>
                      <th>Documents</th>
                      <th>Coach</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>April 2022</td>
                      <td>
                        Overview of progress and outline of player managment
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href="https://football-personal-training.uk/files/W-academy-IDP-sheet-Ollie-Paxton-2010.pdf"
                          >W-academy-IDP-sheet-Ollie-Paxton-2010.pdf</a
                        >
                      </td>
                      <td>Kyle and Olllie's parents present</td>
                      <td>
                        <div class="player-game-responce">
                          <b-button variant="outline-warning"
                            ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                            Edit</b-button
                          >
                          <b-button variant="outline-danger"
                            ><b-icon icon="trash" aria-hidden="true"></b-icon>
                            Delete</b-button
                          >
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>July 2022</td>
                      <td>
                        Overview of progress and outline of player managment
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href="https://football-personal-training.uk/files/W-academy-IDP-sheet-Ollie-Paxton-2010.pdf"
                          >W-academy-IDP-sheet-Ollie-Paxton-2010.pdf</a
                        >
                      </td>
                      <td>Kyle and Ollie's parents present</td>
                      <td>
                        <div class="player-game-responce">
                          <b-button variant="outline-warning"
                            ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                            Edit</b-button
                          >
                          <b-button variant="outline-danger"
                            ><b-icon icon="trash" aria-hidden="true"></b-icon>
                            Delete</b-button
                          >
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>October 2022</td>
                      <td>
                        Overview of progress and outline of player managment
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href="https://football-personal-training.uk/files/W-academy-IDP-sheet-Ollie-Paxton-2010.pdf"
                          >W-academy-IDP-sheet-Ollie-Paxton-2010.pdf</a
                        >
                      </td>
                      <td>Kyle and Olllie's parents present</td>
                      <td>
                        <div class="player-game-responce">
                          <b-button variant="outline-warning"
                            ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                            Edit</b-button
                          >
                          <b-button variant="outline-danger"
                            ><b-icon icon="trash" aria-hidden="true"></b-icon>
                            Delete</b-button
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'coach-tab'"
            >
              <!--<div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    class="main-button"
                    @click="showModal('viewPlayersCV')"
                    size="lg"
                    v-b-tooltip.hover
                    title="View players c.v"
                    variant="outline-success"
                    ><b-icon icon="eye"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Download players c.v"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email players c.v"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>

                <div class="player-cv">
                  <h3>Personal profile</h3>

                  <p>
                    Dedicated, physically fit, and creative footballer committed
                    to helping the team reach its season’s objectives, winning
                    trophies, and upholding the team’s historical legacy.
                    Demonstrate commitment to rigorous training regimes,
                    resulting in a career-high of 13 goals last season. Leverage
                    soft skills like empathy and active listening in coaching
                    young academy team substitutes and fringe players aiming for
                    a permanent place in their team’s first eleven.
                  </p>

                  <h3>PLAYING EXPERIENCE</h3>
                  <table class="w-a-style">
                    <thead>
                      <tr>
                        <th>Club</th>
                        <th>Time at the club</th>
                        <th>Playing stats</th>
                        <th>More information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>East Kilbride Burgh</td>
                        <td>2017-2019</td>
                        <td>Format: 4 v 4 and 5 v 5.</td>
                        <td>
                          <a
                            href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                            >Facebook</a
                          ><br />
                          <a href="https://twitter.com/burghek2010?lang=en-GB"
                            >Twitter</a
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Hunter primary school team</td>
                        <td>2019-2021</td>
                        <td>Format: 7 v 7. Reginal cup winners 2021</td>
                        <td>
                          <a
                            href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                            >Facebook</a
                          ><br />
                          <a href="https://twitter.com/burghek2010?lang=en-GB"
                            >Twitter</a
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>East Kilbride FC</td>
                        <td>2019-2021</td>
                        <td>Format: 7 v 7 and 9 v 9</td>
                        <td>
                          <a
                            href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                            >Facebook</a
                          ><br />
                          <a href="https://twitter.com/burghek2010?lang=en-GB"
                            >Twitter</a
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Calderglen high school team</td>
                        <td>2022-</td>
                        <td>Format: 11 v 11.</td>
                        <td>
                          <a
                            href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                            >Facebook</a
                          ><br />
                          <a href="https://twitter.com/burghek2010?lang=en-GB"
                            >Twitter</a
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>Blantyre soccer academy</td>
                        <td>Cup compition</td>
                        <td>
                          Format: 11 v 11. Played in 5 games for Blantyre soccer
                          academy, scored 3 goals helping the team get to the
                          knockout stages of the compition.
                        </td>
                        <td>
                          <a
                            href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                            >Facebook</a
                          ><br />
                          <a href="https://twitter.com/burghek2010?lang=en-GB"
                            >Twitter</a
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>W Academy</td>
                        <td>2022-</td>
                        <td>Format: 7 v 7 and 11 v 11</td>
                        <td>
                          <a
                            href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                            >Facebook</a
                          ><br />
                          <a href="https://twitter.com/burghek2010?lang=en-GB"
                            >Twitter</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="education">
                    <h3>EDUCATION</h3>

                    <ul>
                      <li>
                        <span class="title"
                          >University of Bath, Claverton Down, Bath
                          (2015-2018)</span
                        >
                        <span class="info"
                          >Bachelor of Science (Hons.) in Sports Science, Upper
                          second-class honours (2:1)</span
                        >

                        <span class="info"
                          >Relevant Modules: Sports Management, Marketing and
                          Media, Nutrition and Metabolism, Physiology of
                          Exercise, Health and Nutrition, Applied Sport
                          Biomechanics Cats College, Canterbury, Kent</span
                        >
                      </li>

                      <li>
                        <span class="title"
                          >A-levels: Maths (A), French (A), English Literature
                          (A)</span
                        >
                        <span class="info"
                          >GCSEs: 10 A-C including Maths, Biology, and Physical
                          and Health Education</span
                        >
                      </li>
                    </ul>
                  </div>

                  <div class="hobbies">
                    <h3>CAREER STATISTICS</h3>

                    <ul>
                      <li>Goals: 31</li>
                      <li>Shots: 104</li>
                      <li>Assists: 43</li>
                      <li>Pass completion: 84.6%</li>
                    </ul>
                  </div>

                  <div class="hobbies">
                    <h3>Interest hobbies outside of football</h3>
                    <table class="w-a-style">
                      <thead>
                        <tr>
                          <th>Interest hobbies</th>
                          <th>More information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Drawing, sketching and painting.</td>
                          <td>
                            Ollie loves to draw, spending some of his spare time
                            being creative.
                          </td>
                        </tr>

                        <tr>
                          <td>Running / Athletics</td>
                          <td>
                            Ollie has been intrested in running and athletics
                            since he was very small. He has one many regional
                            awards within athletics.
                          </td>
                        </tr>

                        <tr>
                          <td>Computer games</td>
                          <td>
                            Ollie loves to play computer games, Fifa and Call of
                            Duty are his favourite games at the moment.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="players-videos">
                    <h3>Videos</h3>
                    <div class="sessions-info">
                      <a
                        target="_blank"
                        href="https://football-personal-training.uk/videos/ollie-second-goal2019-07-20%20at%201.33.45%20PM.mp4"
                        class="session-card"
                      >
                        <img src="./img/video.jpg" alt="" />
                        <p class="small-date">Video</p>
                        <h3>Ollie | Goals EKB 1</h3>
                      </a>

                      <a
                        target="_blank"
                        href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                        class="session-card"
                      >
                        <img src="./img/video.jpg" alt="" />
                        <p class="small-date">Video</p>
                        <h3>Ollie | Goals EKB 2</h3>
                      </a>

                      <a
                        target="_blank"
                        href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                        class="session-card"
                      >
                        <img src="./img/video.jpg" alt="" />
                        <p class="small-date">Video</p>
                        <h3>Ollie | Pentaly EKB 3</h3>
                      </a>

                      <a
                        target="_blank"
                        href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                        class="session-card"
                      >
                        <img src="./img/video.jpg" alt="" />
                        <p class="small-date">Video</p>
                        <h3>Ollie | Goals EKFC 1</h3>
                      </a>

                      <a
                        target="_blank"
                        href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                        class="session-card"
                      >
                        <img src="./img/video.jpg" alt="" />
                        <p class="small-date">Video</p>
                        <h3>Ollie | Goals EKFC 2</h3>
                      </a>
                    </div>
                  </div>
                </div>

                <br />
                <br />
                <b-button variant="outline-warning"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                  Edit</b-button
                >
              </div>-->
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'code-of-conduct-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Download players and parents code of conduct"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email players and parents code of conduct"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>
                <div class="players-code">
                  <h3>Players code of conduct</h3>
                  <p>
                    The rules within the Academy are based upon the principle of
                    ‘consideration and respect for others.’ This ensures that
                    everyone at the Academy can be safe, secure and happy during
                    their time at the club.
                  </p>

                  <ul>
                    <li>
                      Players are reminded that they represent The W Academy.
                      and should behave in an appropriate manner;
                    </li>

                    <li>
                      Players are required to wear club colours with pride.
                    </li>

                    <li>
                      Swearing, fighting, bullying and bad behaviour of any kind
                      will not be tolerated at any time All match kit will be
                      provided.
                    </li>

                    <li>
                      Players should attend both training and matches with clean
                      and polished boots;
                    </li>

                    <li>
                      Jewellery must not be worn for matches or training
                      sessions, both for personal safety and that of other
                      players;
                    </li>

                    <li>
                      All Academy players are expected to keep themselves fit at
                      all times through a sensible nourishing diet, appropriate
                      rest and exercise. They may be given instructions on
                      exercises to complete away from the Academy and keep their
                      Player Planner up-to-date for inspection by their age
                      group Coach;
                    </li>

                    <li>
                      When travelling to away fixtures, players should ensure
                      they have all details of venue and time of arrival.
                    </li>

                    <li>
                      Players should arrive 60 minutes prior to kick off(where
                      possible) time for home games. For away matches, players
                      will be instructed by coach;
                    </li>

                    <li>
                      Players, under no circumstances, should argue or dispute
                      decisions given by the referee or his assistants;
                    </li>

                    <li>
                      Players attending the Academy must make a commitment to
                      adhere strictly to their individual programme at school;
                    </li>

                    <li>
                      All players are requested not to wear any jackets or kits
                      affiliated to any other football club on training evenings
                    </li>
                  </ul>
                </div>

                <div class="players-code">
                  <h3>Parents’/Guardians’ Code of Conduct</h3>

                  <p>
                    We ask parents/guardians to stand on the appropriate side of
                    the pitch, opposite to the Coaches and substitutes. On grass
                    pitches, please stay behind the cones to permit referees
                    sight of the lines;
                  </p>

                  <p>
                    We ask parents/guardians not to get involved in any form of
                    negative shouting from the touchline. The coaching staff
                    work hard to create the correct environment for all players
                    to express themselves fully, both in training and matches,
                    without fear of making mistakes. The support of
                    parents/guardians is requested to achieve the environment
                    and please leave the coaching to the age group Coaches;
                  </p>

                  <p>
                    If there is an issue that concerns the player or yourself,
                    then please raise it with the appropriate Academy staff;
                  </p>

                  <p>
                    Please ensure that players get plenty of rest. An early
                    night before a game or after training is recommended;
                  </p>

                  <p>
                    Try to get players to eat healthily and at regular
                    intervals.
                  </p>
                  <ul>
                    <li>
                      A good breakfast before a morning game is essential to set
                      up the body for the strenuous work required during a game.
                    </li>
                    <li>
                      Make sure players have an adequate supply of fluids to
                      drink before and after a game;
                    </li>
                  </ul>

                  <p>
                    Try to get players to take responsibility for their football
                    education, i.e. packing their kit bag, cleaning their boots,
                    preparing fluids for the day and making a snack for after
                    the game;
                  </p>

                  <p>
                    Please feel free to talk to the appropriate Coach for
                    feedback on players’ performance. At the start of each
                    season there will be an induction meeting with players and
                    parents/carers of the players to inform them of the games
                    programme and any additional information. Any concerns they
                    may have can be addressed at this meeting.
                  </p>
                </div>

                <b-button variant="outline-warning"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                  Edit</b-button
                >
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'charter-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Download players and parents charter"
                    ><b-icon icon="download" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email players and parents charter"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>
                <div class="players-charter charter">
                  <h3>Players Charter</h3>

                  <ul>
                    <li>
                      Accept that striving to win is more important than winning
                      itself
                    </li>
                    <li>Avoid pressurising yourself about winning or losing</li>
                    <li>Take pride in doing your best</li>
                    <li>
                      Show good sportsmanship and respect to other players
                    </li>
                    <li>Respect the rules</li>
                    <li>Set your own standards of excellence</li>
                    <li>
                      Play and think as a team member and not as an individual
                    </li>
                    <li>Take pride in your appearance on and off the pitch</li>
                    <li>Make an effort to eat the correct foods</li>
                    <li>
                      Treat the pre-match warm up and post match warm down
                      seriously as they can enhance your performance as a
                      footballer
                    </li>
                    <li>
                      Always remember you are representing yourself, your family
                      and the club
                    </li>
                    <li>
                      Behaviour on and off the pitch must be of the highest
                      standard
                    </li>
                    <li>Be prepared to discuss problems with your coach</li>
                    <li>
                      Attendance and punctuality at games and training sessions
                      is essential
                    </li>
                    <li>
                      I have read the above charter and agree to abide with
                      these points
                    </li>
                  </ul>

                  <p>Player Signature</p>
                  <div class="signatureWrapper">
                    <vueSignature
                      ref="playerSignature"
                      :sigOption="signatureOptions"
                      :w="'800px'"
                      :h="'400px'"
                      :disabled="false"
                    ></vueSignature>

                    <div class="player-game-responce">
                      <button @click="saveSignature('playerSignature')">
                        Save
                      </button>
                      <button @click="clearSignature('playerSignature')">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>

                <div class="players-charter charter">
                  <h3>Parents Charter</h3>

                  <li>
                    Accept that striving to win is more important than winning
                    itself
                  </li>
                  <li>Avoid pressurising children about winning or losing</li>
                  <li>
                    Help children focus on the process of participation rather
                    than the outcome ie “How did you do?” not “Did you win?”
                  </li>
                  <li>
                    Show the benefits of teamwork, health, exercise and self
                    esteem
                  </li>
                  <li>
                    Show good sportsmanship and respect to other teams and
                    players
                  </li>
                  <li>
                    Be a good role model for your child in relation to sporting
                    behaviour
                  </li>
                  <li>Respect the rules</li>
                  <li>
                    Emphasise enjoyment, provide encouragement and be optimistic
                    about your childs performance
                  </li>
                  <li>
                    Encourage a balanced lifestyle between sporting, academic,
                    social and other interests
                  </li>
                  <li>
                    Encourage childrens independence and let them set their own
                    standards of excellence
                  </li>
                  <li>
                    Support your child in his preparation for coaching sessions,
                    co-ordination ses- sions and matches
                  </li>
                  <li>
                    I have read the above charter and agree to abide with these
                    points
                  </li>

                  <p>Parent Signature</p>
                  <div class="signatureWrapper">
                    <vueSignature
                      ref="parentSignature"
                      :sigOption="signatureOptions"
                      :w="'800px'"
                      :h="'400px'"
                      :disabled="false"
                    ></vueSignature>

                    <div class="player-game-responce">
                      <button @click="saveSignature('playerSignature')">
                        Save
                      </button>
                      <button @click="clearSignature('playerSignature')">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'game-prep-tab'"
            >
              <div class="games-info">
                <div class="button-wrapper-top">
                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Import Game preparation data"
                    ><b-icon icon="upload" aria-hidden="true"></b-icon
                  ></b-button>

                  <b-button
                    size="lg"
                    class="main-button"
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Email Game preparation"
                    ><b-icon icon="envelope" aria-hidden="true"></b-icon
                  ></b-button>
                </div>
                <p>
                  Enjoy a good night’s rest by going to bed early after ensuring
                  that your kit bag is packed with your
                </p>
                <ul>
                  <li>Boots</li>
                  <li>Shin guards</li>
                  <li>Towel</li>
                  <li>Rain jacket</li>
                  <li>Toiletries</li>
                  <li>Water bottle</li>
                  <li>Isotonic drink</li>
                  <li>Other items at Coach’s request</li>
                </ul>

                <ul>
                  <li>
                    Prepare yourself if you have a long journey by bringing
                    reading material, quiz books, personal stereo and possibly
                    school reading material.
                  </li>
                  <li>
                    Have a substantial breakfast as outlined in the section on
                    ‘Food for Football.’
                  </li>
                  <li>
                    Travel to the game in your tracksuit, polo shirt and
                    trainers unless instructed otherwise by your Coach;
                  </li>
                  <li>
                    Allow yourself plenty of time to get to your pick up
                    location. You should arrive 15 minutes before your departure
                    time;
                  </li>
                  <li>
                    It is advisable to bring a snack to eat after the match;
                  </li>
                  <li>
                    If you follow these simple instructions we are confident
                    that you will enjoy the day and always make sure you enjoy
                    the game.
                  </li>
                </ul>

                <b-button variant="outline-warning"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon>
                  Edit</b-button
                >
              </div>
            </div>

            <b-modal
              id="addHeight"
              size="lg"
              :hide-footer="Boolean(true)"
            >
              <h2>Add your height</h2>

              <div class="form__item">
                <b-input-group append="cm">
                  <br />
                  <b-form-input
                    type="number"
                    placeholder="Height"
                    v-model="height"
                  ></b-form-input>
                </b-input-group>
              </div>

              <b-button @click="saveHeight()" variant="outline-success">Save</b-button>

            </b-modal>

            <b-modal
              id="addWeight"
              :hide-footer="Boolean(true)"
            >
              <h2>Add your weight</h2>

              <div class="form__item">
                <b-input-group append="kg">
                  <br />
                  <b-form-input
                    type="number"
                    v-model="weight"
                    placeholder="Weight"
                  ></b-form-input>
                </b-input-group>
              </div>

              <b-button @click="saveWeight()" variant="outline-success">Save</b-button>

            </b-modal>

            <b-modal
              id="viewDailyWellnessReport"
              size="lg"
              :hide-footer="Boolean(true)"
            >
              <h2>How do you feel today?</h2>
              <b-form-group
                class="colour-radios"
                label="Sleep"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="wellnessQuestions.sleep"
                  :aria-describedby="ariaDescribedby"
                  :options="zeroToTen"
                  name="sleep-radios"
                  buttons
                >
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Bad</span>
                  <span>Amazing</span>
                </div>
              </b-form-group>

              <b-form-group
                class="colour-radios"
                label="Happyness"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="wellnessQuestions.happyness"
                  :aria-describedby="ariaDescribedby"
                  :options="zeroToTen"
                  name="Happyness-radios"
                  buttons
                >
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Very sad</span>
                  <span>Very happy</span>
                </div>
              </b-form-group>

              <b-form-group
                class="colour-radios"
                label="Stress"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="wellnessQuestions.stress"
                  :aria-describedby="ariaDescribedby"
                  :options="zeroToTen"
                  name="Stress-radios"
                  buttons
                >
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Really stressed</span>
                  <span>None </span>
                </div>
              </b-form-group>

              <b-form-group
                class="colour-radios"
                label="Fatigue"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="wellnessQuestions.fatigue"
                  :aria-describedby="ariaDescribedby"
                  :options="zeroToTen"
                  name="Fatigue-radios"
                  buttons
                >
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Exhausted</span>
                  <span>Ready to go</span>
                </div>
              </b-form-group>

              <b-form-group
                class="colour-radios"
                label="Muscle Soreness"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="wellnessQuestions.muscleSoreness"
                  :aria-describedby="ariaDescribedby"
                  :options="zeroToTen"
                  name="Muscle-radios"
                  buttons
                >
                </b-form-radio-group>
                <div class="tyes-of-stuff">
                  <span>Very sore</span>
                  <span>None</span>
                </div>
              </b-form-group>

              <div class="player-game-responce">
                <b-button @click="saveHowYouFeel()" variant="outline-success"
                  >Save</b-button
                >
              </div>
            </b-modal>

            <b-modal
              id="performanceModal"
              size="lg"
              :hide-footer="Boolean(true)"
            >
              <h2>Record your game performance</h2>

              <form
                class="form"
                @submit.prevent="saveUserGamePerformanceReport()"
              >
                <div class="form__item">
                  <label class="form__label" for="coachSelect"
                    >Select game</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newGamePerformanceData.gameId"
                  >
                    <option disabled value="">Please select a game</option>
                    <option
                      v-for="game in pastGameData"
                      :value="game.id"
                      :key="game.id"
                    >
                      {{ formatISOStringDateAndTime(game.kickOffTime) }} |
                      {{ game.opposition }}
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >What position did you play?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newGamePerformanceData.postion_played"
                  >
                    <option disabled value="">Please select a position</option>
                    <option value="Goalkeeper">Goalkeeper</option>
                    <option value="Right Full-back (Wingback)">
                      Right Full-back (Wingback)
                    </option>
                    <option value="Left Full-back (Wingback)">
                      Left Full-back (Wingback)
                    </option>
                    <option value="Center-back left">Center-back left</option>
                    <option value="Center-back right">Center-back right</option>
                    <option value="Defensive Midfielder">
                      Defensive Midfielder
                    </option>
                    <option value="Right Midfielder (Winger)">
                      Right Midfielder (Winger)
                    </option>
                    <option value="Left Midfielder (Winger)">
                      Left Midfielder (Winger)
                    </option>
                    <option value="Attacking Midfielder">
                      Attacking Midfielder
                    </option>
                    <option value="Center Forward (Striker) left">
                      Center Forward (Striker) left
                    </option>
                    <option value="Center Forward (Striker) right">
                      Center Forward (Striker) right
                    </option>
                    <option value="I played more than one position">
                      I played more than one position
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >How long did you play for?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newGamePerformanceData.timeOnPitch"
                  >
                    <option value="70">Full game</option>
                    <option value="60">Almost a Full game</option>
                    <option value="35">One half</option>
                    <option value="20">Less than one half</option>
                    <option value="10">Last 10mins</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >Did you get a card?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newGamePerformanceData.card"
                  >
                    <option value="no">No</option>
                    <option value="Yellow">Yellow</option>
                    <option value="two Yellows (red)">Two yellows (red)</option>
                    <option value="red">red</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >How did you think you played?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newGamePerformanceData.player_self_rating"
                  >
                    <option value="Amazing">Amazing</option>
                    <option value="Good">Good</option>
                    <option value="Ok">Ok</option>
                    <option value="Not so good">Not so good</option>
                    <option value="Could play better">Could play better</option>
                  </select>
                </div>

                <div
                  v-if="newGamePerformanceData.postion_played !== 'Goalkeeper'"
                  class="form__item"
                >
                  <label class="form__label" for="goals"
                    >How many Assists?</label
                  >
                  <input
                    class="form__input"
                    type="number"
                    id="assists"
                    v-model="newGamePerformanceData.assists"
                  />
                </div>

                <div
                  v-if="newGamePerformanceData.postion_played !== 'Goalkeeper'"
                  class="form__item"
                >
                  <label class="form__label" for="goals"
                    >How many goals did you score?</label
                  >
                  <input
                    class="form__input"
                    type="number"
                    id="goals"
                    v-model="newGamePerformanceData.goals"
                  />
                </div>
                <div v-else class="form__item">
                  <label class="form__label" for="goals"
                    >Did you have a clean sheet?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newGamePerformanceData.clean_sheet"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <b-button type="submit" size="lg" variant="outline-success"
                  ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
                  game performance report</b-button
                >
              </form>
            </b-modal>

            <b-modal
              id="trainingPerformanceModal"
              size="lg"
              :hide-footer="Boolean(true)"
            >
              <h2>Record your training report</h2>
              <form
                class="form"
                @submit.prevent="saveTraningPerformanceReport()"
              >
                <div class="form__item">
                  <label class="form__label" for="coachSelect"
                    >Select training session</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newTrainingPerformanceData.traningId"
                  >
                    <option disabled value="">Please select a game</option>
                    <option
                      v-for="training in pastTrainingSessionsDataFiltered"
                      :value="training.id"
                      :key="training.id"
                    >
                      {{ formatISOStringDateAndTime(training.kickOffTime) }} |
                      Traning
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >How hard was the session?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newTrainingPerformanceData.effort"
                  >
                    <option disabled value="">Please select an option</option>
                    <option value="10">10 Max effort</option>
                    <option value="9">9 VERY HARD</option>
                    <option value="78">7-8 VIGOROUS ACTIVITY</option>
                    <option value="46">4-6 MODERATE ACTIVITY</option>
                    <option value="23">2-3 LIGHT ACTIVITY</option>
                    <option value="1">1 VERY LIGHT ACTIVITY</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >Did you enjoy the session?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="newTrainingPerformanceData.enjoyment"
                  >
                    <option value="Amazing">Amazing</option>
                    <option value="Good">Good</option>
                    <option value="Ok">Ok</option>
                    <option value="Not so good">Not so good</option>
                    <option value="Did not enjoy it">Did not enjoy it</option>
                  </select>
                </div>

                <b-button
                  v-if="pastTrainingSessionsDataFiltered.length > 0"
                  type="submit"
                  size="lg"
                  variant="outline-success"
                  ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
                  training performance report</b-button
                >
                <p class="red-warning" v-else>
                  You need to attended at least one training session to complete
                  a training performance report
                </p>
              </form>
            </b-modal>

            <b-modal
              id="coachGamePerformanceModal"
              size="lg"
              :hide-footer="Boolean(true)"
              @hide="hideCoachPerformanceModel()"
            >
              <h2>Record players game report</h2>

              <form
                class="form"
                @submit.prevent="coachesGamePerformanceReport()"
              >
                <div class="form__item">
                  <label class="form__label" for="coachSelect"
                    >Select game</label
                  >
                  <select
                    id="coachSelect"
                    v-model="coachGamePerformanceData.gameId"
                  >
                    <option disabled value="">Please select a game</option>
                    <option
                      v-for="game in pastGameDataDataFiltered"
                      :value="game.id"
                      :key="game.id"
                    >
                      {{ formatISOStringDateAndTime(game.kickOffTime) }} |
                      {{ game.opposition }}
                    </option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals"
                    >How did you think you played?</label
                  >
                  <select
                    id="coachSelect"
                    v-model="coachGamePerformanceData.player_rating"
                  >
                    <option value="Amazing">Amazing</option>
                    <option value="Good">Good</option>
                    <option value="0k">Ok</option>
                    <option value="Could play better">Could play better</option>
                  </select>
                </div>

                <div class="form__item">
                  <label class="form__label" for="goals">Comments</label>
                  <textarea
                    class="form__input"
                    id="game_comments"
                    v-model="coachGamePerformanceData.game_comments"
                  ></textarea>
                </div>

                <b-button
                  v-if="pastGameData.length > 0"
                  type="submit"
                  size="lg"
                  variant="outline-success"
                  ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
                  player performance report</b-button
                >
                <p class="red-warning" v-else>
                  You need to play at least one game to complete a players game
                  report
                </p>
              </form>
            </b-modal>
          </div>
        </template>
        <template v-else>
          <section class="player-profile-wrapper">
            <ul class="tabs headding-space">
              <li class="tab-item" v-if="userData.userType === 'Admin'">
                <a
                  :class="
                    selectedDashboardTab === 'player-over-veiw-tab'
                      ? 'tab-link active'
                      : 'tab-link'
                  "
                  @click="
                    tabChange('selectedDashboardTab', 'player-over-veiw-tab')
                  "
                  href="javascript:;"
                  >Players</a
                >
              </li>
              <li class="tab-item" v-if="userData.userType === 'Admin'">
                <a
                  :class="
                    selectedDashboardTab === 'players-reports-tab'
                      ? 'tab-link active'
                      : 'tab-link'
                  "
                  @click="
                    tabChange('selectedDashboardTab', 'players-reports-tab')
                  "
                  href="javascript:;"
                  >Stats / reports</a
                >
              </li>
            </ul>

            <div class="tabs-wrapper">
              <div
                class="player-over-veiw-tab tab-content"
                v-if="selectedDashboardTab === 'player-over-veiw-tab'"
              >
                <div class="number-of-results">
                  <p v-if="filterdCoachesUsersData.length > 0">
                    Number of players: {{ filterdCoachesUsersData.length }}
                  </p>
                  <p v-else>Number of players: {{ coachesUsersData.length }}</p>
                </div>
                <div class="button-wrapper-top filters-sort">
                  <b-input-group
                    size="sm"
                    class="mb-2 autocomplete search-small"
                  >
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      type="search"
                      placeholder="Search players"
                      @input="lookUpSearch($event)"
                    ></b-form-input>
                    <ul
                      class="autocomplete-results"
                      v-if="filterdCoachesUsersData.length > 0"
                    >
                      <li
                        @click="setSelectedUserOfTrainingPlan(userData)"
                        v-for="userData in filterdCoachesUsersData"
                        :key="userData.id"
                        class="autocomplete-result"
                      >
                        {{ userData.name }}
                      </li>
                    </ul>
                  </b-input-group>

                  <b-button
                    @click="toggleFilters = !toggleFilters"
                    variant="outline-success filter-small"
                  >
                    <b-icon icon="filter" aria-hidden="true"></b-icon>
                    Filters</b-button
                  >

                  <div class="filters" v-if="toggleFilters === true">
                    <b-form-select
                      class="mb-3"
                      @change="
                        filterBy('allUserDataBeforFilterApplied', 'teamName')
                      "
                      v-model="selectedFilterData"
                    >
                      <b-form-select-option :value="null" disabled
                        >Select a team</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="team in clubTeams"
                        :key="team.id"
                        :value="team.teamName"
                        >{{ team.teamName }}</b-form-select-option
                      >
                    </b-form-select>
                    <a
                      @click="removeFilter()"
                      v-if="hideRemoveTeamFilterButton === false"
                      href="javascript:;"
                      class="remove-icon-button"
                      ><b-icon
                        icon="x"
                        font-scale="2"
                        aria-hidden="true"
                      ></b-icon
                    ></a>
                  </div>
                </div>

                <template v-if="coachesUsersData.length > 0">
                  <div
                    v-for="player in coachesUsersData"
                    :value="player.id"
                    :key="player.id"
                    :class="
                      player.injury === true
                        ? 'player-profile-details injury'
                        : 'player-profile-details'
                    "
                    @click="selectedUseChanged(player)"
                  >
                    <div class="user-photo-info">
                      <img
                        v-if="
                          player.userImage !== '' && player.userImage !== null
                        "
                        :src="getImgUrl(player.userImage)"
                        alt=""
                      />
                      <img
                        v-else
                        :src="getImgUrl('default-image.jpg')"
                        alt=""
                      />
                    </div>
                    <div class="user-info">
                      <h2>{{ player.name }}</h2>
                      <p>{{ player.teamName }}</p>
                      <p class="red-warning" v-if="player.injury === true">
                        <b-icon icon="exclamation-circle"></b-icon> Player is
                        injured
                      </p>
                      <p>Age: {{ player.age_group }}</p>
                      <p>Position: {{ player.position }}</p>
                      <p v-if="checkSignedDate(player.joined_date) === true">
                        Signed: {{ formatDate(player.joined_date) }}
                      </p>
                      <p class="red-warning" v-else>Signed: Missing date</p>
                      <p>
                        <img src="./img/sfa.png" /> SFA registered:
                        {{ converBoolean(player.sfa_registered) }}
                      </p>
                    </div>
                  </div>
                </template>
                <div class="empty-state" v-else>
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>No player information</p>
                </div>
              </div>

              <div
                class="players-reports-tab tab-content"
                v-if="selectedDashboardTab === 'players-reports-tab'"
              >
                <div class="tab-content">
                  <div class="players-without-subscriptions">
                    <h2>Players without subscriptions</h2>
                    <vue-good-table
                      style-class="vgt-table bordered"
                      :columns="columnsPlayers"
                      :rows="playersWithoutLiveSubscriptions"
                      :search-options="{
                        enabled: true,
                        placeholder: 'Search players',
                      }"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'payment_state'">
                          <span class="unpaid"
                            >No subscription information</span
                          >
                        </span>
                        <span v-else>
                          <span>{{
                            props.formattedRow[props.column.field]
                          }}</span>
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>

        <b-modal
          id="editPlayerInjuryModal"
          size="lg"
          :hide-footer="Boolean(true)"
          @hide="hidePlayerInjuryReportModel()"
        >
          <h2>Edit Player injury report</h2>

          <form class="form" @submit.prevent="updatePlayerInjuryReport()">
            <div class="form__item">
              <label class="form__label" for="injury_type">Injury type</label>

              <select
                id="injury_type"
                v-model="newInjuryInformation.injury_type"
              >
                <option value="Left ankle injury">Left ankle injury</option>
                <option value="Right ankle injury">Right ankle injury</option>
                <option value="Left Knee injury">Left Knee injury</option>
                <option value="Right Knee injury">Right Knee injury</option>
                <option value="Left Quad injury">Left Quad injury</option>
                <option value="Right Quad injury">Right Quad injury</option>
                <option value="Left hamstring injury">
                  Left hamstring injury
                </option>
                <option value="Right hamstring injury">
                  Right hamstring injury
                </option>
                <option value="Left groin injury">Left groin injury</option>
                <option value="Right groin injury">Right groin injury</option>
                <option value="Right Hip injury">Right Hip injury</option>
                <option value="Left Hip injury">Left Hip injury</option>
                <option value="Right Shoulder injury">
                  Right Shoulder injury
                </option>
                <option value="Left Shoulder injury">
                  Left Shoulder injury
                </option>
                <option value="Right Wrist and hand injury">
                  Right Wrist and hand injury
                </option>
                <option value="Left Wrist and hand injury">
                  Left Wrist and hand injury
                </option>
                <option value="Head injury">Head injury</option>
                <option value="Left foot injury">Left foot injury</option>
                <option value="Right foot injury">Right foot injury</option>
                <option value="Right arm injury">Right arm injury</option>
                <option value="Left arm injury">Left arm injury</option>
                <option value="Left calf injury">Left calf injury</option>
                <option value="Right calf injury">Right calf injury</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="more_detail">More detail</label>
              <textarea
                class="form__input"
                id="more_detail"
                v-model="newInjuryInformation.more_detail"
              ></textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="return_time">Return time</label>
              <select
                id="return_time"
                v-model="newInjuryInformation.return_time"
              >
                <option value="1 - 2 days">1 - 2 days</option>
                <option value="3 - 5 days">3 - 5 days</option>
                <option value="1 week">1 week</option>
                <option value="2 - 3 weeks">2 - 3 weeks</option>
                <option value="A month">A month</option>
                <option value="A few months">A few months</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="injury_status"
                >Injury Status</label
              >
              <select
                id="injury_status"
                v-model="newInjuryInformation.injury_status"
              >
                <option value="Reciving treatment">Reciving treatment</option>
                <option value="Resting">Resting</option>
                <option value="Light training">Light training</option>
                <option value="Fully recoverd">Fully recoverd</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact"
                >Contact information</label
              >
              <input
                class="form__input"
                type="text"
                id="contact"
                v-model="newInjuryInformation.contact"
              />
            </div>

            <b-button type="submit" size="lg" variant="outline-success"
              ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
              player injury report</b-button
            >
          </form>
        </b-modal>

        <b-modal
          id="playerInjuryModal"
          size="lg"
          :hide-footer="Boolean(true)"
          @hide="hidePlayerInjuryReportModel()"
        >
          <h2>Record Player injury report</h2>

          <form class="form" @submit.prevent="savePlayerInjuryReport()">
            <div class="form__item">
              <label class="form__label" for="injury_type">Injury type</label>

              <select
                id="injury_type"
                v-model="newInjuryInformation.injury_type"
              >
                <option value="Left ankle injury">Left ankle injury</option>
                <option value="Right ankle injury">Right ankle injury</option>
                <option value="Left Knee injury">Left Knee injury</option>
                <option value="Right Knee injury">Right Knee injury</option>
                <option value="Left Quad injury">Left Quad injury</option>
                <option value="Right Quad injury">Right Quad injury</option>
                <option value="Left hamstring injury">
                  Left hamstring injury
                </option>
                <option value="Right hamstring injury">
                  Right hamstring injury
                </option>
                <option value="Left groin injury">Left groin injury</option>
                <option value="Right groin injury">Right groin injury</option>
                <option value="Right Hip injury">Right Hip injury</option>
                <option value="Left Hip injury">Left Hip injury</option>
                <option value="Right Shoulder injury">
                  Right Shoulder injury
                </option>
                <option value="Left Shoulder injury">
                  Left Shoulder injury
                </option>
                <option value="Right Wrist and hand injury">
                  Right Wrist and hand injury
                </option>
                <option value="Left Wrist and hand injury">
                  Left Wrist and hand injury
                </option>
                <option value="Head injury">Head injury</option>
                <option value="Left foot injury">Left foot injury</option>
                <option value="Right foot injury">Right foot injury</option>
                <option value="Right arm injury">Right arm injury</option>
                <option value="Left arm injury">Left arm injury</option>
                <option value="Left calf injury">Left calf injury</option>
                <option value="Right calf injury">Right calf injury</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="more_detail">More detail</label>
              <textarea
                class="form__input"
                id="more_detail"
                v-model="newInjuryInformation.more_detail"
              ></textarea>
            </div>

            <div class="form__item">
              <label class="form__label" for="return_time">Return time</label>
              <select
                id="return_time"
                v-model="newInjuryInformation.return_time"
              >
                <option value="1 - 2 days">1 - 2 days</option>
                <option value="3 - 5 days">3 - 5 days</option>
                <option value="1 week">1 week</option>
                <option value="2 - 3 weeks">2 - 3 weeks</option>
                <option value="A month">A month</option>
                <option value="A few months">A few months</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="injury_status"
                >Injury Status</label
              >
              <select
                id="injury_status"
                v-model="newInjuryInformation.injury_status"
              >
                <option value="Reciving treatment">Reciving treatment</option>
                <option value="Resting">Resting</option>
                <option value="Light training">Light training</option>
                <option value="Fully recoverd">Fully recoverd</option>
              </select>
            </div>

            <div class="form__item">
              <label class="form__label" for="contact"
                >Contact information</label
              >
              <input
                class="form__input"
                type="text"
                id="contact"
                v-model="newInjuryInformation.contact"
              />
            </div>

            <b-button type="submit" size="lg" variant="outline-success"
              ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
              player injury report</b-button
            >
          </form>
        </b-modal>

        <b-modal
          id="addNewPhysicalPerformanceTimeModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <h2>Physical Performance</h2>

          <form class="form" @submit.prevent="addPhysicalPerformanceValue()">
            <div class="form__item">
              <label class="form__label" for="contact">10m SPRINT:</label>
              <input
                class="form__input"
                type="number"
                step=".01"
                id="contact"
                v-model="playerPhysicalPerformanceData.tenM_sprint_time"
              />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact">40m SPRINT:</label>
              <input
                class="form__input"
                type="number"
                step=".01"
                id="contact"
                v-model="playerPhysicalPerformanceData.fortyM_sprint_time"
              />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact"
                >Countermovement jump:</label
              >
              <input
                class="form__input"
                type="number"
                step=".01"
                id="contact"
                v-model="playerPhysicalPerformanceData.cuntermovement_jump"
              />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact"
                >Reactive strength index:</label
              >
              <input
                class="form__input"
                type="number"
                step=".01"
                id="contact"
                v-model="playerPhysicalPerformanceData.reactive_strength_index"
              />
            </div>

            <div class="form__item">
              <label class="form__label" for="contact"
                >30-15 Intermittent Fitness Test:</label
              >
              <input
                class="form__input"
                type="number"
                step=".01"
                id="contact"
                v-model="
                  playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test
                "
              />
            </div>

            <b-button type="submit" size="lg" variant="outline-success">
              Add entry</b-button
            >
          </form>
        </b-modal>

        <b-modal id="missingDataModal" size="lg" :hide-footer="Boolean(true)">
          <h2>Missing data</h2>
          <p>Looks like you need to give us some more data.</p>

          <p v-if="userData.joined_date === null">The date you joined.</p>
          <p v-if="userData.userImage === null">Your photo.</p>

          <p>
            Please update your profile -
            <router-link :to="{ name: 'Settings' }">Settings</router-link>
          </p>
        </b-modal>
        <b-modal
          id="playerPerformanceReportModal"
          size="lg"
          :hide-footer="Boolean(true)"
          title="Perfomance Report"
        >
          <form class="form" @submit.prevent="savePlayerPerformanceReview()">
            <div class="two-col-pop">
              <div class="user-photo-info">
                <img
                  v-if="
                    selectedUserData.userImage !== '' &&
                    selectedUserData.userImage !== null
                  "
                  :src="getImgUrl(selectedUserData.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-details-pop">
                <p><span>Name:</span> {{ selectedUserData.name }}</p>
                <p><span>Phone:</span> {{ selectedUserData.phone }}</p>
                <p><span>Email:</span> {{ selectedUserData.email }}</p>
                <p>
                  <span>DOB:</span>
                  {{ formatDateForHuman(selectedUserData.dob) }}
                </p>
                <p><span>Position:</span> {{ selectedUserData.position }}</p>
                <p>
                  <span>Preferred foot:</span>
                  {{ selectedUserData.preferred_foot }}
                </p>
              </div>
            </div>

            <h4>
              Performance review -
              {{ formatDateForHuman(playerPerformanceReportData.review_date) }}
            </h4>

            <div class="form__item">
              <label class="form__label" for="attitude">Attitude</label>
              <div class="ratingStars">
                <star-rating
                  v-model="playerPerformanceReportData.attitude"
                ></star-rating>

                <wysiwyg
                  v-model="playerPerformanceReportData.attitude_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="skill">Skill</label>
              <div class="ratingStars">
                <star-rating
                  v-model="playerPerformanceReportData.skill"
                ></star-rating>
                <wysiwyg v-model="playerPerformanceReportData.skill_comments" />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Physique</label>
              <div class="ratingStars">
                <star-rating
                  v-model="playerPerformanceReportData.physique"
                ></star-rating>
                <wysiwyg
                  v-model="playerPerformanceReportData.physique_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Mentality</label>
              <div class="ratingStars">
                <star-rating
                  v-model="playerPerformanceReportData.mentality"
                ></star-rating>
                <wysiwyg
                  v-model="playerPerformanceReportData.mentality_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Leadership</label>
              <div class="ratingStars">
                <star-rating
                  v-model="playerPerformanceReportData.leadership"
                ></star-rating>
                <wysiwyg
                  v-model="playerPerformanceReportData.leadership_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Professionalism</label>
              <div class="ratingStars">
                <star-rating
                  v-model="playerPerformanceReportData.professionalism"
                ></star-rating>
                <wysiwyg
                  v-model="playerPerformanceReportData.professionalism_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="comments">Coachs notes </label>
              <wysiwyg v-model="playerPerformanceReportData.coachsNotes" />
            </div>

            <div class="perfomance-targets">
              <label class="form__label" for="perfomance-targets"
                >Add Performance target

                <b-button
                  v-b-tooltip.hover
                  title="Add Performance Targe"
                  @click="addItem()"
                  variant="success"
                >
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                </b-button>
              </label>

              <div
                class="each-target"
                v-for="(item, index) in playerPerformanceTargets"
                :key="index"
              >
                <label class="form__label" for="perfomance-targets"
                  >Performance target
                </label>

                <wysiwyg v-model="item.target" />

                <b-button
                  v-b-tooltip.hover
                  title="remove Performance Target"
                  variant="danger"
                >
                  <b-icon
                    icon="x"
                    aria-hidden="true"
                    @click="removeItem(index)"
                  ></b-icon
                ></b-button>
              </div>
            </div>

            <div class="player-game-responce">
              <b-button type="submit" variant="success">Save Report</b-button>
            </div>
          </form>
        </b-modal>

        <b-modal
          id="playerPerformanceReportVewDetailsModal"
          size="lg"
          :hide-footer="Boolean(true)"
          title="Perfomance Report"
        >
          <form class="form">
            <UserImageAndInfo
              v-if="Object.keys(selectedUserData).length !== 0"
              :selectedUserDataForUserImageAndInfo="selectedUserData"
              :playerPerformanceReportData="playerPerformanceReportData"
            />
            <UserImageAndInfo
              v-else
              :selectedUserDataForUserImageAndInfo="userData"
              :playerPerformanceReportData="playerPerformanceReportData"
            />

            <h4>
              Performance review -
              {{ formatDateForHuman(selectedUserData.review_date) }}
            </h4>

            <div class="form__item">
              <label class="form__label" for="attitude">Attitude</label>
              <div class="ratingStars">
                <star-rating
                  read-only
                  v-model="playerPerformanceReportData.attitude"
                ></star-rating>

                <div
                  class="comments-box"
                  v-html="playerPerformanceReportData.attitude_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="skill">Skill</label>
              <div class="ratingStars">
                <star-rating
                  read-only
                  v-model="playerPerformanceReportData.skill"
                ></star-rating>
                <div
                  class="comments-box"
                  v-html="playerPerformanceReportData.skill_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Physique</label>
              <div class="ratingStars">
                <star-rating
                  read-only
                  v-model="playerPerformanceReportData.physique"
                ></star-rating>
                <div
                  class="comments-box"
                  v-html="playerPerformanceReportData.physique_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Mentality</label>
              <div class="ratingStars">
                <star-rating
                  read-only
                  v-model="playerPerformanceReportData.mentality"
                ></star-rating>
                <div
                  class="comments-box"
                  v-html="playerPerformanceReportData.mentality_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Leadership</label>
              <div class="ratingStars">
                <star-rating
                  read-only
                  v-model="playerPerformanceReportData.leadership"
                ></star-rating>
                <div
                  class="comments-box"
                  v-html="playerPerformanceReportData.leadership_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="physique">Professionalism</label>
              <div class="ratingStars">
                <star-rating
                  read-only
                  v-model="playerPerformanceReportData.professionalism"
                ></star-rating>
                <div
                  class="comments-box"
                  v-html="playerPerformanceReportData.professionalism_comments"
                />
              </div>
            </div>

            <div class="form__item">
              <label class="form__label" for="comments">Coachs notes </label>
              <div
                class="comments-box"
                v-html="playerPerformanceReportData.coachsNotes"
              />
            </div>

            <div class="form__item">
              <label class="form__label" for="comments"
                >performance targets
              </label>

              
              <div
                class="comments-box"
                v-for="target in getSelectedPerformanceReportTargets(
                  playerPerformanceReportData.id
                )"
                :key="target.id"
              >
              <div v-html="target.target"></div>
            </div>
            </div>
          </form>
        </b-modal>
        <b-modal
          id="allTrainingReportsModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <div class="all-training-reports">
            <h3>Seasions training reports</h3>

            <table class="w-a-style">
              <thead>
                <th>Date</th>
                <th>effort</th>
                <th>enjoyment</th>
              </thead>
              <tbody>
                <tr
                  v-for="session in seasonsTraningPerformanceReportsForUser"
                  :key="session.id"
                >
                  <td>{{ formatDateForHuman(session.created_date) }}</td>
                  <td>{{ session.effort }}</td>
                  <td>{{ session.enjoyment }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Bar-Chart :chartData="trainingPerformanceBarChartDataSet" />
        </b-modal>


        <b-modal
          id="performanceTargetsHistoryModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <table class="w-a-style">
            <thead>
              <th>Date</th>
              <th>Target</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr
                v-for="target in playerPerformanceReviewTargetsData"
                :key="target.id"
              >
                <td>{{ formatDateForHuman(target.created_date) }}</td>
                <td><div v-html="target.target"></div></td>
                <td v-if="target.complete === 1" class="completed">Completed</td>
                <td v-else>Not complete</td>
                
              </tr>
            </tbody>
          </table>
        </b-modal>

        <b-modal
          id="physicalPerformanceHistoryModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <table class="w-a-style">
            <thead>
              <th>Date</th>
              <th>10m SPRINT</th>
              <th>40m SPRINT</th>
              <th>Countermovement jump</th>
              <th>Reactive strength index</th>
              <th>30-15 Intermittent Fitness Test</th>
            </thead>
            <tbody>
              <tr
                v-for="session in allPlayerPhysicalPerformanceData"
                :key="session.id"
              >
                <td>{{ formatDateForHuman(session.date) }}</td>
                <td>{{ session.tenM_sprint_time }}</td>
                <td>{{ session.fortyM_sprint_time }}</td>
                <td>{{ session.cuntermovement_jump }}</td>
                <td>{{ session.reactive_strength_index }}</td>
                <td>{{ session.thirty_15_intermittent_fitness_test }}</td>
              </tr>
            </tbody>
          </table>
        </b-modal>

        <b-modal
          id="allGameReportsModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <div class="all-training-reports">
            <h3>Seasions Game reports</h3>

            <table class="w-a-style">
              <thead>
                <th>Date</th>
                <th>Postion played</th>
                <th>time on pitch</th>
                <th>Player self rating</th>
                <th>Goals</th>
                <th>Assists</th>
                <th>Clean sheet</th>
                <th>Card</th>
              </thead>
              <tbody>
                <tr
                  v-for="session in seasonsgamePerformanceReportsForUser"
                  :key="session.id"
                >
                  <td>{{ formatDateForHuman(session.created_date) }}</td>
                  <td>{{ session.postion_played }}</td>
                  <td>{{ session.timeOnPitch }}</td>
                  <td>{{ session.player_self_rating }}</td>
                  <td>{{ session.goals }}</td>
                  <td>{{ session.assists }}</td>
                  <td v-if="session.postion_played === 'Goalkeeper'">
                    {{ session.clean_sheet }}
                  </td>
                  <td v-else>0</td>
                  <td>{{ session.card }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-modal>

        <b-modal
          id="newPerformanceTargetsModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <div class="performance-target-modal-content">
            <h3>Performance target</h3>
            <div class="form__item">
                <label class="form__label" for="select_performace-reports"
                  >Select performance review</label
                >
              <select
                id="select_performace-reports"
                v-model="selectedPerformanceTarget.performance_report_id">
                  <option v-for="review in allplayerPerformanceReportData"
                        :key="review.id"
                  :value="review.id">
                  {{ formatDateForHuman(review.review_date) }}
                </option>
                </select>
                <p>This will add a performance target to the review selected.</p>
              </div>

            <wysiwyg v-model="selectedPerformanceTarget.target" />
            <b-form-checkbox
              id="checkbox-1"
              size="lg"
              v-model="selectedPerformanceTarget.complete"
              name="checkbox-1"
              >Mark target as complete
            </b-form-checkbox>

            <div class="buttons-row">
              <b-button @click="addNewPerformanceReportTarget()" size="lg" variant="outline-success"> Save</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal
          id="performanceTargetsModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <div class="performance-target-modal-content">
            <h3>Performance target</h3>
            <p class="small-date-player-coach">
              {{ formatDateForHuman(selectedPerformanceTarget.created_date) }}
            </p>

            <wysiwyg v-model="selectedPerformanceTarget.target" />
            <b-form-checkbox
              id="checkbox-1"
              size="lg"
              v-model="selectedPerformanceTarget.complete"
              name="checkbox-1"
              >Mark target as complete
            </b-form-checkbox>

            <div class="buttons-row">
              <b-button @click="updatePerformanceReportTarget()" size="lg" variant="outline-success"> Update</b-button>

              <b-button @click="deletePerformanceReportTarget()" size="lg" variant="outline-danger">Delete</b-button>
            </div>
          </div>
        </b-modal>

        <b-modal id="academyReportModal" size="lg" :hide-footer="Boolean(true)">
          <div class="performance-target-modal-content">
            <h3>Academy report</h3>

            <form class="form">
              <div class="form__item">
                <label class="form__label" for="comments"
                  >Selected Player
                </label>
                <p></p>
              </div>

              <div class="form__item">
                <label class="form__label" for="select_season"
                  >Select season</label
                >
                <select id="select_season">
                  <option value="2022-23">2022-23</option>
                  <option value="2022-23">2019-20</option>
                  <option value="2022-23">2021-22</option>
                </select>
              </div>
            </form>

            <div class="buttons-row">
              <b-button
                size="lg"
                variant="outline-success"
                @click="produceTheAcademyReport()"
              >
                Produce report</b-button
              >
            </div>

            <div class="modal-container performance-report">
              <iframe
                v-if="showReport === true"
                id="theAcademyReportPdf_preview"
                src=""
              />
              <img v-else src="./img/football-illustration.jpg" alt="" />
            </div>
          </div>
        </b-modal>

        <b-modal id="viewPlayersCV" size="lg" :hide-footer="Boolean(true)">
          <div class="player-cv-content">
            <div class="cv-header">
              <div class="user-photo-info">
                <img
                  src="/images/ollie-p642d7d5e1a2ba.png"
                  alt=""
                  id="user_photo"
                />
                <h1>Ollie John Paxton</h1>
              </div>

              <div class="cv-address">
                <ul>
                  <li>ollie@stewartpaxton.co.uk</li>
                  <li>07979496719</li>
                  <li>
                    59 Cornfoot Crescent, <br />St Leonards, <br />East Kilbride
                    G74 3zb
                  </li>
                </ul>
              </div>
            </div>

            <div class="cv-body">
              <div class="Personal-profile">
                <h3>Personal profile</h3>
                <p>
                  Dedicated, physically fit, and creative footballer committed
                  to helping the team reach its season’s objectives, winning
                  trophies, and upholding the team’s historical legacy.
                  Demonstrate commitment to rigorous training regimes, resulting
                  in a career-high of 13 goals last season. Leverage soft skills
                  like empathy and active listening in coaching young academy
                  team substitutes and fringe players aiming for a permanent
                  place in their team’s first eleven.
                </p>
              </div>

              <div class="cv-two-col">
                <div class="col-one">
                  <div class="playing-exp">
                    <h3>PLAYING EXPERIENCE</h3>

                    <table class="w-a-style">
                      <thead>
                        <tr>
                          <th>Club</th>
                          <th>Time at the club</th>
                          <th>Playing stats</th>
                          <th>More information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>East Kilbride Burgh</td>
                          <td>2017-2019</td>
                          <td>Format: 4 v 4 and 5 v 5.</td>
                          <td>
                            <a
                              href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                              >Facebook</a
                            ><br />
                            <a href="https://twitter.com/burghek2010?lang=en-GB"
                              >Twitter</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>Hunter primary school team</td>
                          <td>2019-2021</td>
                          <td>Format: 7 v 7. Reginal cup winners 2021</td>
                          <td>
                            <a
                              href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                              >Facebook</a
                            ><br />
                            <a href="https://twitter.com/burghek2010?lang=en-GB"
                              >Twitter</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>East Kilbride FC</td>
                          <td>2019-2021</td>
                          <td>Format: 7 v 7 and 9 v 9</td>
                          <td>
                            <a
                              href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                              >Facebook</a
                            ><br />
                            <a href="https://twitter.com/burghek2010?lang=en-GB"
                              >Twitter</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>Calderglen high school team</td>
                          <td>2022-</td>
                          <td>Format: 11 v 11.</td>
                          <td>
                            <a
                              href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                              >Facebook</a
                            ><br />
                            <a href="https://twitter.com/burghek2010?lang=en-GB"
                              >Twitter</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>Blantyre soccer academy</td>
                          <td>Cup compition</td>
                          <td>
                            Format: 11 v 11. Played in 5 games for Blantyre
                            soccer academy, scored 3 goals helping the team get
                            to the knockout stages of the compition.
                          </td>
                          <td>
                            <a
                              href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                              >Facebook</a
                            ><br />
                            <a href="https://twitter.com/burghek2010?lang=en-GB"
                              >Twitter</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>W Academy</td>
                          <td>2022-</td>
                          <td>Format: 7 v 7 and 11 v 11</td>
                          <td>
                            <a
                              href="https://www.facebook.com/people/EK-Burgh-2010/100058714732781/"
                              >Facebook</a
                            ><br />
                            <a href="https://twitter.com/burghek2010?lang=en-GB"
                              >Twitter</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="education">
                    <h3>EDUCATION</h3>

                    <ul>
                      <li>
                        <span class="title"
                          >University of Bath, Claverton Down, Bath
                          (2015-2018)</span
                        >
                        <span class="info"
                          >Bachelor of Science (Hons.) in Sports Science, Upper
                          second-class honours (2:1)</span
                        >

                        <span class="info"
                          >Relevant Modules: Sports Management, Marketing and
                          Media, Nutrition and Metabolism, Physiology of
                          Exercise, Health and Nutrition, Applied Sport
                          Biomechanics Cats College, Canterbury, Kent</span
                        >
                      </li>

                      <li>
                        <span class="title"
                          >A-levels: Maths (A), French (A), English Literature
                          (A)</span
                        >
                        <span class="info"
                          >GCSEs: 10 A-C including Maths, Biology, and Physical
                          and Health Education</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-two">
                  <div class="hobbies">
                    <h3>CAREER STATISTICS</h3>

                    <ul>
                      <li>Goals: 31</li>
                      <li>Shots: 104</li>
                      <li>Assists: 43</li>
                      <li>Pass completion: 84.6%</li>
                    </ul>
                  </div>

                  <div class="hobbies">
                    <h3>Interest hobbies outside of football</h3>
                    <table class="w-a-style">
                      <thead>
                        <tr>
                          <th>Interest hobbies</th>
                          <th>More information</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Drawing, sketching and painting.</td>
                          <td>
                            Ollie loves to draw, spending some of his spare time
                            being creative.
                          </td>
                        </tr>

                        <tr>
                          <td>Running / Athletics</td>
                          <td>
                            Ollie has been intrested in running and athletics
                            since he was very small. He has one many regional
                            awards within athletics.
                          </td>
                        </tr>

                        <tr>
                          <td>Computer games</td>
                          <td>
                            Ollie loves to play computer games, Fifa and Call of
                            Duty are his favourite games at the moment.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="players-videos">
                  <h3>Videos</h3>
                  <div class="sessions-info">
                    <a
                      target="_blank"
                      href="https://football-personal-training.uk/videos/ollie-second-goal2019-07-20%20at%201.33.45%20PM.mp4"
                      class="session-card"
                    >
                      <img src="./img/video.jpg" alt="" />
                      <p class="small-date">Video</p>
                      <h3>Ollie | Goals EKB 1</h3>
                    </a>

                    <a
                      target="_blank"
                      href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                      class="session-card"
                    >
                      <img src="./img/video.jpg" alt="" />
                      <p class="small-date">Video</p>
                      <h3>Ollie | Goals EKB 2</h3>
                    </a>

                    <a
                      target="_blank"
                      href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                      class="session-card"
                    >
                      <img src="./img/video.jpg" alt="" />
                      <p class="small-date">Video</p>
                      <h3>Ollie | Pentaly EKB 3</h3>
                    </a>

                    <a
                      target="_blank"
                      href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                      class="session-card"
                    >
                      <img src="./img/video.jpg" alt="" />
                      <p class="small-date">Video</p>
                      <h3>Ollie | Goals EKFC 1</h3>
                    </a>

                    <a
                      target="_blank"
                      href="https://football-personal-training.uk/videos/Ollie-first-goal2019-07-20%20at%201.26.41%20PM.mp4"
                      class="session-card"
                    >
                      <img src="./img/video.jpg" alt="" />
                      <p class="small-date">Video</p>
                      <h3>Ollie | Goals EKFC 2</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

        <img
          src="./img/pitch-potions.jpg"
          alt=""
          class="visually-hidden"
          id="pitch_potions"
        />
        <img
          src="./img/avrage-scores.jpg"
          alt=""
          class="visually-hidden"
          id="average_scores"
        />
        <img
          src="./img/video-link-image.jpg"
          alt=""
          class="visually-hidden"
          id="video_link_image"
        />
        <img
          src="./img/mood-example.jpg"
          alt=""
          class="visually-hidden"
          id="mood-example_image"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Wellness } from "@/services/wellness-service.js";
import { PlayerInjury } from "@/services/player-Injury-service.js";
import { Games } from "@/services/games-service.js";
import { PerformanceReview } from "@/services/performance-review-service.js";
import { Teams } from "@/services/teams-service.js";
import { Payments } from "@/services/stripe-service.js";
import { PlayerPhysicalPerformance } from "@/services/player-physical-performance-service.js";
import AppTemplate from "../layouts/App";
import JsPDF from "jspdf";
import SpinnerLoader from "../../components/LoadingSpinning";
import CircularPercentage from "../../components/CircularPercentage";
import UserImageAndInfo from "../../components/UserImageAndInfo";
import UserHeader from "../../components/UserHeader";
import TrainingPlansLinks from "../training-plans/training-plans-links";
import StarRating from "vue-star-rating";
import moment, { months } from "moment";
import VModal from "vue-js-modal";
import vueSignature from "vue-signature";
import BarChart from "../../components/BarChart";
import DailyPlanner from "../../components/DailyPlanner";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";

Vue.use(VModal);

export default {
  name: "profile",
  mixins: [extendSeasonEndDateToFullYearMixin],
  components: {
    AppTemplate,
    SpinnerLoader,
    UserHeader,
    TrainingPlansLinks,
    StarRating,
    CircularPercentage,
    UserImageAndInfo,
    BarChart,
    vueSignature,
    DailyPlanner,
  },
  data() {
    return {
      weight: 0,
      height: 0,
      pastGameDataDataFiltered: [],
      playersWithoutLiveSubscriptions: [],
      allBookingData: [],
      playersPayment: [],
      clubTeams: [],
      selectedFilterData: null,
      toggleFilters: false,
      hideRemoveTeamFilterButton: true,
      filterdCoachesUsersData: [],
      allUserDataBeforFilterApplied: [],
      selectedDashboardTab: "player-over-veiw-tab",
      selectedTeamId: 0,
      signatureOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      allplayerPerformanceReportData: [],
      playerPerformanceReportData: {
        review_date: moment().format("YYYY-MM-DD"),
      },
      playerInjuryData: [],
      newInjuryInformation: {
        injury_type: null,
        more_detail: null,
        return_time: null,
        injury_status: null,
        contact: null,
      },
      thisseasongamePerformanceData: [],
      trainingPerformanceData: {},
      newTrainingPerformanceData: {},
      newGamePerformanceData: {
        gameId: null,
        postion_played: null,
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        card: "No",
        timeOnPitch: null,
        gamesPlayed: null,
      },
      gamePerformanceData: {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      },
      coachGamePerformanceData: {
        gameId: null,
        player_rating: null,
        game_comments: null,
      },
      pastTrainingSessionsData: [],
      pastTrainingSessionsDataFiltered: [],
      pastGameData: [],
      playerMoodData: {},
      thisSeasionsPlayerMoodData: [],
      playerMoodBarChartDataSet: {},
      wellnessQuestions: {
        sleep: 0,
        stress: 0,
        happyness: 0,
        fatigue: 0,
        muscleSoreness: 0,
      },
      zeroToTen: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
      ],
      publicPath: process.env.BASE_URL,
      loadingStuff: false,
      selectedUserValue: "",
      weekSelectedNumber: "",
      weeklyDate: "",
      selectedWeekMonSunday: "",
      gamesPlayed: 0,
      selectedUserData: {},
      selectedUserDataForHeader: {},
      userData: {
        name: "",
        userType: "",
      },
      coachesUsersData: [],
      selectedCoachImageURL: null,
      seasonsgamePerformanceReportsForUser: [],
      seasonsTraningPerformanceReportsForUser: [],
      selectedTab: "performace-tab",
      currentPlayingSeason: null,
      season_start_date: null,
      season_end_date: null,
      allPlayerPhysicalPerformanceData: [],
      playerPhysicalPerformanceData: {},
      trainingPerformanceBarChartDataSet: {},
      allPlayerInjuryReports: [],
      playerInjuryHistoryAmounts: {
        headNeckInjury: 0,
        shoulderInjury: 0,
        armInjury: 0,
        wristAndHandInjury: 0,
        hipInjury: 0,
        groinInjury: 0,
        quadInjury: 0,
        hamstringInjury: 0,
        kneeInjury: 0,
        calfInjury: 0,
        ankleInjury: 0,
        footInjury: 0,
      },
      selectedPerformanceTarget: {},
      playerPerformanceTargets: [
        {
          player_id: this.selectedUserValue,
          performance_report_id: null,
          target: "",
          complete: 0,
          created_date: moment().format("YYYY-MM-DD"),
        },
      ],
      playerPerformanceReviewTargetsData: [],
      showReport: false,
      academyReport: {
        playerId: null,
        seasion: null,
      },
      slide: 0,
      sliding: true,
      columns: [
        {
          label: "Session name",
          field: "session_name",
        },
        {
          label: "Player name",
          field: "user_name",
        },
        {
          label: "Billing email",
          field: "email",
        },
        {
          label: "Purchase date",
          field: "date",
        },
        {
          label: "Total paid",
          field: "total_price",
        },
        {
          label: "Payment status",
          field: "payment_state",
        },
      ],
      columnsPlayers: [
        {
          label: "Player name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Payment status",
          field: "payment_state",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  async created() {
    this.loadingStuff = true;
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));

    const response = await this.getUserById(clientId);
    if (response === true) {
      if (
        this.userData.userType !== "Admin" &&
        this.userData.userType !== "Coach"
      ) {
        await this.getTeamsDataById(this.userData.teamId);
        this.loadPlayersProfilePerformanceData();
        this.getPlayerPerformanceReviews();
        this.getAllgoalsScoredByPlayerId();
        this.getAllgoalAssistsByPlayerId();
        this.selectedTeamId = this.userData.teamId;
      }

      if (this.userData.userType === "Admin") {
        this.getAllUsersForClubId();
      }

      if (this.userData.userType === "Coach") {
        this.getAllUsersForCoach();
      }
      this.loadingStuff = false;
    }
  },
  methods: {
    async saveHeight() {
      const userData = {
        id: this.selectedUserData.id,
        value: this.height,
        action: "saveHeight",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.getPlayerUserDataId(this.selectedUserValue);
        this.$bvModal.hide("addHeight");

        this.$bvToast.toast("Player height updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async saveWeight() {
      const userData = {
        id: this.selectedUserData.id,
        value: this.weight,
        action: "saveWeight",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200) {
        this.getPlayerUserDataId(this.selectedUserValue);
        this.$bvModal.hide("addWeight");

        this.$bvToast.toast("Player weight updated.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    removeFilter() {
      this.coachesUsersData = this.allUserDataBeforFilterApplied;
      this.hideRemoveTeamFilterButton = true;
    },
    filterBy(arrayToFilter, filterBy) {
      const filterResult = this[arrayToFilter].filter(
        (pilot) => pilot[filterBy] === this.selectedFilterData
      );

      this.coachesUsersData = filterResult;
      this.hideRemoveTeamFilterButton = false;
    },
    setCoachesUsersData(id) {
      this.$store.dispatch(
        POPULATE_COACHES_USER_DATA,
        JSON.parse(localStorage.getItem("coachesUsersData"))
      );
      this.getAllUsersForThisAdmin();
      this.setSelectedTrainingPlanUser(id);
    },
    setSelectedTrainingPlanUser(id) {
      let arrayResult = this.coachesUsersData.filter((selectedTrainingUser) => {
        return selectedTrainingUser.id == id;
      });
      if (arrayResult.length !== 0) {
        this.selectedTrainingUserData = arrayResult[0];
      }
    },
    setSelectedUserOfTrainingPlan(e) {
      let userId = null;
      this.filterdCoachesUsersData = [];
      e.target === undefined ? (userId = e) : (userId = e.target.value);

      this.coachesUsersData.length > 0
        ? this.setSelectedTrainingPlanUser(userId)
        : this.setCoachesUsersData(userId);
      // const selectedUser = e;
      this.selectedUseChanged({ ...e });
    },
    lookUpSearch(value) {
      if (value.length > 3) {
        this.filterdCoachesUsersData = [];

        const results = Array.from(value).reduce(
          (a, v, i) => `${a}[^${value.substr(i)}]*?${v}`,
          ""
        );
        const regEx = RegExp(results);

        this.coachesUsersData.filter((v) => {
          if (v.name.match(regEx)) {
            this.filterdCoachesUsersData.push(v);
          }
        });
      } else {
        this.filterdCoachesUsersData = [];
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    buildTheAcademyReportPDF() {
      let previewContainer = document.getElementById(
        "theAcademyReportPdf_preview"
      );
      // load player image //
      let img = document.querySelector("#user_photo");
      let pdf = new JsPDF("p", "mm", "a4");

      pdf.setFillColor(241, 246, 249);
      pdf.rect(0, 0, 300, 120, "F");

      pdf.setTextColor(3, 86, 158);
      pdf.addImage(img, "PNG", 1, 0, "40", "40");

      pdf.setFontSize(37).setFont(undefined, "bold");
      pdf.text(45, 18, "Ollie John Paxton", { maxWidth: 70 });

      pdf.setFontSize(16);
      pdf.text(5, 55, "Season: Aug 2022 - May 2023");

      pdf.setFontSize(16).setFont(undefined, "normal");
      pdf.text(5, 65, "Appearances:");
      pdf.text(75, 65, "19");

      pdf.text(5, 75, "Goals:");
      pdf.text(75, 75, "15");

      pdf.text(5, 85, "Assists:");
      pdf.text(75, 85, "10");

      pdf.text(5, 95, "Playerperformacerating:");
      pdf.text(75, 95, "Amazing");

      pdf.text(5, 105, "Gametime:");
      pdf.text(75, 105, "1300 mins");

      pdf.text(135, 15, "Dob: 13 Oct 2010").setFont(undefined, "normal");
      pdf
        .text(135, 25, "Position: Atacking Midfielder")
        .setFont(undefined, "normal");
      pdf.text(135, 35, "Height: 175.5 cm").setFont(undefined, "normal");
      pdf.text(135, 45, "Weight: 61.40 kg").setFont(undefined, "normal");
      pdf.text(135, 55, "Team: The W Academy").setFont(undefined, "normal");
      pdf.text(135, 65, "Signed: 10 Apr 2022").setFont(undefined, "normal");

      let pitchPostionImag = document.querySelector("#pitch_potions");
      pdf.addImage(pitchPostionImag, "JPEG", 130, 1, "74", "54");
      pdf.setFontSize(13).setFont(undefined, "bold");
      pdf.text(135, 104, "PLAYING POSITIONS");

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 140, "AVERAGE PERFORMANCE SCORE");
      pdf.setFontSize(72).setFont(undefined, "bold");
      pdf.text(5, 165, "25 / 30");

      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(5, 185, "COACHES COMMENTS");

      pdf.setFontSize(12).setFont(undefined, "normal");

      var paraOne =
        "Ollie excels as an attacker, showcasing remarkable comfort and skill on the ball. His technique and balance are evident in every move he makes. Moreover, Ollie demonstrates a strong passing ability, exhibiting a diverse range of passes.";
      let paraOneLines = pdf.splitTextToSize(paraOne, 115);
      pdf.text(5, 190, paraOneLines);

      var paraTwo =
        "Not only does Ollie possess exceptional athleticism, but his combative nature shines through on the field. His remarkablejumping ability and impressive sprint scores contribute to his overall performance.";
      let paraTwoLines = pdf.splitTextToSize(paraTwo, 115);
      pdf.text(5, 215, paraTwoLines);

      var paraThree =
        "One of Ollies standout qualities is his composed yet determined mindset. He approaches his individual development and team responsibilities with maturity, displaying a level-headed approach to the game.";
      let paraThreeLines = pdf.splitTextToSize(paraThree, 115);
      pdf.text(5, 240, paraThreeLines);

      let averageImage = document.querySelector("#average_scores");
      pdf.addImage(averageImage, "JPEG", 130, 160, 79, 49);

      let videolinkImage = document.querySelector("#video_link_image");
      pdf.addImage(videolinkImage, "JPEG", 5, 260, 200, 21);
      pdf.link(5, 260, 200, 21, {
        url: "https://www.dropbox.com/s/fls5898zoum7yqo/Leo%20Fisher.mov?dl=0",
      });

      let moodExampleImage = document.querySelector("#mood-example_image");
      pdf.addImage(moodExampleImage, "JPEG", 130, 220, 74, 17);

      return { pdfData: pdf, previewLink: previewContainer };
    },
    produceTheAcademyReport() {
      this.showReport = true;
      this.academyReport.playerId = "964";
      this.academyReport.seasion = "2023";

      setTimeout(() => {
        const that = this;
        let pdf = that.buildTheAcademyReportPDF();
        pdf.previewLink.src = pdf.pdfData.output("datauristring");
        this.showReport = true;
      }, 500);
    },
    openCreateAcademyReportModal() {
      this.$bvModal.show("academyReportModal");
    },
    performanceTargetsModal(data, modelName) {
      this.selectedPerformanceTarget = data;
      this.$bvModal.show(modelName);
    },
    addItem(targetText) {
      const playerPerformanceTarget = {
        player_id: this.selectedUserValue,
        performance_report_id: null,
        target: targetText,
        complete: 0,
        created_date: moment().format("YYYY-MM-DD"),
      };
      this.playerPerformanceTargets.push(playerPerformanceTarget);
    },
    removeItem(index) {
      this.playerPerformanceTargets.splice(index, 1);
    },
    openPhysicalPerformanceHistoryModal() {
      this.$bvModal.show("physicalPerformanceHistoryModal");
    },
    checkIfNewValueisBetterOrWorse() {
      if(this.allPlayerPhysicalPerformanceData.length < 2) { return false;}
      const lastItem =
        this.allPlayerPhysicalPerformanceData[
          this.allPlayerPhysicalPerformanceData.length - 1
        ];
      const secondLastItem =
        this.allPlayerPhysicalPerformanceData[
          this.allPlayerPhysicalPerformanceData.length - 2
        ];

      if (lastItem.tenM_sprint_time > secondLastItem.tenM_sprint_time) {
        lastItem.tenM_sprint_time_icon = "worse";
      }
      if (lastItem.tenM_sprint_time < secondLastItem.tenM_sprint_time) {
        lastItem.tenM_sprint_time_icon = "better";
      }
      if (lastItem.tenM_sprint_time === secondLastItem.tenM_sprint_time) {
        lastItem.tenM_sprint_time_icon = "same";
      }

      if (lastItem.fortyM_sprint_time > secondLastItem.fortyM_sprint_time) {
        lastItem.fortyM_sprint_time_icon = "worse";
      }
      if (lastItem.fortyM_sprint_time < secondLastItem.fortyM_sprint_time) {
        lastItem.fortyM_sprint_time_icon = "better";
      }
      if (lastItem.fortyM_sprint_time === secondLastItem.fortyM_sprint_time) {
        lastItem.fortyM_sprint_time_icon = "same";
      }

      if (lastItem.cuntermovement_jump > secondLastItem.cuntermovement_jump) {
        lastItem.cuntermovement_jump_icon = "better";
      }
      if (lastItem.cuntermovement_jump < secondLastItem.cuntermovement_jump) {
        lastItem.cuntermovement_jump_icon = "worse";
      }
      if (lastItem.cuntermovement_jump === secondLastItem.cuntermovement_jump) {
        lastItem.cuntermovement_jump_icon = "same";
      }

      if (
        lastItem.reactive_strength_index >
        secondLastItem.reactive_strength_index
      ) {
        lastItem.reactive_strength_index_icon = "better";
      }
      if (
        lastItem.reactive_strength_index <
        secondLastItem.reactive_strength_index
      ) {
        lastItem.reactive_strength_index_icon = "worse";
      }
      if (
        lastItem.reactive_strength_index ===
        secondLastItem.reactive_strength_index
      ) {
        lastItem.reactive_strength_index_icon = "same";
      }

      if (
        lastItem.thirty_15_intermittent_fitness_test >
        secondLastItem.thirty_15_intermittent_fitness_test
      ) {
        lastItem.thirty_15_intermittent_fitness_test_icon = "worse";
      }
      if (
        lastItem.thirty_15_intermittent_fitness_test <
        secondLastItem.thirty_15_intermittent_fitness_test
      ) {
        lastItem.thirty_15_intermittent_fitness_test_icon = "better";
      }
      if (
        lastItem.thirty_15_intermittent_fitness_test ===
        secondLastItem.thirty_15_intermittent_fitness_test
      ) {
        lastItem.thirty_15_intermittent_fitness_test_icon = "same";
      }
    },
    async addPhysicalPerformanceValue() {
      this.playerPhysicalPerformanceData.date = moment().format("YYYY-MM-DD");
      const userData = {
        date: this.playerPhysicalPerformanceData.date,
        player_id: this.selectedUserValue,
        tenM_sprint_time: this.playerPhysicalPerformanceData.tenM_sprint_time || 0,
        fortyM_sprint_time: this.playerPhysicalPerformanceData.fortyM_sprint_time || 0,
        cuntermovement_jump: this.playerPhysicalPerformanceData.cuntermovement_jump || 0,
        reactive_strength_index: this.playerPhysicalPerformanceData.reactive_strength_index || 0,
        thirty_15_intermittent_fitness_test: this.playerPhysicalPerformanceData.thirty_15_intermittent_fitness_test || 0,
        action: "addPlayerPhysicalPerformanceData",
      };

      const responce = await PlayerPhysicalPerformance.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.data === "New player physical performance results added.") {
        await this.checkIfNewValueisBetterOrWorse();
        this.loadPlayerPhysicalPerformanceData();
        this.$bvModal.hide("addNewPhysicalPerformanceTimeModal");

        this.$bvToast.toast("Player Physical Performance saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    addNewPhysicalPerformanceTimeModal() {
      this.$bvModal.show("addNewPhysicalPerformanceTimeModal");
    },
    saveSignature(siginitureName) {
      var _this = this;
      var png = _this.$refs[siginitureName].save();
      var jpeg = _this.$refs[siginitureName].save("image/jpeg");
      var svg = _this.$refs[siginitureName].save("image/svg+xml");
    },
    clearSignature(siginitureName) {
      var _this = this;
      _this.$refs[siginitureName].clear();
    },
    openAllGamegReportsModal(type) {
      this.$bvModal.show(type);
    },
    openAllTrainingReportsModal(type) {
      this.$bvModal.show(type);
    },
    getPlayerPhysicalPerformanceData() {
      return this.allPlayerPhysicalPerformanceData.reduce((a, b) => {
        return new Date(a.date) > new Date(b.date) ? a : b;
      });
    },
    getSelectedPerformanceReportTargets(reportId) {
      if (this.playerPerformanceReviewTargetsData.length === 0) {
        return this.playerPerformanceReviewTargetsData;
      }

      return this.playerPerformanceReviewTargetsData.filter(
        (element) => element.performance_report_id == reportId
      );

    },
    getPlayersCurrentPerformanceTargets() {
      if (this.playerPerformanceReviewTargetsData.length === 0) {
        return this.playerPerformanceReviewTargetsData;
      }

      const currentReport =
        this.allplayerPerformanceReportData[
          this.allplayerPerformanceReportData.length - 1
        ];
      return this.playerPerformanceReviewTargetsData.filter(
        (element) => element.performance_report_id == currentReport.id
      );
    },
    setPlayerPerformanceReviewData(reviewId) {
      return this.allplayerPerformanceReportData.filter(
        (review) => review.id == reviewId
      );
    },
    openPlayerPerformanceReview(reviewId) {
      const review = this.setPlayerPerformanceReviewData(reviewId);
      this.playerPerformanceReportData = review[0];
      this.playerPerformanceReportData.attitude = Number(
        this.playerPerformanceReportData.attitude
      );
      this.playerPerformanceReportData.leadership = Number(
        this.playerPerformanceReportData.leadership
      );
      this.playerPerformanceReportData.mentality = Number(
        this.playerPerformanceReportData.mentality
      );
      this.playerPerformanceReportData.physique = Number(
        this.playerPerformanceReportData.physique
      );
      this.playerPerformanceReportData.professionalism = Number(
        this.playerPerformanceReportData.professionalism
      );
      this.playerPerformanceReportData.skill = Number(
        this.playerPerformanceReportData.skill
      );

      this.$bvModal.show("playerPerformanceReportVewDetailsModal");
    },
    formatDateForHuman(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do YYYY")
        : "";
    },
    checkSignedDate(date) {
      return date === null || date === undefined || date === "" ? false : true;
    },
    converBoolean(boolean) {
      return boolean === "0" ? "No" : "Yes";
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    resetAllPlayerData() {
      this.resetGamePerformanceData();
      this.resetcoachGamePerformanceData();
      this.resetplayerTrainingData();
      this.reSetNewInjuryInformation();
      this.resetPlayersMoodData();
    },
    resetPlayersMoodData() {
      this.playerMoodData = {};
      this.wellnessQuestions = {
        sleep: 0,
        stress: 0,
        happyness: 0,
        fatigue: 0,
        muscleSoreness: 0,
      };
    },
    resetplayerTrainingData() {
      this.trainingPerformanceData = {};
      this.newTrainingPerformanceData = {};
    },
    reSetNewInjuryInformation() {
      this.playerInjuryData = [];
      this.newInjuryInformation = {
        injury_type: null,
        more_detail: null,
        return_time: null,
        injury_status: null,
        contact: null,
      };
    },
    resetcoachGamePerformanceData() {
      this.coachGamePerformanceData = {
        gameId: null,
        player_rating: null,
        game_comments: null,
        coachId: null,
      };
    },
    resetGamePerformanceData() {
      this.gamePerformanceData = {
        gameId: null,
        postion_played: [],
        goals: 0,
        assists: 0,
        clean_sheet: null,
        player_self_rating: null,
        yellowCardTotal: 0,
        redCardTotal: 0,
        redCardTotal: 0,
        timeOnPitch: null,
        gamesPlayed: null,
      };
    },
    hidePlayerInjuryReportModel() {
      this.$bvModal.hide("playerInjuryModal");
      this.reSetNewInjuryInformation();
      this.getPlayerInjuryReports();
      this.getAllPlayerInjuryReports();
    },
    resetPlayerInjuaryNumbers() {
      this.playerInjuryHistoryAmounts = {
        headNeckInjury: 0,
        shoulderInjury: 0,
        armInjury: 0,
        wristAndHandInjury: 0,
        hipInjury: 0,
        groinInjury: 0,
        quadInjury: 0,
        hamstringInjury: 0,
        kneeInjury: 0,
        calfInjury: 0,
        ankleInjury: 0,
        footInjury: 0,
      };
    },
    download_csv(data, sensor) {
      let csvHeader = Object.keys(data[0]).join(",") + "\n"; // header row
      let csvBody = data.map((row) => Object.values(row).join(",")).join("\n");

      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(csvHeader + csvBody);
      hiddenElement.target = "_blank";
      hiddenElement.download = sensor + ".csv";
      hiddenElement.click();
    },
    populatePlayerInjuryNumbers() {
      this.resetPlayerInjuaryNumbers();
      const injuryTypes = {
        "Head injury": "headNeckInjury",
        "Left Shoulder injury": "shoulderInjury",
        "Right Shoulder injury": "shoulderInjury",
        "Left Arm injury": "armInjury",
        "Right Arm injury": "armInjury",
        "Left Wrist and hand injury": "wristAndHandInjury",
        "Right Wrist and hand injury": "wristAndHandInjury",
        "Left Hip injury": "hipInjury",
        "Right Hip injury": "hipInjury",
        "Left Groin injury": "groinInjury",
        "Right Groin injury": "groinInjury",
        "Left Quad injury": "quadInjury",
        "Right Quad injury": "quadInjury",
        "Left hamstring injury": "hamstringInjury",
        "Right hamstring injury": "hamstringInjury",
        "Left Knee injury": "kneeInjury",
        "Right Knee injury": "kneeInjury",
        "Left Calf injury": "calfInjury",
        "Right Calf injury": "calfInjury",
        "Left ankle injury": "ankleInjury",
        "Right ankle injury": "ankleInjury",
        "Left Foot injury": "footInjury",
        "Right Foot injury": "footInjury",
      };

      this.allPlayerInjuryReports.forEach((injury) => {
        const injuryType = injuryTypes[injury.injury_type];
        if (injuryType) {
          this.playerInjuryHistoryAmounts[injuryType]++;
        }
      });
    },
    async getAllPlayerInjuryReports() {
      const userData = {
        player_id: this.selectedUserValue,
        action: "getAllPlayerInjuryReports",
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allPlayerInjuryReports = responce.data;
        this.populatePlayerInjuryNumbers();
      }
    },
    async getPlayerInjuryReports() {
      this.playerInjuryData = [];
      const userData = {
        player_id: this.selectedUserValue,
        year: this.currentPlayingSeason,
        action: "getPlayerInjuryReports",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };

      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.playerInjuryData = responce.data;
      }
    },
    async deletePlayerInjuryReport(id) {
      this.loadingStuff = true;
      const userData = {
        id: id,
        action: "deletePlayerInjuryReport",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Injury Report removed") {
        this.reSetNewInjuryInformation();
        this.getPlayerInjuryReports();
        this.highlightInjuredPlayers();
        this.loadingStuff = false;

        this.$bvToast.toast("Player injury report removed", {
          title: "Removed",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async updatePlayerInjuryReport() {
      const userData = {
        ...this.newInjuryInformation,
        action: "updatePlayerInjuryReport",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Player injury updated.") {
        this.$bvModal.hide("editPlayerInjuryModal");
        this.reSetNewInjuryInformation();
        this.getPlayerInjuryReports();
        this.loadingStuff = false;

        this.$bvToast.toast("Player injury report updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async savePlayerInjuryReport() {
      const userData = {
        ...this.newInjuryInformation,
        player_id: this.selectedUserValue,
        clubId: this.userData.clubId,
        created_date: moment().format("YYYY-MM-DD"),
        action: "savePlayerInjuryReport",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Player injury saved.") {
        this.$bvModal.hide("playerInjuryModal");
        this.reSetNewInjuryInformation();
        this.getPlayerInjuryReports();
        this.getAllPlayerInjuryReports();
        this.loadingStuff = false;

        this.$bvToast.toast("Player injury report saved", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    openPlayerPerformanceModal(modelId) {
      this.playerPerformanceReportData = {};
      this.playerPerformanceReportData.review_date =
        moment().format("YYYY-MM-DD");
      this.showModal(modelId);
    },
    openPlayerInjuryModal(modelId) {
      this.showModal(modelId);
    },
    editInjuryModal(modelId, injuryData) {
      this.newInjuryInformation = injuryData;
      this.showModal(modelId);
    },
    checkUserPosition() {
      return Object.keys(this.selectedUserData).length === 0
        ? this.userData.position
        : this.selectedUserData.position;
    },
    checkDateTimeFromNow(date) {
      let now = new Date();
      let otherDate = new Date(date);
      new Date(now.setHours(0, 0, 0, 0)).toISOString();
      new Date(otherDate.setHours(0, 0, 0, 0)).toISOString();
      const diffTime = Math.abs(
        new Date(now.setHours(0, 0, 0, 0)) -
          new Date(otherDate.setHours(0, 0, 0, 0))
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays > 7 ? "red-warning" : "";
    },
    openTrainingPlanPage() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      this.$router.push({
        name: "trainingPlans",
        params: { trainingPlanUserId: this.selectedUserValue },
      });

      /* if (userType === "User") {
        this.$store.dispatch(POPULATE_SELECTED_USER_DATA, userId);
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: 0 }
        });
      } else {
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: userId }
        });
      } */
    },
    performanceTargetsHistoryModal() {
      this.showModal("performanceTargetsHistoryModal");
    },
    async selectedUseChanged(player) {
      await this.getTeamsDataById(player.teamId);
      player.selectedUser === undefined
        ? (this.selectedUserValue = player.id)
        : (this.selectedUserValue = player.selectedUser);

      this.selectedUserDataForHeader = player;

      if (!this.checkSignedDate(this.selectedUserDataForHeader.joined_date)) {
        this.showModal("missingDataModal");
      }
      this.loadPlayersProfilePerformanceData();
      this.getPlayerUserDataId(this.selectedUserValue);
      this.getPlayerPerformanceReviews();
    },
    async getTeamsDataById(teamId) {
      const userData = {
        id: teamId,
        action: "getTeamDataById",
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        userData.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        localStorage.setItem(
          "currentPlayingseasonStartDate",
          responce.data.season_start_date
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          responce.data.season_end_date
        );
        (this.season_start_date = responce.data.season_start_date),
          (this.season_end_date = responce.data.season_end_date);
        return true;
      }
    },
    async loadPlayerPhysicalPerformanceData() {
      const userData = {
        player_id: this.selectedUserValue,
        action: "loadPlayerPhysicalPerformanceDataById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PlayerPhysicalPerformance.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.data.length > 0) {
        this.allPlayerPhysicalPerformanceData = responce.data;
        this.playerPhysicalPerformanceData =
          this.getPlayerPhysicalPerformanceData();
        this.checkIfNewValueisBetterOrWorse();
      }
    },
    loadPlayersProfilePerformanceData() {
      this.loadingStuff = true;
      this.resetAllPlayerData();
      this.getAllGamesAttendedById();
      this.getThisSeasonsgamePerformanceReportsForUser();
      this.getCoachesGamePerformanceReport();
      this.getAllTraningAttendedById();
      this.getThisSeasonsTraningPerformanceReportsForUser();
      this.loadPlayerPhysicalPerformanceData();
      this.getAllgoalsScoredByPlayerId();
      this.getAllgoalAssistsByPlayerId();

      this.loadingStuff = false;
    },
    prePopulatePlayerPerformanceValues() {
      this.playerPerformanceReportData.userImage =
        this.selectedUserData.userImage;
      this.playerPerformanceReportData.name = this.selectedUserData.name;
      this.playerPerformanceReportData.userImage =
        this.selectedUserData.userImage;
      this.playerPerformanceReportData.phone = this.selectedUserData.phone;
      this.playerPerformanceReportData.email = this.selectedUserData.email;
      this.playerPerformanceReportData.clubId = this.selectedUserData.clubId;
      this.playerPerformanceReportData.teamId = this.selectedUserData.teamId;
      this.playerPerformanceReportData.userId = this.selectedUserData.id;
      this.playerPerformanceReportData.dob = this.selectedUserData.dob;
      this.playerPerformanceReportData.position =
        this.selectedUserData.position;
      this.playerPerformanceReportData.preferred_foot =
        this.selectedUserData.preferred_foot;
      this.playerPerformanceReportData.coachId = this.userData.id;
    },
    async savePlayerPerformanceReview() {
      this.prePopulatePlayerPerformanceValues();
      const userData = {
        ...this.playerPerformanceReportData,
        performanceTargets: this.playerPerformanceTargets,
        action: "addPerformanceReview",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "New performance review added.") {
        this.getPlayerPerformanceReviews();
        this.$bvModal.hide("playerPerformanceReportModal");
        this.$bvToast.toast("Performance review added", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    makeTheewPerformanceReportTargetObject() {

      return {
        player_id: this.selectedUserValue,
        performance_report_id: this.selectedPerformanceTarget.performance_report_id,
        target: this.selectedPerformanceTarget.target,
        complete: this.selectedPerformanceTarget.complete !== undefined ? this.selectedPerformanceTarget.complete : 0,
        created_date: moment().format("YYYY-MM-DD"),
        action: "addNewPerformanceReportTarget",
      };
      
    },
    async addNewPerformanceReportTarget() {
      const userData = this.makeTheewPerformanceReportTargetObject();
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target added") {
        this.getPerformanceReviewTargets();
        this.$bvModal.hide("newPerformanceTargetsModal");
        this.$bvToast.toast("Performance report target added", {
          title: "added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async deletePerformanceReportTarget() {
      const userData = {
        id: this.selectedPerformanceTarget.id,
        action: "deletePerformanceReportTarget",
      };

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      })

      if (responce.data === "Performance report target deleted") {
        this.getPerformanceReviewTargets();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target deleted", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }


      
    },
    async updatePerformanceReportTarget() {
      const userData = {
        ...this.selectedPerformanceTarget,
        action: "updatePerformanceReportTarget",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target updated") {
        this.getPerformanceReviewTargets();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getPerformanceReviewTargets() {
      const userData = {
        userId: this.selectedUserValue,
        action: "getPerformanceReviewTargets",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.playerPerformanceReviewTargetsData = responce.data;
      }
    },
    async getAllgoalsScoredByPlayerId() {
      const data = {
        action: "getAllgoalsScoredByPlayerId",
        userId: this.selectedUserValue,
        season_start_date: this.season_start_date,
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.goals = responce.data;
      }
    },
    async getAllgoalAssistsByPlayerId() {
      const data = {
        action: "getAllgoalAssistsByPlayerId",
        userId: this.selectedUserValue,
        season_start_date: this.season_start_date,
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.gamePerformanceData.assists = responce.data;
      }
    },
    async getPlayerPerformanceReviews() {
      const userData = {
        userId: this.selectedUserValue,
        season_start_date: this.season_start_date,
        season_end_date: this.extendSeasonEndDateToFullYear(),
        action: "getPerformanceReviews",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allplayerPerformanceReportData = responce.data;
        this.getPerformanceReviewTargets();
      } else {
        console.log("no reviews");
      }
    },
    async getCoachesGamePerformanceReport() {
      const userData = {
        user_id: this.selectedUserValue,
        action: "getLastestCoachGamePerformanceReportsForUser",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null && responce.data !== false) {
        this.coachGamePerformanceData = responce.data;
        this.getCoachById(this.coachGamePerformanceData.coachId);
      }
    },
    async saveTraningPerformanceReport() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      this.loadingStuff = true;
      const userData = {
        ...this.newTrainingPerformanceData,
        user_id: this.selectedUserValue,
        created_date: moment().format("YYYY-MM-DD"),
        action: "saveUserTraningPerformanceReport",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Traning Performance Report added") {
        this.getAllTraningAttendedById();
        this.getThisSeasonsTraningPerformanceReportsForUser();
        this.$bvModal.hide("trainingPerformanceModal");
        this.loadingStuff = false;
        this.$bvToast.toast("Traning Performance Report added", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

      if (responce.data === "Training Performance Report already exists") {
        this.$bvModal.hide("trainingPerformanceModal");
        this.loadingStuff = false;
        this.$bvToast.toast("Training Performance Report already exists", {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "warning",
        });
      }
    },
    async coachesGamePerformanceReport() {
      const userData = {
        ...this.coachGamePerformanceData,
        coachId: this.userData.id,
        user_id: this.selectedUserValue,
        created_date: moment().format("YYYY-MM-DD"),
        action: "saveCoachesGamePerformanceReport",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.showGamePerformanceToast(responce.data, "coachGamePerformanceModal");
    },
    populatePlayerSelfRating() {
      let result = this.seasonsgamePerformanceReportsForUser.reduce((a, b) =>
        a.date > b.date ? a : b
      );
      this.gamePerformanceData.gameId = result.gameId;
      this.gamePerformanceData.player_self_rating = result.player_self_rating;
      this.gamePerformanceData.created_date = result.created_date;
    },
    populateGameStatsForThisSeason(typeOfData) {
      this.gamePerformanceData[typeOfData] =
        this.seasonsgamePerformanceReportsForUser.reduce(function (
          accumulator,
          player
        ) {
          return accumulator + Number(player[typeOfData]);
        },
        0);
    },
    populateTraningStatsForThisSeason(typeOfData) {
      this.trainingPerformanceData[typeOfData] =
        this.seasonsTraningPerformanceReportsForUser.reduce(function (
          accumulator,
          player
        ) {
          return accumulator + Number(player[typeOfData]);
        },
        0);
    },
    populatePostionsPlayed() {
      if (this.seasonsgamePerformanceReportsForUser.length > 0) {
        this.gamePerformanceData.postion_played = [];
        let array = [];
        this.seasonsgamePerformanceReportsForUser.filter(function (postion) {
          array.push(postion.postion_played);
        });
        Object.keys(this.selectedUserData).length === 0
          ? this.gamePerformanceData.postion_played.push(this.userData.position)
          : this.gamePerformanceData.postion_played.push(
              this.selectedUserData.position
            );
        this.gamePerformanceData.postion_played = array;
      } else {
        Object.keys(this.selectedUserData).length === 0
          ? this.gamePerformanceData.postion_played.push(this.userData.position)
          : this.gamePerformanceData.postion_played.push(
              this.selectedUserData.position
            );
      }
    },
    pupulatePlayerCards() {
      this.seasonsgamePerformanceReportsForUser.forEach((player) => {
        console.log(player.card);
        if (player.card === "Yellow") {
          this.gamePerformanceData.yellowCardTotal =
            this.gamePerformanceData.yellowCardTotal + 1;
        }
        if (player.card === "Red" || player.card === "two Yellows (red)") {
          this.gamePerformanceData.redCardTotal =
            this.gamePerformanceData.redCardTotal + 1;
        }
      });
    },
    setCurrentTraningSessionRating() {
      return this.seasonsTraningPerformanceReportsForUser.reduce((a, b) =>
        a.created_date > b.created_date ? a : b
      );
    },
    async getThisSeasonsTraningPerformanceReportsForUser() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }

      const userData = {
        user_id: this.selectedUserValue,
        action: "getThisSeasonsTraningPerformanceReportsForUser",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.seasonsTraningPerformanceReportsForUser = responce.data;
        this.trainingPerformanceData = {
          ...this.setCurrentTraningSessionRating(),
          ...this.trainingPerformanceData,
        };
        let effortData = this.populateTrainingPerformanceDataBarChart(
          "seasonsTraningPerformanceReportsForUser",
          "effort"
        );
        this.barChartDataSet(effortData, [
          "Amazing",
          "Good",
          "Ok",
          "Did not enjoy it",
        ]);
      }
    },
    barChartDataSet(effortData, labelsData) {
      this.trainingPerformanceBarChartDataSet = {
        labels: labelsData,
        datasets: [
          {
            label: "Effort",
            borderColor: "rgba(50, 115, 220, 0.5)",
            backgroundColor: "rgba(50, 115, 220, 0.1)",
            data: effortData,
          },
        ],
      };
    },
    populateBarChartWithData(arrayName, value) {
      let test = [];
      arrayName.filter((i) => {
        test.push(i[value]);
      });

      return test;
    },
    populateTrainingPerformanceDataBarChart(arrayName, value) {
      let test = [];
      this[arrayName].filter((i) => {
        test.push(i[value]);
      });

      return test;
    },
    barChartDataForPlayerMood() {
      this.playerMoodBarChartDataSet = {
        labels: this.populateTrainingPerformanceDataBarChart(
          "thisSeasionsPlayerMoodData",
          "date"
        ),
        datasets: [
          {
            label: "fatigue",
            borderColor: "rgba(50, 115, 220, 0.5)",
            backgroundColor: "rgba(50, 115, 220, 0.1)",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "fatigue"
            ),
          },
          {
            label: "happyness",
            borderColor: "#4caf50",
            backgroundColor: "#4caf50",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "happyness"
            ),
          },
          {
            label: "muscle soreness",
            borderColor: "#ff9f00",
            backgroundColor: "#ff9f00",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "muscle_soreness"
            ),
          },
          {
            label: "sleep",
            borderColor: "#b605ff",
            backgroundColor: "#b605ff",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "sleep"
            ),
          },
          {
            label: "stress",
            borderColor: "#e53c3c",
            backgroundColor: "#e53c3c",
            data: this.populateTrainingPerformanceDataBarChart(
              "thisSeasionsPlayerMoodData",
              "stress"
            ),
          },
        ],
      };
    },
    async getThisSeasonsgamePerformanceReportsForUser() {
      this.seasonsgamePerformanceReportsForUser = [];
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getThisSeasonsgamePerformanceReportsForUser",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      console.log(responce.data);

      if (responce.data.length > 0) {
        this.seasonsgamePerformanceReportsForUser = responce.data;
        this.populatePlayerSelfRating();
        this.populateGameStatsForThisSeason("goals");
        this.populateGameStatsForThisSeason("assists");
        this.populateGameStatsForThisSeason("clean_sheet");
        this.populateGameStatsForThisSeason("timeOnPitch");
        this.pupulatePlayerCards();
      }

      this.populatePostionsPlayed();
    },
    showGamePerformanceToast(responce, modelId) {
      if (responce === "Game Performance Report added") {
        this.$bvModal.hide(modelId);
        this.$bvToast.toast("Game Performance Report added", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

      if (responce === "Game Performance Report already exists") {
        this.$bvModal.hide(modelId);
        this.$bvToast.toast("Game Performance Report already exists", {
          title: "Error",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "warning",
        });
      }
    },
    async saveUserGamePerformanceReport() {
      this.loadingStuff = true;
      const userData = {
        ...this.newGamePerformanceData,
        user_id: this.userData.id,
        created_date: moment().format("YYYY-MM-DD"),
        action: "saveUserGamePerformanceReport",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.loadPlayersProfilePerformanceData();
      this.loadingStuff = false;
      this.showGamePerformanceToast(responce.data, "performanceModal");
    },
    async getAllGameReportsById() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getAllGameReportsById",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      console.log(responce.data);
    },
    SetTrainingHoursThisSeason() {
      return this.trainingPerformanceData.traningAttended !== 0
        ? (this.trainingPerformanceData.time =
            this.trainingPerformanceData.traningAttended * 60)
        : (this.trainingPerformanceData.time = 0);
    },
    async getAllTraningAttendedById() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getAllTraningAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.trainingPerformanceData.traningAttended = responce.data;
      this.SetTrainingHoursThisSeason();
    },
    async getAllGamesAttendedById() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getAllGamesAttendedById",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),

        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      this.gamePerformanceData.gamesPlayed = responce.data;
    },
    editPlayerProfileInSettings() {
      this.$router.push("/settings").catch(() => {});
    },
    hideCoachPerformanceModel() {
      this.getCoachesGamePerformanceReport();
    },
    openTrainingPerformanceModal() {
      this.getPastTrainingSessionsInfo();
      this.showModal("trainingPerformanceModal");
    },
    openGamePerformanceModal(modelId) {
      if (modelId === "coachGamePerformanceModal") {
        this.coachGamePerformanceData = {
          gameId: null,
          player_rating: null,
          game_comments: null,
        };
      }
      this.getPastGamesInfo();
      this.showModal(modelId);
    },
    getPlayerImage() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        return this.selectedUserDataForHeader.userImage !== "" &&
          this.selectedUserDataForHeader.userImage !== null
          ? this.getImgUrl(this.selectedUserDataForHeader.userImage)
          : this.getImgUrl("default-image.jpg");
      }

      if (this.userData.userType === "User") {
        return this.userData.userImage !== "" &&
          this.userData.userImage !== null
          ? this.getImgUrl(this.userData.userImage)
          : this.getImgUrl("default-image.jpg");
      }
    },
    filterOutAllTrainingSessionsWithAPerformanceReport() {
      let results = [...this.pastTrainingSessionsData];

      results.map((trainingSessionItem, index) => {
        this.seasonsTraningPerformanceReportsForUser.map(
          (traningSession) => {
            if (trainingSessionItem.id === traningSession.traningId) {
              console.log(results[index]);
              results.splice(index, 1);
            }
          }
        );
      });

      this.pastTrainingSessionsDataFiltered = results;
    },
    filterOutAllGamesWithAPerformanceReport(data) {
      let results = data;
      data.map((game, index) => {
        this.seasonsgamePerformanceReportsForUser.map((report) => {
          if (game.id === report.gameId) {
            results.splice(index, 1);
          }
        });
      });
      console.log(results);
      this.pastGameDataDataFiltered = results;
    },
    async getPastTrainingSessionsInfo() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }

      const userData = {
        user_id: this.selectedUserValue,
        action: "getPastTrainingSessionsInfo",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.pastTrainingSessionsData = responce.data;
        this.filterOutAllTrainingSessionsWithAPerformanceReport();
      }
    },
    async getPastGamesInfo() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }

      const userData = {
        user_id: this.selectedUserValue,
        action: "getPastGamesEvents",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.filterOutAllGamesWithAPerformanceReport(responce.data);
        this.pastGameData = responce.data;
      }
    },
    async getLatestPlayerMoodData() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      const userData = {
        player_id: this.selectedUserValue,
        action: "getLatestPlayerMoodData",
      };
      const responce = await Wellness.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== undefined) {
        this.playerMoodData = responce.data;
        this.getThisSeasionsPlayerMoodData();
      }
    },
    async getThisSeasionsPlayerMoodData() {
      const userData = {
        player_id: this.selectedUserValue,
        action: "getThisSeasionsPlayerMoodData",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Wellness.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.thisSeasionsPlayerMoodData = responce.data;
        this.barChartDataForPlayerMood();
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    calendarDateChanged(date) {
      this.weeklyDate = date;
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
      if (selectedTabValue === "payments-tab") {
        this.getPlayersPaymentInformation();
      }

      if (selectedTabValue === "players-reports-tab") {
        this.getAllBookingsMadeInlastTwoMonths();
      }

      if (selectedTabValue === "injury-tab") {
        this.getPlayerInjuryReports();
        this.getAllPlayerInjuryReports();
      }

      if (selectedTabValue === "wellness-tab") {
        this.getLatestPlayerMoodData();
      }
    },
    async getAllBookingsMadeInlastTwoMonths() {
      this.loadingStuff = true;
      const data = {
        action: "getAllBookingsMadeInlastTwoMonths",
        clubId: this.userData.clubId,
        lastPaymentDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allBookingData = responce.data;
        this.checkPlayersPaymentStatus();

        this.loadingStuff = false;
      }
    },
    checkPlayersPaymentStatus() {
      this.playersWithoutLiveSubscriptions = this.coachesUsersData.filter(
        (player) => {
          return !this.allBookingData.some(
            (booking) => booking.user_id === player.id
          );
        }
      );
    },
    async getPlayersPaymentInformation() {
      const data = {
        action: "getPlayersBookingsById",
        userId: this.selectedUserValue,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.playersPayment = responce.data;
      }
    },
    async getPlayerUserDataId(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.selectedUserData = responce.data[0];
        this.selectedTeamId = this.selectedUserData.teamId;
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "User" &&
          !this.checkSignedDate(this.userData.joined_date)
        ) {
          this.showModal("missingDataModal");
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async saveHowYouFeel() {
      const today = moment();
      const todayFormatted = moment(today).format("YYYY-MM-DD");
      const userData = {
        player_id: this.userData.id,
        ...this.wellnessQuestions,
        date: todayFormatted,
        action: "savePlayerMood",
      };

      const responce = await Wellness.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Your mood has been saved.") {
        this.getLatestPlayerMoodData();
        this.$bvModal.hide("viewDailyWellnessReport");
        this.$bvToast.toast("Your mood has been saved.", {
          title: "Saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

      if (
        responce.data === "You have already submitted your daily mood today."
      ) {
        this.$bvModal.hide("viewDailyWellnessReport");
        this.$bvToast.toast(
          "You have already submitted your daily mood today.",
          {
            title: "Info",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "warning",
          }
        );
      }
    },
    async getCoachById(id) {
      const userData = {
        id: id,
        action: "getCoachById",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.selectedCoachImageURL =
          responce.data[0].userImage !== "" &&
          responce.data[0].userImage !== null
            ? this.getImgUrl(responce.data[0].userImage)
            : this.getImgUrl("default-image.jpg");
      }
    },
    addInjuredPlayerInfo(data) {
      this.coachesUsersData = this.coachesUsersData.map((player) => {
        const hasInjury = data.some(
          (injury) =>
            player.id == injury.player_id &&
            injury.injury_status !== "Fully recoverd"
        );
        player.injury = hasInjury;
        return player;
      });
    },
    async highlightInjuredPlayers() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getInjuryedPlayersByClubId",
      };
      const responce = await PlayerInjury.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.addInjuredPlayerInfo(responce.data);
      }
    },
    async getAllUsersForCoach() {
      const userData = {
        clubId: this.userData.clubId,
        coach_id: this.userData.id,
        action: "getAllUsersForCoach",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    async getTeamsByCoachid() {
      const data = {
        action: "getTeamsByCoachid",
        coach_id: this.userData.id,
      };

      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.clubTeams = responce.data;
      }
    },
    async getTeamsByUserId() {
      if (this.userData.userType === "Coach") {
        this.getTeamsByCoachid();
      } else {
        const data = {
          action: "getTeamsDataById",
          userId: this.userData.id,
        };

        if (this.userData.userType === "Admin") {
          data.clubId = this.userData.clubId;
        }
        const response = await Teams.find(data).catch((error) => {
          console.log(error);
        });
        this.clubTeams = response.data;
      }
    },
    populatePlayerDataFromAllUsersCall(data) {
      this.coachesUsersData = data;
      this.highlightInjuredPlayers();
      this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
      localStorage.setItem(
        "coachesUsersData",
        JSON.stringify(this.coachesUsersData)
      );
      this.allUserDataBeforFilterApplied = this.coachesUsersData;
      this.getTeamsByUserId();
    },
    async getAllUsersForClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getUserByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.populatePlayerDataFromAllUsersCall(responce.data);
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    pounds(amount) {
      const numberWithRemovedZeros = amount / 100;
      if (amount !== undefined) {
        return Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
        }).format(numberWithRemovedZeros);
      }
    },
    formatDate(date) {
      if (date === null) {
        return "No Date";
      }
      return moment(date).format("MMMM Do YYYY");
    },
    formatDates(date) {
      return moment(date).format("DD MMM YYYY");
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("MMMM Do YYYY h:mm a");
    },
  },
};
</script>
<style scoped lang="scss">
.performance-target-modal-content {
  .custom-checkbox {
    border: 1px solid #dee2e6;
    padding: 1rem;
    margin: 1rem 0;

    label.custom-control-label {
      margin-left: 0.5rem;
    }
  }
}

.target-info {
  border-bottom: 1px dotted #a9b3bc;
  margin-bottom: 1rem;

  h4 {
    img {
      width: 1rem;
    }
  }

  .target-content {
    display: flex;
    justify-content: space-between;
     p {
      max-width: 20rem;
     }
  }
}

.perfomance-targets {
  border-top: 1px solid #e4e4e4;
  padding: 1rem 0;

  label {
    button {
      margin-right: 0.5rem;
    }
  }

  .each-target {
    border-top: 1px dashed #e4e4e4;
    padding: 1rem 0;
    margin-top: 1rem;
  }
}

.player-profile-details.injury {
  .user-photo-info {
    border: 1px solid red !important;
    background: #ffeeeb;
  }
}

.headding-space {
  border-top: none;
  padding-top: 0px !important;
}
.tabs {
  .tab-item {
    min-width: auto;
  }
}

.user-info {
  p {
    img {
      width: 1.5rem;
    }
  }
}

.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.td-no-padding {
  padding: 0;
}

.comments-box {
  border: 1px solid #dee2e6;
  margin-top: 1rem;
  padding: 0.5rem;
}

.player-performance-review-info {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 3rem;
}

.review-average-score {
  min-width: 20rem;
}

.physical-performance {
  ul {
    li {
      display: flex;
      font-weight: bold;
      margin-bottom: 0.5rem;
      position: relative;
      padding-right: 2.7rem;
      align-items: start;
      border-bottom: 1px solid #a9b3bc;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;

      span {
        font-weight: normal;
        width: 17rem;
      }

      span.time-value {
        width: 6rem !important;
        padding: 0 0.5rem;
      }

      button {
        margin-left: 0.5rem;
        margin-bottom: 0;
      }

      img {
        width: 1.3rem;
        margin: 0.5rem 0.5rem 0 0.5rem;
      }
    }
  }
}

.small-image {
  border: 1px solid #a9b3bc;
  width: 4rem;
  border-radius: 100px;
  margin-right: 1rem;
}

.session-enjoyment-wrapper {
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}

.all-training-reports {
  a {
    border: 1px solid #038cfc;
    padding: 0.5rem 1rem;
    display: block;
    margin-bottom: 1rem;
    border-radius: 4px;
    max-width: 28rem;
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      background: #47a9f9;
      color: white;
    }
  }
}

.signatureWrapper {
  margin-bottom: 2rem;

  div {
    border: 1px solid #d6dadd;
    margin-bottom: 1rem;
  }
}

.players-code {
  margin-bottom: 2rem;
}

.player-injury-body-map {
  position: relative;
  width: 20rem;

  img {
    width: 100%;
  }

  .injury-spot {
    position: absolute;
    font-size: 0.7rem;
    text-align: center;

    span {
      display: block;
      border: 1px solid red;
      width: 2rem;
      height: 2rem;
      border-radius: 100px;
      padding-bottom: 1rem;
      margin: 1rem auto 0 auto;

      &:hover,
      &:focus {
        background: red;
        cursor: pointer;
      }
    }
  }

  .Right-foot-injury {
    bottom: 2%;
    left: 67%;
  }

  .Left-foot-injury {
    bottom: 2%;
    left: 27%;
  }

  .Head-injury {
    top: 0%;
    left: 44%;
  }

  .Left-Wrist-and-hand-injury {
    top: 50%;
    left: 7%;
  }

  .Right-Wrist-and-hand-injury {
    top: 50%;
    left: 85%;
  }

  .Left-Shoulder-injury {
    top: 16%;
    left: 29%;
  }

  .Right-Shoulder-injury {
    top: 16%;
    left: 59%;
  }

  .Left-Hip-injury {
    top: 45%;
    left: 26%;
  }

  .Right-Hip-injury {
    top: 45%;
    left: 66%;
  }

  .Right-groin-injury {
    top: 53%;
    left: 51%;
  }

  .Left-groin-injury {
    top: 53%;
    left: 39%;
  }

  .Right-hamstring-injury {
    top: 60%;
    left: 39%;
  }

  .Left-hamstring-injury {
    top: 60%;
    left: 51%;
  }

  .Right-Quad-injury {
    top: 56%;
    left: 64%;
  }

  .Left-Quad-injury {
    top: 56%;
    left: 26%;
  }

  .Right-Knee-injury {
    top: 67%;
    left: 61%;
  }

  .Left-Knee-injury {
    top: 67%;
    left: 33%;
  }

  .Left-ankle-injury {
    bottom: 10%;
    left: 33%;
  }
  .Right-ankle-injury {
    bottom: 10%;
    left: 62%;
  }

  .Right-calf-injury {
    bottom: 20%;
    left: 62%;
  }

  .Left-calf-injury {
    bottom: 18%;
    left: 32%;
  }

  .Left-arm-injury {
    top: 32%;
    left: 18%;
  }

  .Right-arm-injury {
    top: 32%;
    left: 72%;
  }
}

.daily-planner-wrapper {
  width: 100%;
  padding: 0 2rem;
}

.completed {
  color: #08a10f;
}

.injury-table {
  tbody {
    tr.full-recoverd {
      border-left: 2px solid #08a10f;

      .injury-status {
        color: #08a10f;
      }
    }

    tr {
      border-left: 2px solid #d85c64;
    }
  }
}

table {
  tr {
    td {
      button.btn.btn-outline-warning,
      button.btn.btn-outline-danger {
        background: white;
      }

      button.btn.btn-outline-warning {
        &:hover,
        &:focus {
          color: #ffc109;
        }
      }

      button.btn.btn-outline-danger {
        &:hover,
        &:focus {
          color: #d85c64;
        }
      }
    }
  }
}

.players-without-subscriptions {
  margin: 1rem;
}

span.unpaid {
  background: #d85c64;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: white;
  padding: 0.1rem 1rem;
  font-weight: bold;
}

ul.injury-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  span {
    font-weight: bold;
    float: right;
  }
}

.modal-container {
  margin-top: 1rem;
  width: 100%;
  height: 50rem;

  iframe {
    border: 0;
    width: 100%;
    height: 50rem;
  }
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.mood-chart {
  flex-grow: 1;
  min-height: 0;

  > div {
    position: relative;
    height: 100%;
  }
}

.player-cv {
  border: 1px solid #a9b3bc;
  max-width: 1000px;
  padding: 2rem;

  .sessions-info {
    display: flex;
    flex-wrap: wrap;

    .session-card {
      width: 48%;
    }
  }
}

.modal-container.performance-report {
  img {
    width: 100%;
  }
}

.payments-tab.tab-content {
  display: block;
  padding: 1rem;
}

.player-cv-content {
  .cv-header {
    display: flex;
    margin-bottom: 2rem;

    .user-photo-info {
      display: flex;

      h1 {
        margin-left: 1rem;
      }
      img {
        border-radius: 100px;
        border: 1px solid #c3cdd5;
        width: 150px;
        height: 150px;
      }
    }

    .cv-address {
      ul {
        list-style: none;
        li {
          padding: 0.2rem 0;
        }
      }
    }
  }

  .cv-body {
    .Personal-profile {
      margin: 3rem 0;
    }

    .players-videos {
      .sessions-info {
        .session-card {
          width: 47%;
        }
      }
    }
  }
}

.education {
  margin: 2rem 0;
  ul {
    li {
      margin-bottom: 1rem;

      .title {
        font-weight: bold;
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.create-academy-report-button {
  margin-top: 2rem;
  margin-bottom: 0;
}

.filters {
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.filters-sort {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.search-small {
  max-width: 20rem;
}

.filter-small {
  height: 2.5rem;
  border: transparent;
  color: #9ea7b0;

  &:hover,
  &:focus {
    background: transparent;
    color: #7c838a;
    border: transparent;
    outline: transparent;
    box-shadow: 0 0 0 0rem;
  }
}

span.paid {
  background: #00cf63;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: white;
  padding: 0.1rem;
  font-weight: bold;
}

.remove-icon-button {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}
</style>
