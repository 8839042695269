<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <h2>Trialists</h2>
      <template>
        <div class="tabs-wrapper">
        <button
          @click="addEditTrialistModel()"
          v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
          class="green-button add-new-programme"
        >
          Add trialist
        </button>

        <template>
          <section
            class="player-profile-wrapper"
            v-if="trialistsData.length > 0"
          >
            <div
              v-for="trialist in trialistsData"
              :value="trialist.id"
              :key="trialist.id"
              class="player-profile-details"
            >
              <div class="user-photo-info">
                <img
                  v-if="
                    trialist.userImage !== '' && trialist.userImage !== null
                  "
                  :src="getImgUrl(trialist.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ trialist.name }}</h2>
                <p>Position: {{ trialist.position }}</p>
                <p>Team: {{ getTeamName(trialist.teamId) }}</p>
                <p>Trial time: {{ trialLength(trialist.trial_length) }}</p>

                <p>Trial score: {{ totalScore(trialist) }} / 30</p>
                <p>Coach: {{ trialist.coach_name }}</p>
                <p :class="`outCome-${outComeText(trialist.outcome)}`">{{ outComeText(trialist.outcome) }}</p>
              </div>

              <div class="player-game-responce">

              <b-button
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="selectedUseChanged(trialist)"
                  ><b-icon icon="pencil" aria-hidden="true"></b-icon
                ></b-button>

                    <b-button
                @click="removeTrialistFromDB(trialist.id)"
                variant="outline-danger"
                v-b-tooltip.hover
                        title="Delete trialist"
                ><b-icon icon="trash" aria-hidden="true"></b-icon></b-button
              >
              </div>
            </div>
          </section>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a trialist to view their information</p>
          </div>
        </template>
        </div>
      </template>
    </div>

    <b-modal
      id="addEditTrialist"
      size="lg"
      :title="editMode === false ? 'Add new trialist' : 'Edit trialist details'"
      :hide-footer="Boolean(true)"
      @hide="resetSelectedCoach()"
    >
      <form class="form" @submit.prevent="addEditCoachDetails()">
        <div class="form__item">
          <label class="form__label" for="name">Name</label>
          <input
            class="form__input"
            type="text"
            v-model="selectedTrialistsData.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="phone">phone</label>
          <input
            type="number"
            v-model="selectedTrialistsData.phone"
            id="phone"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="email">Email</label>
          <input
            type="email"
            v-model="selectedTrialistsData.email"
            id="email"
          />
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="dob">DOB</label>
          <datepicker v-model="selectedTrialistsData.dob" name="dob" id="dob" />
        </div>

        <ImageUploader
          @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
          @populateImageFileDataFromUpload="
            imageUploadObjectDataChanged($event)
          "
          @clearImageDataFromUpload="clearImageDataFromUpload()"
          :imageURL="selectedTrialistsData.userImage"
        />

        <div class="form__item">
          <label class="form__label" for="position">Position</label>
          <select id="position" v-model="selectedTrialistsData.position">
            <option disabled value="">Please select a position</option>
            <option value="Goalkeeper">Goalkeeper</option>
            <option value="Right Full-back (Wingback)">
              Right Full-back (Wingback)
            </option>
            <option value="Left Full-back (Wingback)">
              Left Full-back (Wingback)
            </option>
            <option value="Center-back left">Center-back left</option>
            <option value="Center-back right">Center-back right</option>
            <option value="Defensive Midfielder">Defensive Midfielder</option>
            <option value="Right Midfielder (Winger)">
              Right Midfielder (Winger)
            </option>
            <option value="Left Midfielder (Winger)">
              Left Midfielder (Winger)
            </option>
            <option value="Attacking Midfielder">Attacking Midfielder</option>
            <option value="Center Forward (Striker) left">
              Center Forward (Striker) left
            </option>
            <option value="Center Forward (Striker) right">
              Center Forward (Striker) right
            </option>
            <option value="I played more than one position">
              I played more than one position
            </option>
          </select>
        </div>

        <div class="form__item">
            <label class="form__label" for="coachSelect">Select team</label>
            <select v-model="selectedTrialistsData.teamId" id="coachSelect">
              <option disabled value="">Please select a team</option>
              <option v-for="team in teamData" :value="team.id" :key="team.id">
                {{ team.teamName }}
              </option>
            </select>
          </div>

        <div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="selectedTrialistsData.coach_id" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>

        <div class="form__item">
          <label class="form__label" for="coachSelect"
            >Select trial length</label
          >
          <select
            v-model="selectedTrialistsData.trial_length"
            id="trialLengthSelect"
          >
            <option disabled value="">Please select a trial length</option>
            <option disabled value="6">6 weeks</option>
            <option disabled value="12">12 weeks</option>
          </select>
        </div>

        <div class="form__item uk-form dob">
          <label class="form__label" for="trialStartDate"
            >Trial start date</label
          >
          <datepicker
            v-model="selectedTrialistsData.trial_start_date"
            name="trialStartDate"
            id="trialStartDate"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="preferredFootSelect"
            >Select preferred foot</label
          >
          <select
            v-model="selectedTrialistsData.preferred_foot"
            id="preferredFootSelect"
          >
            <option disabled value="">Please select a preferred foot</option>
            <option disabled value="Right">Right</option>
            <option disabled value="Left">Left</option>
            <option disabled value="Both">Both</option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="attitude">Attitude</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <button type="submit" class="green-button">Add trialist</button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="ViewTrialist"
      size="lg"
      title="Trialist performance review"
      :hide-footer="Boolean(true)"
      @hide="resetSelectedCoach()"
    >
        <div class="two-col-pop">
          <div class="user-photo-info">
            <img
              v-if="
                selectedTrialistsData.userImage !== '' &&
                selectedTrialistsData.userImage !== null
              "
              :src="getImgUrl(selectedTrialistsData.userImage)"
              alt=""
            />
            <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
          </div>
          <div class="user-details-pop">
            <p><span>Name:</span> {{ selectedTrialistsData.name }}</p>
            <p><span>Phone:</span> {{ selectedTrialistsData.phone }}</p>
            <p><span>Email:</span> {{ selectedTrialistsData.email }}</p>
            <p>
              <span>DOB:</span>
              {{ formatDateForHuman(selectedTrialistsData.dob) }}
            </p>
            <p><span>Position:</span> {{ selectedTrialistsData.position }}</p>
            <p>
              <span>Trial length:</span>
              {{ trialLength(selectedTrialistsData.trial_length) }}
            </p>
            <p>
              <span>Trial start date:</span>
              {{ formatDateForHuman(selectedTrialistsData.trial_start_date) }}
            </p>
            <p>
              <span>Preferred foot:</span>
              {{ selectedTrialistsData.preferred_foot }}
            </p>

            <p><span>Team:</span> {{ getTeamName(selectedTrialistsData.teamId) }}</p>

            <p>
              <span>Coach:</span> 
              {{ selectedTrialistsData.coach_name }}</p>
          </div>
        </div>

        <h4>Performance review</h4>

        <div class="form__item">
          <label class="form__label" for="attitude">Attitude</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.attitude"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="skill">Skill</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.skill"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Physique</label>
          <div class="ratingStars">
            <star-rating v-model="selectedTrialistsData.physique"></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Mentality</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.mentality"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Leadership</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.leadership"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="physique">Professionalism</label>
          <div class="ratingStars">
            <star-rating
              v-model="selectedTrialistsData.professionalism"
            ></star-rating>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="comments">Comments</label>
          <wysiwyg v-model="selectedTrialistsData.comments" />
        </div>

        <div class="out-come-buttons">
          <button @click="recordOutcome('1')" class="green-button">Sign</button>
          <button @click="recordOutcome('2')" class="red-button">Reject</button>
          <button @click="recordOutcome('3')" class="yellow-button">Waiting list</button>
        </div>

        <p>When you sign a player, they will be automatically registered with the team they tried out for. Additionally, a default password will be set for them.</p>

        <div class="form__item">
          <button @click="saveTrialistPerformanceReview()" class="green-button">Save trialist</button>
        </div>
    </b-modal>
  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import { USER_POPULATE } from "@/store/actions/user.js";
import { AUTH_SIGNUP } from "@/store/actions/auth";
import { Users } from "@/services/user-service.js";
import { Trialists } from "@/services/trialist-service.js";
import Datepicker from "vuejs-datepicker";
import { Teams } from "@/services/teams-service.js";
import ImageUploader from "../../components/ImageUploader";
import StarRating from "vue-star-rating";
import moment from "moment";

export default {
  name: "Trialists",
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    ImageUploader,
    StarRating,
  },
  data() {
    return {
      loadingStuff: false,
      editMode: false,
      trialistsData: [],
      coachesUsersData: [],
      selectedTab: "contact-tab",
      teamData: [],
      clubTeams: [],
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      selectedTrialistsData: {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
      },
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      registerUser: {
        img: "",
        name: "",
        email: "",
        password: "",
        dob: "",
        position: "",
        phone: "",
        emergency_contact_one: "",
        userType: "User",
        action: "register",
        teamId: 0,
        permission: 0,
        clubId: null,
        coach_id: 0,
        team_module: 1,
        preferred_foot: 0,
        joined_date: "",
        medical: "",
      },
      coachData: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.getUserByIdForHeader(clientId);
    this.getUserById(clientId).then((response) => {
      if (response === true) {
        this.getTeamsByUserId();
        this.getAllTrialists();
      }
    });
  },
  methods: {
    async populateCoachesData() {
      const coachesData = {
        clubId: this.userData.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    register() {
    this.registerUser.userImage = this.selectedTrialistsData.userImage;
    this.registerUser.imageFile = null;
    this.registerUser.fileName = null;
    this.registerUser.name = this.selectedTrialistsData.name;
    this.registerUser.email = this.selectedTrialistsData.email;
    this.registerUser.password = "Pa$$w0rd1";
    this.registerUser.dob = this.selectedTrialistsData.dob;
    this.registerUser.position = this.selectedTrialistsData.position;
    this.registerUser.phone = this.selectedTrialistsData.phone;
    this.registerUser.emergency_contact_one = this.selectedTrialistsData.emergency_contact_one;
    this.registerUser.teamId = this.selectedTrialistsData.teamId;
    this.registerUser.permission = 1;
    this.registerUser.clubId = this.selectedTrialistsData.clubId;
    this.registerUser.preferred_foot = this.selectedTrialistsData.preferred_foot;
    this.registerUser.joined_date = this.selectedTrialistsData.joined_date;
    this.registerUser.age_group = this.selectedTrialistsData.age_group;
    this.registerUser.coachId = this.selectedTrialistsData.coach_id;
    this.registerUser.age_group = this.formatAgeGroupForSQL(this.selectedTrialistsData.dob);
    this.registerUser.emergency_contact_one = this.selectedTrialistsData.phone;

    this.$store
      .dispatch(AUTH_SIGNUP, this.registerUser)
      .then(() => {
        this.$snack.success({
          text:
            "Successfully registered player",
        });
        this.$router.push("/trialists");
      })
      .catch((error) => {
        this.$snack.danger({
          text: error.message,
        });
      });
    },
    getCoachesName(coach_id) {
      let name = "";
      this.coachData.map((coach) => {
        if (coach.id === coach_id) {
          name = coach.name;
        }
      });
      return name;
    },
    outComeActions(value) {
      value == 1 ? this.register() : this.saveTrialistPerformanceReview();
    },
    async populateTeamData() {
      const teamData = {
        clubId: this.userData.clubId,
        action: "populateTeamData",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async removeTrialistFromDB(id) {
      const userData = {
        action: "removeTrialist",
        id: id,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllTrialists();
        this.hideModal("ViewTrialist");
        this.loadingStuff = false;
        this.$bvToast.toast("Trialist removed.", {
            title: "removed",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
      }

    },
    recordOutcome(value) {
      this.selectedTrialistsData.outcome = value;
      this.outComeActions(value);
    },
    outComeText(outCome) {
      switch (outCome) {
        case 0:
          return "Under consideration";
        case 1:
          return "Signed";
        case 2:
          return "Rejected";
        case 3:
          return "Waiting list";
      }
    },
    totalScore(trialist) {
      let values = [
        trialist.attitude,
        trialist.skill,
        trialist.physique,
        trialist.mentality,
        trialist.leadership,
        trialist.professionalism,
      ];

      var totalYears = values.reduce(function (accumulator, value) {
        return accumulator + +value;
      }, 0);

      return totalYears;
    },
    getTeamName(teamId) {
      if (teamId === "") {
        return teamId;
      }
      var selectedTeam = this.clubTeams.filter(function (team) {
        return team.id == teamId;
      });

      return selectedTeam[0].teamName;
    },
    trialLength(number) {
      if (number == "6") {
        return "6 weeks";
      }
      if (number == "12") {
        return "12 weeks";
      }
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    trialistDataconvertStringsToNumbers(trialist) {
      trialist.attitude = parseInt(trialist.attitude);
      trialist.skill = parseInt(trialist.skill);
      trialist.physique = parseInt(trialist.physique);
      trialist.mentality = parseInt(trialist.mentality);
      trialist.leadership = parseInt(trialist.leadership);
      trialist.professionalism = parseInt(trialist.professionalism);

      return trialist;
    },
    selectedUseChanged(trialist) {
      let trialistData = this.trialistDataconvertStringsToNumbers(trialist);
      this.selectedTrialistsData = trialistData;
      this.showModal("ViewTrialist");
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.userHeaderData = responce.data[0];
      }
    },
    addEditCoachDetails() {
      this.edit !== true
        ? this.addTrialistDetailsSubmit()
        : this.editTrialistDetailsSubmit();
    },
    async saveTrialistPerformanceReview() {
      this.loadingStuff = true;
      const userData = {
        action: "updateTrialist",
        ...this.selectedTrialistsData,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      this.getAllTrialists();
      this.hideModal("ViewTrialist");
      this.loadingStuff = false;
      this.$bvToast.toast("Trialist updated.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
    },
    async getAllTrialists() {
      const userData = {
        action: "getTrialists",
        clubId: this.userData.clubId,
      };

      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trialistsData = responce.data;
        this.coachData = await this.populateCoachesData();
        this.teamData = await this.populateTeamData();
      }
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.clubTeams = responce.data;
    },
    async addTrialistDetailsSubmit() {
      this.loadingStuff = true;
      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedTrialistsData.fileName = this.imageFile.imageName;
        this.selectedTrialistsData.imageFile = this.image;
      }
      this.selectedTrialistsData.trial_start_date = this.fotmatDatesForDb(
        this.selectedTrialistsData.dob
      );

      this.selectedTrialistsData.coach_name = this.getCoachesName(this.selectedTrialistsData.coach_id);
      this.selectedTrialistsData.clubId = this.userData.clubId;
      const userData = {
        ...this.selectedTrialistsData,
        action: "addTrialist",
      };
      const responce = await Trialists.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getAllTrialists();
        this.loadingStuff = false;
        this.hideModal("ViewTrialist");
        this.$bvToast.toast("Trialist added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addEditTrialistModel(trialist) {
      if (trialist !== undefined) {
        this.selectedTrialistsData = { ...trialist };
      }
      this.showModal("addEditTrialist");
    },
    resetSelectedCoach() {
      this.selectedTrialistsData = {
        dob: "",
        trial_length: "6",
        trial_start_date: "",
        preferred_foot: "Right",
        email: "",
        name: "",
        position: "",
        attitude: 0,
        skill: 0,
        physique: 0,
        mentality: 0,
        leadership: 0,
        professionalism: 0,
        comments: "",
        outcome: "0",
        clubId: "",
        teamId: "",
      };
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    fotmatDatesForDb(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    formatDatesForSQL(date) {
      return moment(date).format(moment.HTML5_FMT.DATE);
    },
    formatAgeGroupForSQL(dob) {
      const d = new Date(dob);
      return d.getFullYear();
    },
  },
};
</script>
<style lang="scss" scoped>
.two-col-pop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
}

span.vue-star-rating-rating-text {
  font-size: 2rem;
  padding: 0.5rem 1rem 0 1rem;
}

.user-details-pop p span {
  font-weight: bold;
  display: inline-block;
  min-width: 8rem;
}

.out-come-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  button {
    min-width: 9rem;
  }

  .red-button {
    background: #d85c64;
  }

  .yellow-button {
    background: #ffc107;
  }
}

.outCome-Under {
  border: 1px solid #004ca3;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #008afc;
  background: #e8f5ff;
}

.outCome-Signed {
  border: 1px solid #18a300;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #18a300;
  background: #ecffe8;
}

.outCome-Rejected {
  border: 1px solid #d85c64;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #d85c64;
  background: #ffe8ec;
}

.outCome-Waiting {
  border: 1px solid #ffc107;
  padding: 0.5rem!important;
  margin: 0.5rem 0!important;
  color: #ffc107;
  background: #fffbe8;
}
</style>
