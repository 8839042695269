<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">

      <div class="intro-header">
        <div class="header-buttons">
          <div v-if="selectedUserValue !== ''">
            <div class="user-photo-info">
              <img
                v-if="
                  selectedUserValue.userImage !== '' &&
                  selectedUserValue.userImage !== null
                "
                id="user_photo"
                :src="getImgUrl(selectedUserValue.userImage)"
                alt=""
              />
              <img v-else :src="getImgUrl('default-image.png')" alt="" />
              <h1>Coach <br> {{ selectedUserValue.name }}</h1>
            </div>
          </div>
          <h2 v-else>Coaches</h2>
        </div>
      </div>

      <template v-if="selectedUserValue !== ''">
        <ul class="tabs headding-space">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'contact-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'contact-tab')"
              href="javascript:;"
              >Contact information</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'qualifications-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'qualifications-tab')"
              href="javascript:;"
              >Qualifications</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'teams-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'teams-tab')"
              href="javascript:;"
              >Teams</a
            >
          </li>
         <!---<li class="tab-item">
            <a
              :class="
                selectedTab === 'performace-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'performace-tab')"
              href="javascript:;"
              >Performace</a
            >
          </li>-->
        </ul>

        <div class="tabs-wrapper">
          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'performace-tab'"
          ></div>

          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'contact-tab'"
          >
            <div class="games-info">
              <div class="personal-info">
                <div class="user-photo-info">
                  <img
                    v-if="
                      selectedUserValue.coachImage !== '' &&
                      selectedUserValue.coachImage !== null
                    "
                    :src="getImgUrl(selectedUserValue.coachImage)"
                    alt=""
                  />
                  <img v-else :src="getImgUrl('default-image.png')" alt="" />
                </div>
                <div class="item">
                  <span class="label">Name:</span>
                  <span class="value">{{ selectedUserValue.name }}</span>
                </div>
                <div class="item">
                  <span class="label">Email:</span>
                  <span class="value">{{ selectedUserValue.email }}</span>
                </div>
                
                  <div class="item">
                    <span class="label">Phone:</span>
                    <span
                      class="value"
                      >{{ selectedUserValue.phone }}</span>
                  </div>

                <div class="item" v-html="selectedUserValue.details" />
              </div>
            </div>
          </div>
          <div
            class="team-over-veiw-tab tab-content"
            v-if="selectedTab === 'teams-tab'"
          >

          <div class="tab-content" v-if="clubTeams.length > 0">

            <div class="number-of-results">
              <p>Number of teams: {{ clubTeams.length }}</p>
            </div>

            <div
              v-for="(team, index) in clubTeams"
              :value="team.id + index"
              :key="team.id"
              class="team-profile-details"
              @click="setSelectedTeam(team)"
            >
              <div class="user-photo-info">
                <img :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="team-info">
                <h2>{{ team.teamName }}</h2>
                <p>Age group: {{ team.age_group }}</p>
              </div>
              <span
                class="team-form-results"
                v-if="allTeamsGameResultsData.length > 0"
              >
                <span
                  v-for="(pastGame, i) in allTeamsGameResultsData"
                  :key="i"
                >
                  <span
                    v-if="team.id == pastGame.teamId"
                    :class="`result-${checkGameResult(pastGame)}`"
                    >{{ checkGameResult(pastGame) }}</span
                  >
                </span>
              </span>
              <span v-else>No game results</span>
            </div>


          </div>
          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>No teams information</p>
          </div>
        </div>

          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'qualifications-tab'"
          >

          <div class="games-info">

            <div class="form__item"  v-if="selectedcoachesQualifications !== false">
              
              <div class="qualifications-wrapper">

                  <h3>UEFA Qualifications / coaching licences</h3>

                  <p v-if="selectedcoachesQualifications.UEFA_A === 1">UEFA A</p>
                  <p v-if="selectedcoachesQualifications.UEFA_B === 1">UEFA B</p>
                  <p v-if="selectedcoachesQualifications.UEFA_C === 1">UEFA C</p>
                  <p v-if="selectedcoachesQualifications.UEFA_C === 1">UEFA C</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Pro === 1">UEFA Pro</p>
                  <p v-if="selectedcoachesQualifications.youth_A_Elite === 1">UEFA Youth A Elite</p>
                  <p v-if="selectedcoachesQualifications.youth_B_Elite === 1">UEFA Youth B Elite</p>
                  <p v-if="selectedcoachesQualifications.youth_B_Elite === 1">UEFA Goalkeeper A</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Goalkeeper_A === 1">UEFA Goalkeeper A</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Goalkeeper_B === 1">UEFA Goalkeeper B</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Futsal_B === 1">UEFA Futsal B</p>

                  <div v-html="selectedcoachesQualifications.details"></div>
              </div>

                <b-button
                  @click="addEditCoachModel('Edit')"
                  class="main-button"
                  size="lg"
                  variant="outline-warning"
                  >Edit qualifications</b-button
                >

            </div>
            <div v-else>

              <button
              @click="addEditCoachModel('Add')"
              class="green-button add-new-programme"
            >Add qualifications</button>
            </div>
          </div>
        </div>
        </div>
      </template>
      <template v-else>

        <template>
          <div class="tabs-wrapper" v-if="coachesData.length > 0">
          <section class="player-profile-wrapper">
            <div
              v-for="coach in coachesData"
              :value="coach.id"
              :key="coach.id"
              class="player-profile-details"
              @click="selectedUseChanged(coach)"
            >
              <div class="user-photo-info">
                <img
                  v-if="coach.userImage !== '' && coach.userImage !== null"
                  :src="getImgUrl(coach.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ coach.name }}</h2>
                <p>Coach</p>
              </div>
            </div>
          </section>
          </div>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a coach to view their information</p>
          </div>
        </template>
      </template>
    </div>

    <b-modal
      id="addEditCoach"
      size="lg"
      :title="editMode === false ? 'Add Qualifications' : 'Edit Qualifications'"
      :hide-footer="Boolean(true)">

        <div class="form__item">
          <label class="form__label" for="checkBoxes"
            >UEFA Qualifications / coaching licences</label
          >
          <div class="wrapper-checkBoxes">
            <b-form-checkbox
              id="UEFA_A-checkBox"
              v-model="selectedcoachesQualifications.UEFA_A"
              name="UEFA_A-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA A</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_B-checkBox"
              v-model="selectedcoachesQualifications.UEFA_B"
              name="UEFA_B-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA B</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_C-checkBox"
              v-model="selectedcoachesQualifications.UEFA_C"
              name="UEFA_C-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA C</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Pro-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Pro"
              name="UEFA_Pro-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Pro</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="youth_A_Elite-checkBox"
              v-model="selectedcoachesQualifications.youth_A_Elite"
              name="youth_A_Elite-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Youth A Elite</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="youth_B_Elite-checkBox"
              v-model="selectedcoachesQualifications.youth_B_Elite"
              name="youth_B_Elite-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Youth B Elite</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Goalkeeper_A-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Goalkeeper_A"
              name="UEFA_Goalkeeper_A-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Goalkeeper A</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Goalkeeper_B-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Goalkeeper_B"
              name="UEFA_Goalkeeper_B-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Goalkeeper B</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Futsal_B-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Futsal_B"
              name="UEFA_Futsal_B-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Futsal B</span>
            </b-form-checkbox>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="sessionDetails"
            >Coaching experience</label
          >
          <wysiwyg v-model="selectedcoachesQualifications.details" />
        </div>

        <div v-if="editMode === false" class="form__item">
          <button @click="addCoachQualificationsSubmit()" class="green-button">Add Qualifications</button>
        </div>
        <div v-else class="form__item">
          <button @click="editCoachQualificationsSubmit()" class="green-button">Edit Qualifications</button>
        </div>
    </b-modal>
  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Coaches } from "@/services/coaches-service.js";
import { Teams } from "@/services/teams-service.js";
import { Games } from "@/services/games-service.js";
import moment from "moment";

export default {
  name: "Coaches",
  components: { AppTemplate, SpinnerLoader },
  data() {
    return {
      loadingStuff: false,
      editMode: false,
      coachesData: [],
      coachesUsersData: [],
      clubTeams: [],
      allTeamsGameResultsData: [],
      selectedcoachesQualifications: {
        UEFA_A: 0,
        UEFA_B: 0,
        UEFA_C: 0,
        UEFA_Futsal_B: 0,
        UEFA_Goalkeeper_A: 0,
        UEFA_Goalkeeper_B: 0,
        UEFA_Pro: 0,
        details: "",
        youth_A_Elite: 0,
        youth_B_Elite: 0,
      },
      selectedTab: "contact-tab",
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.getUserByIdForHeader(clientId);
    this.getUserById(clientId).then((response) => {
      if (response === true) {
        this.getAllCoaches();
      }
    });
  },
  methods: {
    checkGameResult(game) {
      if (Number(game.home_team_goals) > Number(game.away_team_goals)) {
        return "W";
      }
      if (Number(game.home_team_goals) === Number(game.away_team_goals)) {
        return "D";
      }
      if (Number(game.home_team_goals) < Number(game.away_team_goals)) {
        return "L";
      }
    },
    async getCoachesQualificationsById() {
      const userData = {
        coach_id: this.selectedUserValue.id,
        clubId: this.selectedUserValue.clubId,
        action: "getCoachesQualificationsById",
      };

      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        this.selectedcoachesQualifications = responce.data;
      }
    },
    async getTeamsDataById(teamId) {
      const userData = {
        coach_id: this.selectedUserValue.id,
        action: "getTeamsByCoachid",
      };
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        Array.isArray(responce.data)
          ? (this.clubTeams = responce.data)
          : (this.clubTeams = [responce.data]);
      }

      const allTeamsIds = this.getAllTeamsIdsFromTeamsEvents();
        this.getAllTeamsPastResults(
          this.formatMatchDate(moment().subtract(3, "months")),
          this.formatMatchDate(moment()),
          allTeamsIds
        );
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    selectedUseChanged(coach) {
      this.selectedUserValue = coach;
      this.getTeamsDataById(coach.teamId);
      this.getCoachesQualificationsById();
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.userHeaderData = responce.data[0];
      }
    },
    async getAllCoaches() {
      const userData = {
        action: "getAllCoachesByClubId",
        clubId: this.userData.clubId,
      };

      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.coachesData = responce.data;
      }
    },
    async addCoachQualificationsSubmit() {
      const userData = {
        ...this.selectedcoachesQualifications,
        coach_id: this.selectedUserValue.id,
        clubId: this.selectedUserValue.clubId,
        action: "addCoachQualifications",
      };
      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });

      console.log(responce);

      if (responce.status === 200) {
        this.getCoachesQualificationsById();
        this.loadingStuff = false;
        this.$bvModal.hide("addEditCoach");
        this.$bvToast.toast("Qualifications added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async editCoachQualificationsSubmit() {
      const userData = {
        ...this.selectedcoachesQualifications,
        coach_id: this.selectedUserValue.id,
        clubId: this.selectedUserValue.clubId,
        action: "editCoachesQualifications",
      };

      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });


      if (responce.status === 200) {
        this.getCoachesQualificationsById();
        this.loadingStuff = false;
        this.$bvModal.hide("addEditCoach");
        this.$bvToast.toast("Coach qualifications updated.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }





    },
    async addEditCoachModel(value) {
      if (value === "Edit") {
        this.editMode = true;
      } else {
        this.resetSelectedCoach();
        this.editMode = false;
      }
      this.showModal("addEditCoach");
    },
    resetSelectedCoach() {
      this.selectedcoachesQualifications = {
        UEFA_A: 0,
        UEFA_B: 0,
        UEFA_C: 0,
        UEFA_Futsal_B: 0,
        UEFA_Goalkeeper_A: 0,
        UEFA_Goalkeeper_B: 0,
        UEFA_Pro: 0,
        details: "",
        youth_A_Elite: 0,
        youth_B_Elite: 0,
      };
      this.editMode = false;
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getAllTeamsIdsFromTeamsEvents() {
      return this.clubTeams.map((event) => event.id);
    },
    async getAllTeamsPastResults(
      gameStartDateAndTime,
      gameEndDateAndTime,
      allTeamsIds
    ) {
      const data = {
        action: "getAllTeamsPastResults",
        teamIds: allTeamsIds,
        gameStartDateAndTime: gameStartDateAndTime,
        gameEndDateAndTime: gameEndDateAndTime,
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allTeamsGameResultsData = responce.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.intro-header {
  border: none;
}

.number-of-results {
    width: 100%;
}

.qualifications-wrapper {
  margin-bottom: 2rem;
}

</style>
