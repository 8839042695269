<template>
  <div class="auth-page loading">
    <main>
      <div class="content content--side">
        <header class="codrops-header uk-flex uk-flex-center">
          <img src="../../img/w-logo.svg" alt="" />
          <h1 class="uk-margin-remove uk-text-center">
            The W Academy
            <span>{{ $store.getters.appName }}</span>
          </h1>
        </header>
        <div class="form">
          <p v-if="validation" class="uk-text-danger">
            Invalid Username or Password!
          </p>
          <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input
              class="form__input"
              type="email"
              v-model="email"
              id="email"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="password">Password</label>
            <div class="form__input-wrap">
              <input
                class="form__input"
                type="password"
                v-model="password"
                id="password"
              />
              <p class="form__password-strength" id="strength-output"></p>
            </div>
          </div>
          <div class="form__item form__item--actions">
            <!--<span
              >Don't have an account?
              <router-link class="form__link" to="/register"
                >Register here</router-link
              >
            </span>-->
            <button-spinner ref="loadingButton" @click="login()"
              >Log in</button-spinner
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style>
.login {
  display: flex;
  flex-direction: column;
  width: 300px;
}
</style>

<script>
import img from "./img/login.jpg";
import { AUTH_REQUEST } from "@/store/actions/auth";
import { USER_POPULATE } from "@/store/actions/user";
import { User } from "@/services/user-service.js";
import { Storage } from "@/services/storage";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      action: "login",
      img: img,
      validation: false
    };
  },
  methods: {
    login() {
      this.$refs.loadingButton.startLoading();
      const { email, password, action } = this;
      this.$store
        .dispatch(AUTH_REQUEST, { email, password, action })
        .then(response => {
          this.passwordCheck(response);
        })
        .catch(error => {
          this.$refs.loadingButton.stopLoading();
          this.$snack.danger({
            text: error.message
          });
        });
    },
    passwordCheck(response) {
      if (response === "Invalid Username or Password!") {
        this.validation = true;
         this.$refs.loadingButton.stopLoading();
      } else {
        this.validation = false;
        this.$refs.loadingButton.stopLoading();
        this.poupulateUserData(response[0]);
        this.$router.push("/dashboard").catch(() => {});
      }
    },
    poupulateUserData(userData) {
      this.$store.dispatch(USER_POPULATE, { userData });
      Storage.setJSON("selectedClientId", userData.id);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "./../styles/auth-styles";

.reset-password {
  margin-top:0.5rem;
  display:block;
}
</style>
