<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <template>
      <div v-if="userData.name !== ''" class="uk-container uk-container-large">
        <div class="sessions-week-layout">
          <ul class="tabs">
            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'current-week-tab'
                    ? 'tab-link active'
                    : 'tab-link'
                "
                @click="tabChange('selectedTab', 'current-week-tab')"
                href="javascript:;"
                >Your Apps</a
              >
            </li>

            <li class="tab-item">
              <a
                :class="
                  selectedTab === 'actions-tab' ? 'tab-link active' : 'tab-link'
                "
                @click="tabChange('selectedTab', 'actions-tab')"
                href="javascript:;"
              >
                <span class="number-alert" v-if="playerActionNumber > 0">{{
                  playerActionNumber
                }}</span
                >Your Actions</a
              >
            </li>
          </ul>
          <div class="tabs-wrapper">
            <div
              class="current-week-tab tab-content"
              v-if="selectedTab === 'current-week-tab'"
            >
              <div class="training-session-entry">
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'profile' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/boot.svg" />
                      <h3
                        v-if="
                          userData.userType !== 'Admin' &&
                          userData.userType !== 'Coach'
                        "
                      >
                        Your profile
                      </h3>
                      <h3 v-else>Overview</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="training-session-entry">
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'teams' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/teams-icon.svg" />
                      <h3>Teams information</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                class="training-session-entry"
                v-if="
                  userData.userType === 'Admin'
                "
              >
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'trialists' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/player-change.svg" />
                      <h3>Trialists</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                class="training-session-entry"
                v-if="
                  userData.userType === 'Admin'
                "
              >
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'booking' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/pitch-icon.svg" />
                      <h3>Resources and booking</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                class="training-session-entry"
                v-if="
                  userData.userType === 'Admin'
                "
              >
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'coaches' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/strip.svg" />
                      <h3>Coaches</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                class="training-session-entry"
                v-if="userData.pt_module != '0'"
              >
                <div class="session-content large-app">
                  <router-link
                    v-if="
                      userData.userType === 'Admin' ||
                      userData.userType === 'Coach'
                    "
                    :to="{ name: 'ptHome' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/clock.svg" />
                      <h3>Training plans</h3>
                    </div>
                  </router-link>
                  <router-link
                    v-else
                    :to="{ name: 'ptUserHome' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/clock.svg" />
                      <h3>Personal Training</h3>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                class="training-session-entry"
                v-if="
                  userData.userType === 'Admin'
                "
              >
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'documents' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/documentlib.svg" />
                      <h3>Documents library</h3>
                    </div>
                  </router-link>
                </div>
              </div>
              <div
                class="training-session-entry">
                <div class="session-content large-app">
                  <router-link
                    :to="{ name: 'payments' }"
                    class="uk-navbar-item uk-logo"
                  >
                    <div class="session-data">
                      <img src="../img/payments.svg" />
                      <h3>Payments / subscriptions</h3>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div
              class="actions-tab tab-content"
              v-if="selectedTab === 'actions-tab'"
            >
              <div
                v-if="
                  userData.userType !== 'Admin' || userData.userType === 'Coach'
                "
                class="padding-wrapper"
              >
                <div v-if="playerActionNumber == 0">
                  <div class="empty-state">
                    <img src="../img/emptyStateIllustration.svg" />
                    <p>You have no actions, Good job!</p>
                  </div>
                </div>

                <b-alert
                  v-if="gamePerformanceData.created_date === null"
                  show
                  variant="danger"
                >
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    have not submitted any Game reports - Please submit one now
                    -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>

                <b-alert
                  v-if="trainingPerformanceData.created_date === null"
                  show
                  variant="danger"
                >
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    have not submitted any Training reports - Please submit one
                    now -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>

                <b-alert
                  v-if="checkDateTimeFromNow(gamePerformanceData.created_date)"
                  show
                  variant="danger"
                >
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    last submitted a Game report on
                    {{ formatDate(gamePerformanceData.created_date) }} - Please
                    submit one now -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>

                <b-alert
                  v-if="
                    checkDateTimeFromNow(trainingPerformanceData.created_date)
                  "
                  show
                  variant="danger"
                >
                  <p>
                    <b-icon icon="file-text" aria-hidden="true"></b-icon> You
                    last submitted a Training report on
                    {{ formatDate(trainingPerformanceData.created_date) }} -
                    Please submit one now -
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                  </p>
                </b-alert>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </template>
    <!-- <template v-else>
      <div class="big-message">
        <h2>Please fill out your Player profile to get started.</h2>
        <button class="green-button">Create Player profile </button>
      </div>
    </template>-->
    <b-modal
      class="full-screen-modal"
      id="playerProfile"
      size="lg"
      title="Create your player profile"
      :no-close-on-backdrop="true"
      :hide-footer="Boolean(true)"
    >
      <p>Welcome {{ userData.name }}</p>
      <p>
        To get you fully onboarded. We need you to fill out your Player profile
        / bio and self assessment.
      </p>
      <form class="form" @submit.prevent="createPlayerProfile()">
        <section
          class="player-profile-part-one"
          v-if="playerProfileForm.sectionOne === true"
        >
          <h3>More Details</h3>
          <div class="form__item">
            <label class="form__label" for="imageURL">Upload image</label>
            <input
              accept="image/jpeg|image/jpg|image/heic|.jpeg"
              @change="imageChange($event, 'imageFile', 'image', 'fileOne')"
              type="file"
              class="form__input"
              ref="fileOne"
              id="imageURL"
            />
          </div>
          <div class="form__item">
            <label class="form__label" for="name">Full Name</label>
            <input type="text" v-model="playerProfileData.name" id="name" />
          </div>

          <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input type="email" v-model="playerProfileData.email" id="email" />
          </div>

          <div class="form__item uk-form dob">
            <label class="form__label" for="dob">DOB</label>
            <datepicker v-model="playerProfileData.dob" name="dob" id="dob" />
          </div>

          <div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="playerProfileData.coachId" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Position</label>
            <select id="position" v-model="playerProfileData.position">
              <option disabled value="">Please select a position</option>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Right Full-back (Wingback)">
                Right Full-back (Wingback)
              </option>
              <option value="Left Full-back (Wingback)">
                Left Full-back (Wingback)
              </option>
              <option value="Center-back left">Center-back left</option>
              <option value="Center-back right">Center-back right</option>
              <option value="Defensive Midfielder">Defensive Midfielder</option>
              <option value="Right Midfielder (Winger)">
                Right Midfielder (Winger)
              </option>
              <option value="Left Midfielder (Winger)">
                Left Midfielder (Winger)
              </option>
              <option value="Attacking Midfielder">Attacking Midfielder</option>
              <option value="Center Forward (Striker) left">
                Center Forward (Striker) left
              </option>
              <option value="Center Forward (Striker) right">
                Center Forward (Striker) right
              </option>
            </select>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionTwo === true">
          <h3>Your Profile</h3>
          <div class="form__item">
            <label class="form__label" for="name">Height</label>
            <input type="text" v-model="playerProfileData.height" id="name" />
          </div>

          <div class="form__item">
            <label class="form__label" for="foot-radios">Preferred foot</label>
            <div class="radio-group-flex">
              <b-form-radio
                v-model="playerProfileData.foot"
                name="foot-radios"
                value="Right"
                >Right</b-form-radio
              >
              <b-form-radio
                v-model="playerProfileData.foot"
                name="foot-radios"
                value="Left"
                >Left</b-form-radio
              >
              <b-form-radio
                v-model="playerProfileData.foot"
                name="foot-radios"
                value="Both"
                >Both</b-form-radio
              >
            </div>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionThree === true">
          <p class="more-chat">
            Tell us more about the teams you have played for.
          </p>
          <h3>Playing History / awards, previous tournaments </h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <wysiwyg v-model="playerProfileData.history" />
          </div>
        </section>

        <section v-if="playerProfileForm.sectionFour === true">
          <p class="more-chat">
            Tell us more about any other footballing qualifcations or
            experixances you have had.
          </p>
          <h3>Football Experience / courses</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <wysiwyg v-model="playerProfileData.courses" />
          </div>
        </section>

        <section v-if="playerProfileForm.sectionFive === true">
          <p class="more-chat">
            Tell us about any dietary requierments you have, like allages etc..
          </p>
          <h3>Nutrition requierments</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea
              id="textarea"
              v-model="playerProfileData.nutrition"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionSix === true">
          <p class="more-chat">
            Tell us about what else you like to do in your spare time.
          </p>
          <h3>Interests</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea
              id="textarea"
              v-model="playerProfileData.interests"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionSeven === true">
          <p class="more-chat">We need to ask :-).</p>
          <h3>Discipline reds / yellow / bans</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea
              id="textarea"
              v-model="playerProfileData.discipline"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </section>

        <section v-if="playerProfileForm.sectionEight === true">
          <p class="more-chat">Do you have any football videos or reels?</p>
          <h3>Football Highlight Videos</h3>

          <div class="form__item">
            <label class="form__label" for="name">Information</label>
            <b-form-textarea
              id="textarea"
              v-model="playerProfileData.videos"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </section>

        <div class="buttons-flex">
          <button @click="formSectionsMove('back')" class="green-button">
            <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Back
          </button>

          <button
            v-if="playerProfileForm.sectionEight === true"
            @click="formSectionsMove('next')"
            class="green-button"
          >
            Save
          </button>
          <button v-else @click="formSectionsMove('next')" class="green-button">
            Next <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { CHECK_PROFILE_HAS_DATA, USER_POPULATE } from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Games } from "@/services/games-service.js";
import { Teams } from "@/services/teams-service.js";
import AppTemplate from "../layouts/App";
import SpinnerLoader from "../../components/LoadingSpinning";
import VModal from "vue-js-modal";
import wysiwyg from "vue-wysiwyg";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import moment from "moment";

Vue.use(VModal);
Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
});

export default {
  name: "Dashboard",
  mixins: [extendSeasonEndDateToFullYearMixin],
  components: { AppTemplate, SpinnerLoader, Datepicker },
  data() {
    return {
      gamePerformanceData: {
        created_date: null,
      },
      trainingPerformanceData: {
        created_date: null,
      },
      playerProfileFormSelection: "sectionOne",
      playerProfileForm: {
        sectionOne: true,
        sectionTwo: false,
        sectionThree: false,
        sectionFour: false,
        sectionFive: false,
        sectionSix: false,
        sectionSeven: false,
        sectionEight: false,
      },
      coachData: [],
      playerProfileData: {},
      playerHasProfile: false,
      publicPath: process.env.BASE_URL,
      loadingStuff: false,
      selectedUserValue: "",
      weeklyDate: "",
      userData: {
        name: "",
        userType: "",
      },
      coachesUsersData: [],
      selectedTab: "current-week-tab",
      currentPlayingSeason: null,
      weekSelectedNumber: "",
      playerActionNumber: null,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile"]),
  },
  created() {
    this.loadingStuff = true;
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
        //this.checkPlayerHasProfile();
        if (
          this.userData.userType !== "Admin" &&
          this.userData.userType !== "Coach"
        ) {
          this.getTeamsDataById(this.userData.teamId);
          this.setPlayingSeasion();
        }
        this.loadingStuff = false;
      } else {
        const clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
            if (
              this.userData.userType !== "Admin" &&
              this.userData.userType !== "Coach"
            ) {
              this.getTeamsDataById(this.userData.teamId);
              this.setPlayingSeasion();
            }
            //this.checkPlayerHasProfile();
          }
          this.loadingStuff = false;
        });
      }
    });
  },
  methods: {
    async getTeamsDataById(teamId) {
      const userData = {
        id: teamId,
        action: "getTeamDataById",
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        userData.clubId = this.userData.clubId;
      }
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        localStorage.setItem(
          "currentPlayingseasonStartDate",
          responce.data.season_start_date
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          responce.data.season_end_date
        );
        (this.season_start_date = responce.data.season_start_date),
          (this.season_end_date = responce.data.season_end_date);
        this.getThisSeasonsgamePerformanceReportsForUser();
        this.getTheLastDateSubmittedSeasonsTrainingPerformanceReport();

        return true;
      }
    },
    getNumberOfActionsForUser(data) {
      if (data.created_date === null) {
        return null;
      }
      this.playerActionNumber = 0;
      this.checkDateTimeFromNow(data.created_date) === true
        ? (this.playerActionNumber = this.playerActionNumber + 1)
        : (this.playerActionNumber = 0);
    },
    setPlayingSeasion() {
      if (this.weekSelectedNumber >= 8 && this.weekSelectedNumber < 52) {
        this.currentPlayingSeason = moment().format("YYYY");
      }
      if (this.weekSelectedNumber >= 1 && this.weekSelectedNumber < 8) {
        this.currentPlayingSeason = moment().subtract(1, "year").format("YYYY");
      }
      if (this.weekSelectedNumber == 52) {
        moment().format("M") === "1"
          ? (this.currentPlayingSeason = moment()
              .subtract(1, "year")
              .format("YYYY"))
          : (this.currentPlayingSeason = moment().format("YYYY"));
      }

      localStorage.setItem("currentPlayingSeason", this.currentPlayingSeason);
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.userData.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    resetPlayerProfileForm() {
      this.playerProfileForm = {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
        sectionFour: false,
        sectionFive: false,
        sectionSix: false,
        sectionSeven: false,
        sectionEight: false,
      };
    },
    changePlayerProfileFormSection(section) {
      this.resetPlayerProfileForm();
      this.playerProfileFormSelection = section;
      this.playerProfileForm[section] = true;
    },
    formSectionsMove(type) {
      if (this.playerProfileFormSelection === "sectionOne" && type === "next") {
        this.changePlayerProfileFormSection("sectionTwo");
        return;
      }
      if (this.playerProfileFormSelection === "sectionTwo" && type === "next") {
        this.changePlayerProfileFormSection("sectionThree");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionThree" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionFour");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFour" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionFive");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFive" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionSix");
        return;
      }
      if (this.playerProfileFormSelection === "sectionSix" && type === "next") {
        this.changePlayerProfileFormSection("sectionSeven");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionSeven" &&
        type === "next"
      ) {
        this.changePlayerProfileFormSection("sectionEight");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionEight" &&
        type === "next"
      ) {
        return;
      }

      if (this.playerProfileFormSelection === "sectionTwo" && type === "back") {
        this.changePlayerProfileFormSection("sectionOne");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionThree" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionTwo");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFour" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionThree");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionFive" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionFour");
        return;
      }
      if (this.playerProfileFormSelection === "sectionSix" && type === "back") {
        this.changePlayerProfileFormSection("sectionFive");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionSeven" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionSix");
        return;
      }
      if (
        this.playerProfileFormSelection === "sectionEight" &&
        type === "back"
      ) {
        this.changePlayerProfileFormSection("sectionSeven");
        return;
      }
    },
    async createPlayerProfile() {},
    async checkPlayerHasProfile() {
      if (
        this.userData.playerProfile === true ||
        this.userData.userType === "Admin" || 
        this.userData.userType === 'Coach'
      ) {
        this.playerHasProfile = true;
        this.loadingStuff = false;
      } else {
        this.playerProfileData = {
          name: this.userData.name,
          email: this.userData.email,
          dob: this.userData.dob,
          position: this.userData.position,
        };
        this.coachData = await this.populateCoachesData();
        this.showPlayerProfileModal();
      }
    },
    showPlayerProfileModal() {
      this.loadingStuff = false;
      this.showModal("playerProfile");
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.coachesUsersData = responce.data;
        this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
        localStorage.setItem(
          "coachesUsersData",
          JSON.stringify(this.coachesUsersData)
        );
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    async getThisSeasonsgamePerformanceReportsForUser() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getTheLastDateSubmittedSeasonsgamePerformanceReport",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.gamePerformanceData = responce.data[0];
        this.getNumberOfActionsForUser(this.gamePerformanceData);
      } else {
        this.playerActionNumber = this.playerActionNumber + 1;
      }
    },
    async getTheLastDateSubmittedSeasonsTrainingPerformanceReport() {
      if (this.selectedUserValue === "") {
        this.selectedUserValue = this.userData.id;
      }
      if (localStorage.getItem("currentPlayingSeason") !== null) {
        this.currentPlayingSeason = localStorage.getItem(
          "currentPlayingSeason"
        );
      }
      const userData = {
        user_id: this.selectedUserValue,
        action: "getTheLastDateSubmittedSeasonsTrainingPerformanceReport",
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        year: this.currentPlayingSeason,
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.trainingPerformanceData = responce.data[0];
        this.getNumberOfActionsForUser(this.trainingPerformanceData);
      } else {
        this.playerActionNumber = this.playerActionNumber + 1;
      }
    },
    checkDateTimeFromNow(date) {
      if (date === null) {
        return false;
      }
      let now = new Date();
      let otherDate = new Date(date);
      new Date(now.setHours(0, 0, 0, 0)).toISOString();
      new Date(otherDate.setHours(0, 0, 0, 0)).toISOString();

      const diffTime = Math.abs(
        new Date(now.setHours(0, 0, 0, 0)) -
          new Date(otherDate.setHours(0, 0, 0, 0))
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays > 7 ? true : false;
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    formatDate(date) {
      return moment(moment(date, "YYYY-MM-DD")).format("MMMM Do YYYY");
    },
  },
};
</script>
