/*import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDtQOl0oba690X6tsFz32PxiOzr_Sn09V8",
    authDomain: "chatmessages-fc29c.firebaseapp.com",
    projectId: "chatmessages-fc29c",
    storageBucket: "chatmessages-fc29c.appspot.com",
    messagingSenderId: "602129322649",
    appId: "1:602129322649:web:1d14f26ff34dd54105de08"
};

const app = initializeApp(firebaseConfig);
// init firestore service
const db = getFirestore(app)*/

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
    //Get this from your firebase console
    apiKey: "AIzaSyDtQOl0oba690X6tsFz32PxiOzr_Sn09V8",
    authDomain: "chatmessages-fc29c.firebaseapp.com",
    projectId: "chatmessages-fc29c",
    storageBucket: "chatmessages-fc29c.appspot.com",
    messagingSenderId: "602129322649",
    appId: "1:602129322649:web:1d14f26ff34dd54105de08"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export default db;