<template>
  <div class="space">
    <div class="form__item">
      <label v-if="showHomeTeamsPiches === true" class="form__label" for="selectpitchType">Select Pitch</label>
      <label v-else class="form__label" for="selectpitchType">Add a Pitch</label>
      <select
      v-if="showHomeTeamsPiches === true"
        id="selectpitch"
        v-model="pitch_id"
        @change="pitchChangeCall($event, 'newGameData')"
      >
        <option disabled value="">Please select a pitch</option>
        <option v-for="pitch in pitches" :value="pitch.id" :key="pitch.id">
          {{ pitch.name }}
        </option>
      </select>

      <a v-if="showHomeTeamsPiches === true" @click="defaultPitchInputsCall('newGameData')" href="javascript:;"
        >Or enter new pitch details</a
      >
      <a v-else @click="showHomeTeamsPiches = true"  href="javascript:;">View teams Piches</a>
    </div>
    <div v-if="gameDate !== '' && pitch_id !== ''" class="pitch-avaiability">
      <p>Booked times</p>
      <p
        v-for="game in currentGamesAndTrainingData"
        :value="game.id"
        :key="game.id"
      >
        <span
          class="booked-time"
          v-if="
            game.pitch_id === pitch_id &&
            game.matchDate === formatMatchDate(gameDate)
          "
          >{{ getDayAndDate(game.matchDate) }}
          {{ formatISOStringDateAndTime(game.kickOffTime) }}
          {{ checkEndTime(game.kickOffTime, game.endTime) }}</span
        >
      </p>
    </div>
    <div v-else>
      <p v-if="showHomeTeamsPiches === true">Choose a date and pitch to see pitch avaiablity.</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "PitchSelet",
  data: function () {
    return {
      pitch_id: "",
      showHomeTeamsPiches: true,
    };
  },
  props: {
    pitches: {
      type: Array,
      default: null,
    },
    gameDate: {
      type: [String, Date],
      default: null,
    },
    currentGamesAndTrainingData: {
      type: Array,
      default: null,
    },
  },
  methods: {
    pitchChangeCall(e, gameType) {
      this.$emit("pitchChange", e, gameType);
    },
    defaultPitchInputsCall(gameType) {
      this.showHomeTeamsPiches = false;
      this.$emit("default-pitch-inputs", gameType);
    },
    getDayAndDate(date) {
      return moment(date).format("dddd, Do");
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    checkEndTime(kickOffTime, endTime) {
      return endTime === ""
        ? this.formatISOStringDateAndTime(moment(kickOffTime).add(2, "hours"))
        : this.formatISOStringDateAndTime(endTime);
    },
  },
};
</script>

<style scoped lang="scss">
.pitch-avaiability {
  border: 1px solid #dddddd;
  padding: 1rem;
  overflow-y: scroll;
  background: #fafafa;

  .booked-time {
    border: 1px solid red;
    background: #ffe8e8;
    display: block;
    padding: 0.5rem;
  }
}
</style>
