<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <div class="sessons-header">
        <h1>Payments / subscriptions</h1>
        <b-button
          v-if="selectedTab !== 'subscriptions-tab'"
          class="cart-button"
          size="lg"
          @click="showSidebar = true"
          ><b-icon icon="cart" aria-hidden="true"></b-icon> View cart</b-button
        >
      </div>
      <section class="product-wrapper">
        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'products-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'products-tab')"
              href="javascript:;"
              >Training</a
            >
          </li>

          <li class="tab-item" v-if="userData.userType === 'Admin'">
            <a
              :class="
                selectedTab === 'subscriptions-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'subscriptions-tab')"
              href="javascript:;"
            >
              Subscriptions</a
            >
          </li>
        </ul>

        <div class="checkout-basket">
          <b-sidebar v-model="showSidebar" title="Cart" right shadow>
            <div class="px-3 py-2">
              <div class="product-head">
                <p>Name</p>
                <p>Price</p>
                <p>Amount</p>
                <p></p>
              </div>
              <div
                v-for="(productInBasket, index) in currentProductsInBasket"
                :key="productInBasket.id"
                class="products-in-basket"
              >
                <p>{{ productInBasket.session_name }}</p>
                <p>{{ pounds(productInBasket.productPrice) }}</p>
                <p>{{ productInBasket.booked_numbers }}</p>
                <a
                  href="javascript:;"
                  v-b-tooltip.hover
                  title="Remove product"
                  @click="removeProductFromBasket(index)"
                  >x</a
                >
              </div>
            </div>

            <div class="order-summary">
              <p class="order-summary-title">Order Summary</p>
              <p>
                <span>Total: </span>
                <span>{{ pounds(totalAmount(totalAmoutinBasket)) }}</span>
              </p>

              <b-button
                class="main-button create-academy-report-button"
                @click="productCheckOut()"
                size="lg"
                variant="outline-success"
                id="sidebar-right"
                ><b-icon icon="credit-card" aria-hidden="true"></b-icon> Check
                out</b-button
              >
            </div>
          </b-sidebar>
        </div>
        <div class="tabs-wrapper">
          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'products-tab'"
          >
            <section class="product-wrapper">
              <div
                class="product"
                v-for="product in allStripeProducts"
                :key="product.id"
              >
                <div class="product-details">
                  <div class="description">
                    <h3>{{ product.name }}</h3>
                    <h5 v-if="product.type === 'one_time'">
                      {{ pounds(product.unit_amount) }} per session / ticket
                    </h5>
                    <h5 v-else>{{ pounds(product.unit_amount) }} per month</h5>
                    <p>{{ product.description }}</p>

                    <div class="session-booking">
                      <div
                        class="form__item"
                        v-if="Object.keys(product.metadata).length !== 0"
                      >
                        <label class="form__label" for="coachSelect"
                          >Select session</label
                        >
                        <select
                          id="select-session"
                          v-model="selectedSession"
                          @change="selectSessionChange()"
                        >
                          <option v-for="meta in product.metadata" :key="meta">
                            {{ meta }}
                          </option>
                        </select>
                        <p class="red-warning" v-if="needsValidation === true">
                          Please select a session
                        </p>
                      </div>

                      <div class="form__item">
                        <label class="form__label" for="goals"
                          >Spaces needed</label
                        >
                        <input
                          class="form__input"
                          type="number"
                          id="bookingAomunt"
                          v-model="bookingAomunt"
                        />
                      </div>
                      <div
                        v-if="Object.keys(product.metadata).length !== 0"
                        class="space-information"
                      >
                        <p>Availability</p>
                        <p
                          v-for="(value, key) in getThisProductAmount(
                            product.id,
                            product.metadata
                          )"
                          :key="key"
                        >
                          <span
                            >{{ key }}: <b>{{ value }}</b></span
                          >
                          <span
                            class="spacer"
                            v-if="key === 'sessionOneNumbers'"
                          ></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="Object.keys(product.metadata).length !== 0">
                  <b-button
                    class="main-button create-academy-report-button"
                    @click="addToBasket(product)"
                    v-if="checkIfProductIsSoldOut(product)"
                    size="lg"
                    variant="outline-success"
                    v-b-toggle.sidebar-right
                    ><b-icon icon="cart" aria-hidden="true"></b-icon> Add to
                    basket</b-button
                  >
                  <p v-else class="red-warning">Sold out</p>
                </div>
                <div v-else>
                  <b-button
                    class="main-button create-academy-report-button"
                    @click="addToBasket(product)"
                    size="lg"
                    variant="outline-success"
                    v-b-toggle.sidebar-right
                    ><b-icon icon="cart" aria-hidden="true"></b-icon> Add to
                    basket</b-button
                  >
                </div>
              </div>
            </section>
          </div>

          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'subscriptions-tab'"
          >
            <section class="subs-wrapper">
              <div class="subscriptions-summary-data">
                <div class="total-fees">
                  <h3 v-if="stripeBalanceData.pending.length > 0">
                    Toal payments pending
                    <span class="success-text">{{
                      pounds(stripeBalanceData.pending[0].amount)
                    }}</span>
                  </h3>
                  <p v-if="stripeBalanceData.available.length > 0">
                    <span>Paid and available:</span>
                    {{ pounds(stripeBalanceData.available[0].amount) }}
                  </p>
                </div>
                <div class="active-subs">
                  <h3>
                    Players with no subscriptions
                    <span class="warning-text">{{
                      playersWithoutLiveSubscriptions.length
                    }}</span>
                  </h3>
                </div>
              </div>

              <div class="subscriptions-tabs">
                <ul class="tabs">
                  <li class="tab-item">
                    <a
                      :class="
                        selectedSubscriptionTab === 'payment-tab'
                          ? 'tab-link active'
                          : 'tab-link'
                      "
                      @click="
                        tabChange('selectedSubscriptionTab', 'payment-tab')
                      "
                      href="javascript:;"
                      >Payments</a
                    >
                  </li>
                  <li class="tab-item">
                    <a
                      :class="
                        selectedSubscriptionTab === 'without-subscriptions-tab'
                          ? 'tab-link active'
                          : 'tab-link'
                      "
                      @click="
                        tabChange(
                          'selectedSubscriptionTab',
                          'without-subscriptions-tab'
                        )
                      "
                      href="javascript:;"
                      >Players without subscriptions</a
                    >
                  </li>
                </ul>

                <div
                  class="payment-tab tab-content"
                  v-if="selectedSubscriptionTab === 'payment-tab'"
                >
                  <div v-if="allBookingData.length > 0" class="">
                    <vue-good-table
                      style-class="vgt-table bordered"
                      :columns="columns"
                      :rows="allBookingData"
                      :search-options="{
                        enabled: true,
                        placeholder: 'Search payments',
                      }"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'date'">
                          <span>{{
                            formatDates(props.formattedRow[props.column.field])
                          }}</span>
                        </span>
                        <span v-else-if="props.column.field == 'total_price'">
                          {{ pounds(props.formattedRow[props.column.field]) }}
                        </span>
                        <span v-else-if="props.column.field == 'payment_state'">
                          <span
                            :class="props.formattedRow[props.column.field]"
                            >{{ props.formattedRow[props.column.field] }}</span
                          >
                        </span>
                        <span v-else>
                          <span>{{
                            props.formattedRow[props.column.field]
                          }}</span>
                        </span>
                      </template>
                    </vue-good-table>
                  </div>
                </div>

                <div
                  class="without-subscriptions-tab tab-content"
                  v-if="selectedSubscriptionTab === 'without-subscriptions-tab'"
                >
                  <vue-good-table
                    style-class="vgt-table bordered"
                    :columns="columnsPlayers"
                    :rows="playersWithoutLiveSubscriptions"
                    :search-options="{
                      enabled: true,
                      placeholder: 'Search players',
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'payment_state'">
                        <span class="unpaid">No subscription information</span>
                      </span>
                      <span v-else>
                        <span>{{
                          props.formattedRow[props.column.field]
                        }}</span>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import SpinnerLoader from "../../components/LoadingSpinning";
import { Users } from "@/services/user-service.js";
import { CHECK_PROFILE_HAS_DATA, USER_POPULATE } from "@/store/actions/user.js";
import AppTemplate from "../layouts/App";
import { Payments } from "@/services/stripe-service.js";
import VueGoodTable from "vue-good-table";
import moment from "moment";

Vue.use(VueGoodTable);

export default {
  name: "Payments",
  components: { AppTemplate, SpinnerLoader },
  data() {
    return {
      loadingStuff: false,
      selectedSubscriptionTab: "payment-tab",
      userData: {
        name: "",
        userType: "",
      },
      stripeBalanceData: {
        available: [],
        pending: [],
      },
      coachesUsersData: [],
      playersWithoutLiveSubscriptions: [],
      productAmounts: [],
      allBookingsInTheLastMonthData: [],
      allBookingData: [],
      allStripeProducts: [],
      allStripeProductsPrices: [],
      selectedSession: null,
      bookingAomunt: 1,
      selectedTab: "products-tab",
      selectedUserValue: null,
      currentProductsInBasket: [],
      totalAmoutinBasket: 0,
      needsValidation: false,
      showSidebar: false,
      columns: [
        {
          label: "Session name",
          field: "session_name",
        },
        {
          label: "Player name",
          field: "user_name",
        },
        {
          label: "Billing email",
          field: "email",
        },
        {
          label: "Purchase date",
          field: "date",
        },
        {
          label: "Total paid",
          field: "total_price",
        },
        {
          label: "Payment status",
          field: "payment_state",
        },
      ],
      columnsPlayers: [
        {
          label: "Player name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Payment status",
          field: "payment_state",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  async created() {
    this.loadingStuff = true;
    await this.allStripeProductsCall();
    await this.getallStripeProductsPrices();

    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
        this.getProductAmounts();
      } else {
        const clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
          }
          this.getProductAmounts();
          this.loadingStuff = false;
        });
      }
    });
  },
  methods: {
    checkIfProductIsSoldOut(productObject) {
      let test = 0;
      this.productAmounts.map(function (product) {
        if (productObject.id === product.stripe_product_id) {
          if (product.amount_avaiable !== undefined) {
            test = test + product.amount_avaiable;
          }
        }
      }, 0);
      return test !== 0;
    },
    getThisProductAmount(productId, sessions) {
      let sessionInfo = {
        "session-one": null,
        sessionOneNumbers: 0,
        "session-two": null,
        sessionTwoNumbers: 0,
      };

      this.productAmounts.find((product) => {
        if (product.stripe_product_id === productId) {
          if (sessions["session-one"] !== undefined) {
            sessionInfo["session-one"] = sessions["session-one"];

            if (product["selected_session"] === sessions["session-one"]) {
              sessionInfo.sessionOneNumbers = product.amount_avaiable;
            }
          }

          if (sessions["session-two"] !== undefined) {
            sessionInfo["session-two"] = sessions["session-two"];
            if (product["selected_session"] === sessions["session-two"]) {
              sessionInfo.sessionTwoNumbers = product.amount_avaiable;
            }
          }
        }
      });

      return sessionInfo;
    },
    totalAmount() {
      this.totalAmoutinBasket = this.currentProductsInBasket.reduce(
        (acc, product) => acc + product.productPrice,
        0
      );

      return this.totalAmoutinBasket;
    },
    removeProductFromBasket(index) {
      this.currentProductsInBasket.splice(index, 1);
    },
    async getAllBookingsMadeInlastTwoMonths() {
      this.loadingStuff = true;
      const data = {
        action: "getAllBookingsMadeInlastTwoMonths",
        clubId: this.userData.clubId,
        lastPaymentDate: moment().subtract(2, "months").format("YYYY-MM-DD"),
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allBookingsInTheLastMonthData = responce.data;
        this.checkPlayersPaymentStatus();

        this.loadingStuff = false;
      }
    },
    async getAllUsersForClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getUserByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.coachesUsersData = responce.data;
        this.getAllBookingsMadeInlastTwoMonths();
      }
    },
    checkPlayersPaymentStatus() {
      this.playersWithoutLiveSubscriptions = this.coachesUsersData.filter(
        (player) => {
          return !this.allBookingData.some(
            (booking) => booking.user_id === player.id
          );
        }
      );
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    productObject(productDetails) {
      return {
        stripe_price_id: productDetails.default_price,
        session_name: productDetails.name,
        productDescription: productDetails.description,
        productPrice: productDetails.unit_amount,
        stripe_product_id: productDetails.id,
        selectedSession: this.selectedSession,
        session_description: productDetails.description,
        booked_numbers: this.bookingAomunt,
        clubId: this.userData.clubId,
        date: moment().format("YYYY-MM-DD"),
        user_id: this.userData.id,
        user_name: this.userData.name,
        email: this.userData.email,
        payment_state: "unpaid",
        booking_status: "open",
        type: productDetails.type,
        hasSessions:
          Object.keys(productDetails.metadata).length === 0 ? false : true,
      };
    },
    addToBasket(productDetails) {
      const productData = this.productObject(productDetails);

      if (Object.keys(productDetails.metadata).length > 0) {
        productData.selectedSession !== null
          ? this.addProductToShoppingBasket(productData)
          : (this.needsValidation = true);
      } else {
        this.addProductToShoppingBasket(productData);
      }
    },
    addProductToShoppingBasket(productData) {
      this.currentProductsInBasket.push(productData);
      this.needsValidation = false;
      this.selectedSession = null;
      this.showSidebar = true;
    },
    selectSessionChange() {
      this.selectedSession !== undefined
        ? (this.needsValidation = false)
        : (this.needsValidation = true);
    },
    async productCheckOut() {
      const data = {
        action: "productCheckOut",
        products: this.currentProductsInBasket,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      window.location.href = responce.data;
    },
    async allStripeProductsCall() {
      const data = {
        action: "getallStripeProducts",
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.data) {
        this.allStripeProducts = responce.data.data;
      }
    },
    async getallStripeProductsPrices() {
      const data = {
        action: "getallStripeProductsPrices",
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.data) {
        this.allStripeProductsPrices = responce.data.data;
        if (this.allStripeProducts.length > 0) {
          const results = this.addPriceToStripeProducts();
          this.allStripeProducts = results.filter(function (product) {
            return product !== undefined;
          });
        }
        this.loadingStuff = false;
      }
    },
    async getProductAmounts() {
      const data = {
        action: "getProductAmounts",
        clubId: this.userData.clubId,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.productAmounts = responce.data;
      }
    },
    async getAllBookingData() {
      const data = {
        action: "getAllBookings",
        clubId: this.userData.clubId,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allBookingData = responce.data;
        this.loadingStuff = false;
      }
    },
    async retrieveBalance() {
      const data = {
        action: "retrieveBalance",
        clubId: this.userData.clubId,
      };

      const responce = await Payments.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.stripeBalanceData = responce.data;
      }
    },
    addPriceToStripeProducts() {
      return this.allStripeProducts.map((product, index) => {
        if (product.default_price === this.allStripeProductsPrices[index].id) {
          return {
            ...product,
            unit_amount: this.allStripeProductsPrices[index].unit_amount,
            type: this.allStripeProductsPrices[index].type,
          };
        }
      });
    },
    pounds(amount) {
      const numberWithRemovedZeros = amount / 100;
      if (amount !== undefined) {
        return Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: "GBP",
        }).format(numberWithRemovedZeros);
      }
    },
    formatDates(date) {
      return moment(date).format("DD MMM YYYY");
    },
    tabChange(tabValue, selectedTabValue) {
      if (selectedTabValue === "subscriptions-tab") {
        this.loadingStuff = true;
        this.getAllBookingData();
        this.retrieveBalance();
        this.getAllUsersForClubId();
      }
      this[tabValue] = selectedTabValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.sessons-header {
  margin-bottom: 2rem;
  display: flex;
  gap: 2rem;
}

.products-in-basket,
.product-head {
  font-size: 0.9rem;
}

.product-head {
  display: flex;
  gap: 1rem;
  background: #f2f5f7;
  padding: 1rem 0 0 1rem;

  p {
    width: 30%;
  }
}

.products-in-basket {
  display: flex;
  gap: 1rem;
  padding: 1rem 0 0 1rem;

  p {
    width: 30%;
  }
}

.order-summary {
  margin: 2rem;
  font-weight: bold;

  .order-summary-title {
    border-bottom: 1px solid;
  }
}

.cart-button {
  background: transparent;
  color: #06569e;
  border: 1px solid #06569e;

  &:focus,
  &:hover {
    box-shadow: none;
  }
}

span.paid {
  background: #00cf63;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: white;
  padding: 0.1rem;
  font-weight: bold;
}

.space-information {
  p {
    margin: 0;
  }
}

.product-wrapper {
  display: flex;
  flex-wrap: wrap;

  .product {
    border: 1px solid #a9b3bc;
    padding: 1rem;
    margin: 0rem 1rem 1rem 0;
    width: 100%;
    max-width: 40rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #026ec8;
    }
  }

  .session-booking {
    padding: 1rem 0;

    .form__item {
      label {
        display: block;
      }

      input {
        padding: 0.5rem;
      }
    }
  }
}

.spacer {
  display: block;
  margin-bottom: 1rem;
}

.subs-wrapper {
  padding: 2rem;

  .subscriptions-summary-data {
    .total-fees,
    .active-subs {
      background: #f2f5f7;
      padding: 2rem;

      h3 {
        font-size: 0.9rem;

        span {
          display: block;
          font-size: 2.5rem;
        }
      }

      p {
        margin: 0;

        span {
          display: inline-block;
        }
      }
    }
  }
}


.warning-text {
          color: #d85c64;
        }

        .success-text {
          color: #00cf63;
        }

.subscriptions-tabs {
  margin-top: 2rem;

  .tab-content {
    padding: 1rem 0;
  }
}

span.unpaid {
  background: #d85c64;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: white;
  padding: 0.1rem 1rem;
  font-weight: bold;
}

@media screen and (min-width: 480px) {
}

@media screen and (min-width: 720px) {
}

@media screen and (min-width: 960px) {
  .product-wrapper {
    padding: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .subs-wrapper {
    padding: 2rem;

    .subscriptions-summary-data {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      .total-fees,
      .active-subs {
        background: #f2f5f7;
        padding: 2rem;

        h3 {
          font-size: 1rem;
        }

        p {
          margin: 0;

          span {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
