<template>
  <div class="auth-page">
    <main>
      <div v-if="clubId !== null" class="content content--side">
        <header class="codrops-header uk-flex uk-flex-center">
          <a class="header-wrapper" href="/login"
            ><img src="../../img/w-logo.svg" alt=""
          /></a>
          <h1 class="uk-margin-remove uk-text-center">
            The W Academy
            <span>{{ $store.getters.appName }}</span>
          </h1>
          <p class="">Please fill in the form below to get started.</p>
        </header>
        <div class="form">
          <div class="form__item">
            <label class="form__label" for="name">Full Name</label>
            <input
              :class="
                validationValues.name.state === true
                  ? 'danger-outline form__input'
                  : 'form__input'
              "
              @change="checkChangeVlaidation(name, 'name')"
              type="text"
              v-model="name"
              id="name"
            />
            <p
              class="invalid-feedback"
              v-if="validationValues.name.state === true"
            >
              {{ validationValues.name.text }}
            </p>
          </div>
          <div class="form__item">
            <label class="form__label" for="email">Email Address</label>
            <input
              :class="
                validationValues.email.state === true
                  ? 'danger-outline form__input'
                  : 'form__input'
              "
              @change="checkChangeVlaidation(email, 'email')"
              type="email"
              v-model="email"
              id="email"
            />
            <p
              class="invalid-feedback"
              v-if="validationValues.email.state === true"
            >
              {{ validationValues.email.text }}
            </p>
          </div>
          <div class="form__item">
            <label class="form__label" for="password">Password</label>
            <div class="form__input-wrap">
              <input
                :class="
                  validationValues.password.state === true
                    ? 'danger-outline form__input'
                    : 'form__input'
                "
                @change="checkChangeVlaidation(password, 'password')"
                type="password"
                v-model="password"
                id="password"
              />
              <p
                class="invalid-feedback"
                v-if="validationValues.password.state === true"
              >
                {{ validationValues.password.text }}
              </p>
              <p class="form__password-strength" id="strength-output"></p>
            </div>
          </div>
          <div class="form__item uk-form dob">
            <label class="form__label" for="dob">DOB</label>
            <datepicker
              v-model="dob"
              name="dob"
              id="dob"
              @input="checkChangeVlaidation(dob, 'dob')"
            />
            <p
              class="invalid-feedback"
              v-if="validationValues.dob.state === true"
            >
              {{ validationValues.dob.text }}
            </p>
          </div>

          <div class="form__item uk-form dob">
            <label class="form__label" for="joined_date">Date joined</label>
            <datepicker
              v-model="joined_date"
              name="joined_date"
              id="joined_date"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="coachSelect">Select coach</label>
            <select v-model="coachId" id="coachSelect">
              <option disabled value="">Please select a coach</option>
              <option
                v-for="coach in coachData"
                :value="coach.id"
                :key="coach.id"
              >
                {{ coach.name }}
              </option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Position</label>
            <select id="position" v-model="position">
              <option disabled value="">Please select a position</option>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Right Full-back (Wingback)">
                Right Full-back (Wingback)
              </option>
              <option value="Left Full-back (Wingback)">
                Left Full-back (Wingback)
              </option>
              <option value="Center-back left">Center-back left</option>
              <option value="Center-back right">Center-back right</option>
              <option value="Defensive Midfielder">Defensive Midfielder</option>
              <option value="Right Midfielder (Winger)">
                Right Midfielder (Winger)
              </option>
              <option value="Left Midfielder (Winger)">
                Left Midfielder (Winger)
              </option>
              <option value="Attacking Midfielder">Attacking Midfielder</option>
              <option value="Center Forward (Striker) left">
                Center Forward (Striker) left
              </option>
              <option value="Center Forward (Striker) right">
                Center Forward (Striker) right
              </option>
            </select>
          </div>
          <div class="form__item">
            <label class="form__label" for="phone">Phone</label>
            <input
              class="form__input"
              type="number"
              v-model="phone"
              id="phone"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="emergency_contact_one"
              >Emergency Contact</label
            >
            <input
              class="form__input"
              type="text"
              v-model="emergency_contact_one"
              id="emergency_contact_one"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="coachSelect">Select team</label>
            <select v-model="teamId" id="coachSelect">
              <option disabled value="">Please select a team</option>
              <option v-for="team in teamData" :value="team.id" :key="team.id">
                {{ team.teamName }}
              </option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Preferred foot</label>
            <select id="position" v-model="preferred_foot">
              <option disabled value="">Please select a Preferred foot</option>
              <option value="0">Right</option>
              <option value="1">Left</option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="position">Permission</label>
            <b-form-checkbox
              id="checkbox-1"
              v-model="permission"
              name="checkbox-1"
    >
    Permission to take photographs 
    </b-form-checkbox>
            </div>

            <div class="form__item">
            <label class="form__label" for="position">Medical information</label>

            <b-form-textarea
              id="textarea"
              v-model="medical"
              placeholder=""
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            </div>


          <ImageUploader
            @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
            @populateImageFileDataFromUpload="
              imageUploadObjectDataChanged($event)
            "
            @clearImageDataFromUpload="clearImageDataFromUpload()"
            :imageURL="userImage"
          />

          <div
            class="uk-flex uk-flex-center uk-flex-middle uk-margin-medium-top uk-visible@s"
          >
            <span uk-icon="icon: info; ratio: 3;"></span>
            <span class="uk-margin-left">
              <small
                >We strongly recommend that you use a secure password.</small
              >
            </span>
          </div>
          <div class="form__item form__item--actions">
            <span
              >Already have an account?
              <router-link class="form__link" to="/login"
                >Login here</router-link
              >
            </span>
            <button-spinner ref="loadingButton" @click="register()"
              >Register</button-spinner
            >
          </div>
        </div>
      </div>
      <div v-else class="content content--side">
        <h1>Looks like your URL is wrong. Check it and try again.</h1>
      </div>
    </main>
  </div>
</template>

<script>
import ImageUploader from "../../../components/ImageUploader";
import Datepicker from "vuejs-datepicker";
import img from "./img/register.jpg";
import { AUTH_SIGNUP } from "@/store/actions/auth";
import { Users } from "@/services/user-service.js";
import { Teams } from "@/services/teams-service.js";
import moment from "moment";
import zxcvbn from "./zxcvbn.js";
export default {
  components: {
    Datepicker,
    ImageUploader,
  },
  data() {
    return {
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: "Please add a name please!",
        },
        email: {
          state: false,
          text: "Please add an email address",
        },
        password: {
          state: false,
          text: "Please add password please!",
        },
        dob: {
          state: false,
          text: "Please add a date of birth please!",
        },
      },
      coachData: [],
      teamData: [],
      img: img,
      name: "",
      email: "",
      password: "",
      dob: "",
      position: "",
      phone: "",
      emergency_contact_one: "",
      userType: "User",
      action: "register",
      teamId: 0,
      permission: 0,
      clubId: null,
      coachId: 0,
      team_module: 1,
      preferred_foot: 0,
      joined_date: "",
      medical: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      imageFile: null,
      imageURL: "",
      userImage: "",
    };
  },
  async created() {
    if ((await this.checkTeamIdexists()) === true) {
      this.clubId = this.$route.params.clubId;
      this.coachData = await this.populateCoachesData();
      this.coachId = this.coachData[0].id;
      this.teamData = await this.populateTeamData();
      this.teamId = this.teamData[0].id;
    }
  },
  methods: {
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    async populateTeamData() {
      const teamData = {
        clubId: this.clubId,
        action: "populateTeamData",
      };

      const responce = await Teams.find(teamData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async populateCoachesData() {
      const coachesData = {
        clubId: this.clubId,
        action: "populateCoachesData",
      };

      const responce = await Users.find(coachesData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : [];
    },
    async checkTeamIdexists() {
      const userData = {
        clubId: this.$route.params.clubId,
        action: "checkTeamIdexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? true : false;
    },
    async getTeamsById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    async checkIfEmailExists(value) {
      const userData = {
        email: value,
        action: "checkEmailexists",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.validationValues.email.state = true;
        this.validationValues.email.text =
          "Email address aready exits, please change it";
      } else {
        this.validationValues.email.state = false;
        this.validationValues.email.text = "Please add an email address";
      }
    },
    checkChangeVlaidation(value, elementName) {
      if (elementName === "email") {
        if (value !== "") {
          this.checkIfEmailExists(value);
        } else {
          this.validationValues.email.state = false;
          this.validationValues.email.text = "Please add an email address";
        }
        const validated = this.requiredEmailValidation(value);
        this.validationValues[elementName].state = validated !== true;
      } else {
        JSON.stringify(value).length > 0
          ? (this.validationValues[elementName].state = false)
          : (this.validationValues[elementName].state = true);
      }
    },
    setValidationMessage() {
      if (this.needsValidation.length === 0) {
        this.validationMessage = null;
        this.needsValidation = [];
      } else {
        this.validationMessage =
          "Please fix the validation errors before you can proceed.";
        this.isLoading = false;
      }
    },
    reCheckValidation() {
      let validation = this.needsValidation.length === 0;
      validation = this.requiredEmailValidation(this.email);
      this.setValidationMessage();
      return validation;
    },
    populateValidationArray(validationData) {
      return validationData.map((validation) => {
        return validation === true;
      });
    },
    filterValidationResults(validationResults) {
      return validationResults.filter((result) => {
        return result === true;
      });
    },
    togglevalaidationErrors(name, validationState) {
      this.validationValues[name].state = validationState;
    },
    requiredValidation(value) {
      return !value || value.length === 0;
    },
    requiredEmailValidation(value) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return validRegex.test(value);
    },
    setUpValidation(validationValue, validationName) {
      let validationstate = false;
      if (validationName === "email") {
        validationstate = !this.requiredEmailValidation(validationValue);
      } else {
        validationstate = this.requiredValidation(validationValue);
      }
      this.togglevalaidationErrors(validationName, validationstate);
      return validationstate;
    },
    validationCheck() {
      let validationData = [];
      this.needsValidation = [];
      validationData.push(this.setUpValidation(this.name, "name"));
      validationData.push(this.setUpValidation(this.email, "email"));
      validationData.push(this.setUpValidation(this.password, "password"));
      validationData.push(this.setUpValidation(this.dob, "dob"));
      let validationResults = this.populateValidationArray(validationData);
      this.needsValidation = this.filterValidationResults(validationResults);
      return this.reCheckValidation();
    },
    register() {
      if (this.validationCheck() === true) {
        this.$refs.loadingButton.startLoading();
        const dob = this.formatDatesForSQL(this.dob);
        const joined_date = this.formatDatesForSQL(this.joined_date);
        const age_group = this.formatAgeGroupForSQL(dob);
        const {
          name,
          email,
          password,
          position,
          phone,
          userType,
          action,
          coachId,
          teamId,
          clubId,
          emergency_contact_one,
          team_module,
          permission,
          medical,
          preferred_foot,
        } = this;

        if (this.image.imageFile !== null && this.imageFile !== null) {
          this.fileName = this.imageFile.imageName;
          this.imageFile = this.image;
        }

        this.$store
          .dispatch(AUTH_SIGNUP, {
            name,
            email,
            password,
            dob,
            position,
            phone,
            userType,
            action,
            imageFile: this.imageFile,
            fileName: this.fileName,
            coachId,
            teamId,
            clubId,
            age_group,
            emergency_contact_one,
            team_module,
            permission,
            joined_date,
            medical,
            preferred_foot,
          })
          .then(() => {
            this.$refs.loadingButton.stopLoading();
            this.$snack.success({
              text:
                "Successfully registered to " +
                this.$store.getters.appName +
                ". Please log in to continue.",
            });
            this.$router.push("/login");
          })
          .catch((error) => {
            this.$refs.loadingButton.stopLoading();
            this.$snack.danger({
              text: error.message,
            });
          });
      }
    },
    formatAgeGroupForSQL(dob) {
      const d = new Date(dob);
      return d.getFullYear();
    },
    formatDatesForSQL(date) {
      return moment(date).format(moment.HTML5_FMT.DATE);
    },
    createObjectURLCheck(file, imageFileName, imageNumber) {
      if (file.type === "image/heic") {
        this.convertHeicToJpg(file, imageFileName, imageNumber);
      }
      "srcObject" in this
        ? (this.srcObject = file)
        : (this.image.path = URL.createObjectURL(file));
    },
    imageChange(event, imageFileName, imageNumber, inputRef) {
      const file = event.currentTarget.files[0];
      this.createObjectURLCheck(file, imageFileName, imageNumber);
      this.image.file = file;
      this.image.imageName = file.name;

      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.imageFile = event.target.result;
      };
    },
    getPath(p, imageNumber) {
      if (p === undefined) {
        return;
      }
      return this.checkTheimageForBlob(p, imageNumber);
    },
    checkforDotAtTheStartOfUrl(p) {
      return p.startsWith(".") ? p.substring(1) : p;
    },
    checkTheimageForBlob(p, imageNumber) {
      if (p !== null) {
        var URL = this.checkforDotAtTheStartOfUrl(p);
      }
      switch (typeof URL) {
        case "string":
          URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
          return URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
        case "object":
          return this[imageNumber].path;
      }
    },
    generateOrgId(teamName) {
      const firstPart = teamName.replace(/\s+/g, "-").toLowerCase();
      const lastPart = Math.random().toString(36).substr(2, 9);
      return firstPart + lastPart;
    },
  },
  mounted() {
    const passwordInput = document.querySelector("#password");
    const passwordFeedback = document.querySelector("#strength-output");
    const strengthStr = {
      0: "Worst",
      1: "Bad",
      2: "Weak",
      3: "Good",
      4: "Strong",
    };

    if (passwordInput !== null) {
      passwordInput.addEventListener("input", () => {
        const val = passwordInput.value;
        const result = zxcvbn(val);
        // We want to reveal the image as the password gets stronger. Since the zxcvbn.score has
        // only 5 different values (0-4) we will use the zxcvbn.guesses_log10 output.
        // The guesses_log10 will be >= 11 when the password is considered strong,
        // so we want to map a factor of 1 (all pixelated) to 100 (clear image) to
        // a value of 0 to 11 of guesses_log10.
        // This result will be used in the render function.
        // pxFactor = (99 / 11) * Math.min(11, Math.round(result.guesses_log10)) + 1;

        // so we see most of the time pixels rather than approaching a clear image sooner..
        //if (pxFactor != 1 && pxFactor != 100) {
        //  pxFactor -= (pxFactor / 100) * 50;
        // }

        passwordFeedback.innerHTML =
          val !== "" ? `Password strength: ${strengthStr[result.score]}` : "";
        //render();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./../styles/auth-styles";

a.header-wrapper {
  text-decoration: none;
  cursor: pointer;
  header {
    h1,
    p {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
