<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">
      <Calendar />
    </div>
  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import Calendar from "../../components/Calendar";

export default {
  name: "Booking",
  components: { AppTemplate, SpinnerLoader, Calendar },
  data() {
    return {
      loadingStuff: false,
    };
  },
};
</script>
<style lang="scss" scoped></style>
