import axios from "axios";
import { settings } from "@/settings";

let API_SERVER = null;

if (window.location.href.indexOf("8080") > -1) {
  API_SERVER = "http://www.localhost:8888/";
} else {
  API_SERVER = settings.API_SERVER;
}

export const api_routes = {
  user: {
    login: API_SERVER + "back-end-endpoint-login.php",
    signup: API_SERVER + "back-end-endpoint-register.php",
    userEndPoint: API_SERVER + "back-end-endpoint-user.php"
  }
};

export const apiCall = ({ url, method, ...args }) =>
  new Promise((resolve, reject) => {
    // let token = localStorage.getItem("user-token") || "hellloo";

    // if (token)
    //   axios.defaults.headers.common["Authorization"] = "Bearer " + "token";

    try {
      axios({
        method: method || "get",
        url: url,
        crossDomain: true,
        ...args
      })
        .then(resp => {
          console.log("resp");
          console.log(resp);
          resolve(resp.data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    } catch (err) {
      reject(new Error(err));
    }
  });
