<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div v-if="userData.name !== ''" class="uk-container uk-container-large">
      <!--<ul class="breadcrumb">
        <li>
          <router-link :to="{ name: 'home' }"
            ><b-icon icon="house-door" aria-hidden="true"></b-icon
          ></router-link>
        </li>
        &#8594;
        <li>
          <router-link :to="{ name: 'ptHome' }">PT Dashboard</router-link>
        </li>
        &#8594;
        <li>Document library</li>
      </ul>-->

      <h1>Document library</h1>
      <div class="sessions-week-layout">
        <b-button
          class="main-button create-academy-report-button"
          size="lg"
          variant="outline-success"
          @click="showModal('documentModal')"
          ><b-icon icon="file-text" aria-hidden="true"></b-icon> Create
          document</b-button
        >

        <div class="sorting-wrapper">
          <select class="filter-by">
            <option :value="null">View documents by</option>
            <option>All</option>
            <option>Team</option>
            <option>Player</option>
          </select>
          <b-input-group size="sm" class="mb-2 autocomplete search">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search documents"
              @input="lookUpSearch($event)"
            ></b-form-input>
          </b-input-group>
        </div>
        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'current-week-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'current-week-tab')"
              href="javascript:;"
              >Documents</a
            >
          </li>
        </ul>
        <div class="tabs-wrapper">
          <div
            class="current-week-tab documents-tab tab-content"
            v-if="selectedTab === 'current-week-tab'"
          >
            <div
              class="document"
              v-for="document in usersDocuments"
              :key="document.id"
            >
              <img src="../img/documentlib.svg" />
              <h3>{{ document.name }}</h3>

              <div class="doc-buttons">
                <b-icon
                  @click="openDocument(document)"
                  icon="eye"
                  aria-hidden="true"
                ></b-icon>
                <b-icon
                  @click="editDocument(document)"
                  icon="pencil"
                  aria-hidden="true"
                ></b-icon>
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <b-modal id="documentModal" size="xl" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>New document</h3>

        <form class="form">
          <div class="form__item">
            <label class="form__label" for="title">Title</label>
            <input
              class="form__input"
              type="text"
              id="title"
              v-model="documentData.name"
            />
          </div>

          <wysiwyg v-model="documentData.text" />

          <b-form-checkbox
            id="checkbox-signature"
            v-model="documentData.signature"
            name="checkbox-signature"
          >
            Signature required
          </b-form-checkbox>

          <h4>Document access</h4>

          <div class="form__item">
            <label class="form__label" for="select_season">Team</label>
            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="lookUpSearch($event, 'filterdTeamsData', 'clubTeams')"
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdTeamsData.length > 0"
                >
                  <li
                    v-for="teamData in filterdTeamsData"
                    :key="teamData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Teams',
                          teamData,
                          'documentData'
                        )
                      "
                    >
                      {{ teamData.teamName }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season"
              >Player / Coach</label
            >

            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="
                    lookUpSearch(
                      $event,
                      'filterdCoachesUsersData',
                      'coachesUsersData'
                    )
                  "
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdCoachesUsersData.length > 0"
                >
                  <li
                    v-for="userData in filterdCoachesUsersData"
                    :key="userData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Users',
                          userData,
                          'documentData'
                        )
                      "
                    >
                      {{ userData.name }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season">Access</label>
            <div class="access-pool">
              <p
                v-for="accessData in documentData.approvedAccessList.users"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Users')"
              >
                {{ accessData.name }} <span>x</span>
              </p>
              <p
                v-for="accessData in documentData.approvedAccessList.teams"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Teams')"
              >
                {{ accessData.teamName }} <span>x</span>
              </p>
            </div>
          </div>
        </form>

        <div class="buttons-row">
          <b-button
            size="lg"
            variant="outline-success"
            @click="saveNewDocument()"
          >
            Save</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal id="documentViewModal" size="xl" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>{{ selectedDocumentData.name }}</h3>

        <div v-html="selectedDocumentData.text"></div>
      </div>
    </b-modal>

    <b-modal id="documentEditModal" size="xl" :hide-footer="Boolean(true)">
      <div class="performance-target-modal-content">
        <h3>Edit document</h3>

        <form class="form">
          <div class="form__item">
            <label class="form__label" for="title">Title</label>
            <input
              class="form__input"
              type="text"
              id="title"
              v-model="selectedDocumentData.name"
            />
          </div>

          <wysiwyg v-model="selectedDocumentData.text" />

          <b-form-checkbox
            id="checkbox-signature"
            v-model="selectedDocumentData.signature"
            name="checkbox-signature"
          >
            Signature required
          </b-form-checkbox>

          <h4>Document access</h4>

          <div class="form__item">
            <label class="form__label" for="select_season">Team</label>
            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="lookUpSearch($event, 'filterdTeamsData', 'clubTeams')"
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdTeamsData.length > 0"
                >
                  <li
                    v-for="teamData in filterdTeamsData"
                    :key="teamData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Teams',
                          teamData,
                          'selectedDocumentData'
                        )
                      "
                    >
                      {{ teamData.teamName }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season"
              >Player / Coach</label
            >

            <div class="select-user">
              <b-input-group size="sm" class="mb-2 autocomplete">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="search"
                  placeholder="Search players / coaches"
                  @input="
                    lookUpSearch(
                      $event,
                      'filterdCoachesUsersData',
                      'coachesUsersData'
                    )
                  "
                ></b-form-input>
                <ul
                  class="autocomplete-results"
                  v-if="filterdCoachesUsersData.length > 0"
                >
                  <li
                    v-for="userData in filterdCoachesUsersData"
                    :key="userData.id"
                    class="autocomplete-result"
                  >
                    <span
                      @click="
                        addUserTeamToApprovedAccessList(
                          'Users',
                          userData,
                          'selectedDocumentData'
                        )
                      "
                    >
                      {{ userData.name }}</span
                    >
                  </li>
                </ul>
              </b-input-group>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="select_season">Access</label>
            <div class="access-pool">
              <p
                v-for="accessData in selectedDocumentData.approvedAccessList
                  .users"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Users')"
              >
                {{ accessData.name }} <span>x</span>
              </p>
              <p
                v-for="accessData in selectedDocumentData.approvedAccessList
                  .teams"
                :key="accessData.id"
                class="has-access"
                @click="removeAccess(accessData.id, 'Teams')"
              >
                {{ accessData.teamName }} <span>x</span>
              </p>
            </div>
          </div>
        </form>

        <div class="buttons-row">
          <b-button
            size="lg"
            variant="outline-success"
            @click="saveEditedDocument(selectedDocumentData)"
          >
            Save</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Teams } from "@/services/teams-service.js";
import { Documents } from "@/services/documents-service.js";
import { TrainingPlan } from "@/services/training-plan-service.js";
import AppTemplate from "../layouts/App";
import UserHeader from "../../components/UserHeader";
import SpinnerLoader from "../../components/LoadingSpinning";
import VModal from "vue-js-modal";
import moment from "moment";
import wysiwyg from "vue-wysiwyg";

Vue.use(VModal);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    bold: false,
    italic: false,
    underline: false,
    justifyLeft: false,
    justifyCenter: false,
    justifyRight: false,
    headings: false,
    link: false,
    code: false,
    orderedList: false,
    unorderedList: false,
    image: false,
    table: false,
    removeFormat: false,
    separator: false,
  },
  forcePlainTextOnPaste: true,
});

export default {
  name: "Documents",
  components: { AppTemplate, SpinnerLoader },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      editMode: false,
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
      loadingStuff: false,
      trainingPlanHasData: false,
      selectedUserValue: "",
      weekSelectedNumber: "",
      sessionCompleted: false,
      weeklyDate: "",
      newSessionData: {
        userId: "",
        section: "",
        details: "",
        weekSelectedNumber: "",
        name: "",
        introText: "",
        notes: "",
        day: "",
        year: "",
        week: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      userData: {
        name: "",
        userType: "",
      },
      coachesUsersData: [],
      trainingPlanArciveData: [],
      trainingPlanData: [],
      sessionDataModal: {},
      selectedTab: "current-week-tab",
      selectedSessionTab: "details",
      documentData: {
        name: "",
        text: "",
        created_date: moment().format("YYYY-MM-DD"),
        updated_date: null,
        created_by: null,
        edited_by: null,
        signature: false,
        approvedAccessList: {
          teams: [],
          users: [],
        },
      },
      filterdCoachesUsersData: [],
      clubTeams: [],
      filterdTeamsData: [],
      usersDocuments: [],
      selectedDocumentData: {
        approvedAccessList: {
          teams: [],
          users: [],
        },
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData", "getWeekNumber"]),
  },
  created() {
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.$route.params.trainingPlanUserId !== 0
          ? this.setheaderInfoForUser()
          : this.setHeaderUserData();

        this.setUser();
        this.getAllUsersForClubId();
        this.getTeamsByUserId();
        this.loadAllDocuments();
      } else {
        this.setSectionType();
        if (this.$route.params.trainingPlanUserId !== 0) {
          userHeaderClientId = this.$route.params.trainingPlanUserId;
        }
        let clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserByIdForHeader(userHeaderClientId);

        this.getUserById(clientId).then((response) => {
          if (response === true) {
            if (
              this.userData.userType === "Admin" ||
              this.userData.userType === "Coach"
            ) {
              this.selectedUserValue = this.$route.params.trainingPlanUserId;
            } else {
              this.selectedUserValue = clientId;
            }
          }
        });

        this.getAllUsersForClubId();
        this.getTeamsByUserId();
        this.loadAllDocuments();
      }
    });
  },
  methods: {
    async getDocumentAccessDataCall(documentId) {
      const userData = {
        document_id: documentId,
        action: "getDocumentAccess",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        return response.data;
      } else {
        return null;
      }
    },
    async populateDocumentModelWithAccess(
      documentData,
      documentAccesData,
      objectName,
      modalName
    ) {
      if (documentAccesData !== null) {
        let accessResponse = documentAccesData.map(async (accessData) => {
          const teamData = await this.getClubTeamName(accessData.team_id);
          if (accessData.team_id !== null) {
            return await this.addUserTeamToApprovedAccessList(
              "Teams",
              teamData,
              objectName
            );
          }

          if (accessData.user_id !== null) {
            const response = await this.getUserName(accessData.user_id);
            const useAceessrData = {
              id: accessData.user_id,
              name: response.name,
            };
            return await this.addUserTeamToApprovedAccessList(
              "Users",
              useAceessrData,
              objectName
            );
          }
        });

        if (accessResponse) {
          this[objectName] = {
            ...documentData,
            approvedAccessList: { ...this[objectName].approvedAccessList },
          };
          this.showModal(modalName);
        }
      }
    },
    async openDocument(documentData) {
      const documentAccesData = await this.getDocumentAccessDataCall(
        documentData.id
      );

      if (documentAccesData.length > 0) {
        this.populateDocumentModelWithAccess(
          documentData,
          documentAccesData,
          "selectedDocumentData",
          "documentViewModal"
        );
      }
    },
    async editDocument(documentData) {
      const documentAccesData = await this.getDocumentAccessDataCall(
        documentData.id
      );

      await this.populateDocumentModelWithAccess(
        documentData,
        documentAccesData,
        "selectedDocumentData",
        "documentEditModal"
      );
    },
    getUserName(id) {
      return this.coachesUsersData.find((user) => user.id == id) || null;
    },
    getClubTeamName(id) {
      return this.clubTeams.find((team) => team.id == id) || null;
    },
    async loadAllDocuments() {
      const userData = {
        created_by: this.userData.id,
        action: "getDocuments",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.usersDocuments = response.data;
      }
    },
    async addDocumetsAccess() {
      const documentAccessData = {
        approvedAccessList: this.selectedDocumentData.approvedAccessList,
        document_id: this.selectedDocumentData.id,
        action: "addDocumetsAccess",
      };

      const response = await Documents.find(documentAccessData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (response.data.length > 0) {
        this.loadAllDocuments();
      }
    },
    async saveEditedDocument() {
      this.loadingStuff = true;

      this.selectedDocumentData.signature === true ?? 1;
      this.selectedDocumentData.signature === false ?? 0;
      this.selectedDocumentData.updated_date === moment().format("YYYY-MM-DD");

      const userData = {
        ...this.selectedDocumentData,
        action: "saveEditedDocument",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data === "Document updated") {
        await this.addDocumetsAccess();
        this.loadingStuff = false;
        this.$bvToast.toast("Document updated successfully.", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.loadingStuff = false;
        this.hideModal("documentEditModal");
      }
    },
    async saveNewDocument() {
      this.loadingStuff = true;
      this.documentData.created_by = this.userData.id;

      const userData = {
        ...this.documentData,
        action: "addDocument",
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.hideModal("documentModal");
        this.loadAllDocuments();
        this.loadingStuff = false;
        this.$bvToast.toast("Created Document successfully.", {
          title: "Created",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async removeAccessCall(accessDataToBeRemoved) {
      this.loadingStuff = true;
      const data = {
        ...accessDataToBeRemoved,
        action: "removeDocumentAccess",
      };
      const response = await Documents.find(data).catch((error) => {
        console.log(error);
      });

      if (response === "access removed") {
        console.log(response);
      }

      const documentAccesData = await this.getDocumentAccessDataCall(
        this.selectedDocumentData.id
      );
      this.selectedDocumentData.documentAccesData = documentAccesData;
      this.loadingStuff = false;
    },
    removeAccess(id, typeToRemove) {
      let accessToRemove = {
        teams: [],
        users: [],
      };

      if (typeToRemove === "Teams") {
        this.documentData.approvedAccessList.teams =
          this.documentData.approvedAccessList.teams.filter(
            (team) => team.id !== id
          );
        accessToRemove.teams.push({
          team_id: id,
          document_id: this.selectedDocumentData.id,
        });
      }
      if (typeToRemove === "Users") {
        this.documentData.approvedAccessList.users =
          this.documentData.approvedAccessList.users.filter(
            (user) => user.id !== id
          );
        accessToRemove.users.push({
          user_id: id,
          document_id: this.selectedDocumentData.id,
        });
      }

      this.removeAccessCall(accessToRemove);
    },
    addToApprovedAccessList(accessList, newItem) {
      if (!accessList.some((item) => item.id == newItem.id)) {
        accessList.push(newItem);
      }
    },
    addUserTeamToApprovedAccessList(accessType, data, objectName) {
      if (accessType === "Teams") {
        this.addToApprovedAccessList(
          this[objectName].approvedAccessList.teams,
          {
            id: data.id,
            teamName: data.teamName,
          }
        );
        this.filterdTeamsData = [];
        return this[objectName].approvedAccessList.teams.length > 0 ?? true;
      }

      if (accessType === "Users") {
        this.addToApprovedAccessList(
          this[objectName].approvedAccessList.users,
          {
            id: data.id,
            name: data.name,
          }
        );
        this.filterdCoachesUsersData = [];
        return this[objectName].approvedAccessList.users.length > 0 ?? true;
      }
    },
    async getAllUsersForClubId() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getUsersByClubId",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.coachesUsersData = response.data;
      }
    },
    async getTeamsByUserId() {
      const data = {
        action: "getTeamsDataById",
        userId: this.userData.id,
      };

      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        data.clubId = this.userData.clubId;
      }
      const response = await Teams.find(data).catch((error) => {
        console.log(error);
      });
      this.clubTeams = response.data;
    },
    lookUpSearch(value, filterdData, arrayToFilter) {
      if (value.length > 3) {
        this[filterdData] = [];

        const results = Array.from(value).reduce(
          (a, v, i) => `${a}[^${value.substr(i)}]*?${v}`,
          ""
        );
        const regEx = RegExp(results);

        this[arrayToFilter].filter((v) => {
          if (v.hasOwnProperty("name")) {
            if (v.name.match(regEx)) {
              this[filterdData].push(v);
            }
          }

          if (v.hasOwnProperty("teamName")) {
            if (v.teamName.match(regEx)) {
              this[filterdData].push(v);
            }
          }
        });
      } else {
        this[filterdData] = [];
      }
    },
    setheaderInfoForUser() {
      this.getUserByIdForHeader(
        JSON.parse(localStorage.getItem("the_w_selectedClientId"))
      );
    },
    setSectionType() {
      switch (this.$route.name) {
        case "documents":
          this.newSessionData.section = "Documents";
          break;
      }
    },
    getTrainingTypeAndUserData() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
        return {
          trainingType: this.$route.params.trainingPlanType,
          userId: this.$route.params.trainingPlanUserId,
        };
      } else {
        this.selectedUserValue = this.userData.id;
        return {
          trainingType: this.$route.params.trainingPlanType,
          userId: this.userData.id,
        };
      }
      return null;
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    selectedUseChanged(userId) {
      this.selectedUserValue = userId.selectedUser;
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (response.data.length > 0) {
        this.userHeaderData = response.data[0];
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (response.data.length > 0) {
        this.userData = response.data[0];
        const userData = response.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.checkForCoachesUsersData();
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    makeTraingDataObject(actionName) {
      let data = this.newSessionData;
      data.complete = false;
      data.action = actionName;

      if (this.image.file !== null) {
        data.imageFile = this.imageFile;
        data.fileName = this.image.imageName;
      }
      return data;
    },
    actionsAfterFitnessSessionEditCreate() {
      this.hideModal("addEditProgramme");
      this.gettrainingPlansByUserIdAndWeek(this.selectedUserValue);
      this.loadingStuff = false;
      this.$bvToast.toast("Created successfully.", {
        title: "Created",
        toaster: "b-toaster-bottom-right",
        solid: true,
        appendToast: true,
        variant: "success",
      });
    },
    async edittrainingPlan() {
      this.loadingStuff = true;
      const trainingPlanData = this.makeTraingDataObject("edittrainingPlan");
      const response = await TrainingPlan.store(trainingPlanData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (response === "Training session updated") {
        this.actionsAfterFitnessSessionEditCreate();
        this.editMode = false;
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (response.data.length > 0) {
        this.coachesUsersData = response.data;
        this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
        localStorage.setItem(
          "coachesUsersData",
          JSON.stringify(this.coachesUsersData)
        );
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    makeToast(title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.tabs-wrapper .tab-content {
  padding: 1rem;
}

.sorting-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;

  .filter-by {
    background: transparent;
  }

  .search {
    max-width: 20rem;

    input {
      padding: 0.5rem;
      font-size: 0.875rem;
      border-radius: 0;
      background: "white";
    }

    .input-group-text {
      padding: 0.5rem;
      border-radius: 0;
    }
  }
}

.access-pool {
  padding: 1rem;
  border: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .has-access {
    background: #e3f0fb;
    padding: 0.5rem;
    display: block;
    border-radius: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #f8e0e0;
    }
  }
}

.buttons-row {
  margin-top: 1rem;
  padding-top: 1rem;
}

.documents-tab {
  display: flex;
  flex-wrap: wrap;

  .document {
    max-width: 14rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #026ec8;
    }

    h3 {
      margin-top: 1rem;
      font-size: 1rem;
    }

    img {
      width: 100%;
    }
  }
}

.doc-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  svg {
    border: 1px solid #c3cdd5;
    padding: 0.3rem;
    font-size: 1.7rem;

    &:hover,
    &:focus {
      cursor: pointer;
      background: #eaeff3;
    }
  }
}
</style>
